import Crud from '../../sub/Crud';

export default function SettingsDepartments() {
    return (
        <Crud
            apiRouterPathRoot={"/department"}
            elementLabel={"département"}
            isDepartmentCrud={true}
        />
   )
}