import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Attendee, Event } from 'microsoft-graph';
import { createEvent } from '../../../GraphService';
import { useAppContext } from '../../../AppContext';
import Util from "../../../utils/Util";
import Salesman from "../../../interfaces/Salesman";
import Marker from "../../../interfaces/Marker";

interface MarkerModalEventAddProps {
  marker: Marker,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  updateMarker: (updatedField: string, updatedValue: any) => void,
  salesmen: Array<Salesman>
}

export default function MarkerModalEventAdd({marker, setShowModal, updateMarker, salesmen}: MarkerModalEventAddProps) {
  const app = useAppContext();

  const getAttendees = (mail:string, mailSalesman:string) => {
    let attendees:string = "";
    mail !== "" ? attendees+= mail.toLowerCase()+";" : attendees+="";
    mailSalesman !== undefined && mailSalesman !== null  && mailSalesman.trim() !== "" ? attendees+= mailSalesman.toLowerCase()+";" : attendees+="";
    return attendees;
  }

  const mail:string = marker.mail !== undefined && marker.mail !== null && marker.mail.trim() !== "" ? marker.mail : "";

  const fullAddress:string = marker.adresse + " " + marker.cp + " " + marker.ville;

  const SUBJECT:string = "["+marker.nom+" ("+marker.ville+")] - Rendez-vous commercial EUPHORIA";
  const BODY:string = marker.nom +"\n"+ fullAddress +"\n"+ marker.contact +"\n"+ marker.telephone +"\n"+ mail.toLowerCase();

  const [subject, setSubject] = useState<string>(SUBJECT);
  const [attendees, setAttendees] = useState<string>(getAttendees(mail, Util.getSalesmanEmail(salesmen, marker.departement_details.salesman)));
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>('');
  const [body, setBody] = useState<string>(BODY);
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    setFormDisabled(
      subject.length === 0 ||
      start.length === 0 ||
      end.length ===0);
  }, [subject, start, end]);

  const doCreate = async () => {
    const attendeeEmails = attendees.split(';');
    const attendeeArray: Attendee[] = [];

    attendeeEmails.forEach((email) => {
      if (email.length > 0) {
        attendeeArray.push({
          emailAddress: {
            address: email
          }
        });
      }
    });

    const newEvent: Event = {
      subject: subject,
      // Only add if there are attendees
      attendees: attendeeArray.length > 0 ? attendeeArray : undefined,
      // Specify the user's time zone so
      // the start and end are set correctly
      start: {
        dateTime: start,
        timeZone: app.user?.timeZone
      },
      end: {
        dateTime: end,
        timeZone: app.user?.timeZone
      },
      // Only add if a body was given
      body: body.length > 0 ? {
        contentType: 'text',
        content: body
      } : undefined
    };

    const createMarkerEvent = (eventData:Event) => {
      let events:Array<Object> = [];
      if (marker.rdv !== undefined && marker.rdv.length > 0) events = [...marker.rdv];
      let newEvent = {}
        newEvent = {
        id: eventData.id,
        author: eventData.organizer!.emailAddress!.name,
        attendees: eventData.attendees,
        startDate: eventData.start!.dateTime,
        endDate: eventData.end!.dateTime,
        subject: eventData.subject,
        body: eventData.bodyPreview
      };
      events.push(newEvent);
      updateMarker("rdv", events)
      setShowModal(false);
    }

    try {
      createEvent(app.authProvider!, newEvent).then(response => {
        createMarkerEvent(response);
        setRedirect(true);
      });
    } catch (err) {
      app.displayError!('Error creating event', JSON.stringify(err));
    }
  };

  if (redirect) {
    return <Redirect to="/map" />
  }

  return(
    <Modal
      show={true}
      onHide={() => setShowModal(false)}
      backdrop={"static"}
      keyboard={false}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{"Nouveau RDV"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="m-4">
          <Form.Group>
            <Form.Label>Sujet</Form.Label>
            <Form.Control type="text"
              name="subject"
              id="subject"
              className="mb-2"
              value={subject}
              onChange={(e) => setSubject(e.target.value) } />
          </Form.Group>
          <Form.Group>
            <Form.Label>Participants</Form.Label>
            <Form.Control type="text"
              name="attendees"
              id="attendees"
              className="mb-2"
              placeholder="Entrez une liste des adresses e-mail (séparer chaque adresse par des points-vigules)"
              value={attendees}
              onChange={(e) => setAttendees(e.target.value) } />
          </Form.Group>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Début</Form.Label>
                <Form.Control type="datetime-local"
                  name="start"
                  id="start"
                  value={start}
                  onChange={(e) => setStart(e.target.value) } />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Fin</Form.Label>
                <Form.Control type="datetime-local"
                  name="end"
                  id="end"
                  value={end}
                  onChange={(e) => setEnd(e.target.value) } />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea"
              name="body"
              id="body"
              className="mb-3"
              style={{ height: '10em' }}
              value={body}
              onChange={(e) => setBody(e.target.value) } />
          </Form.Group>
          <Button color="primary"
            className="me-2"
            disabled={formDisabled}
            onClick={() => doCreate()}>Créer</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}