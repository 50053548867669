import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal, Button, InputGroup, FormControl, Alert, Spinner } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';

import 'react-notifications/lib/notifications.css';
import Company from "../../interfaces/Company";
import Department from "../../interfaces/Department";
import ArrayUtils from '../../utils/ArrayUtils';

interface CompanyModalProps {
    companyId: string,
    companies: Array<Company>,
    setCompanies: React.Dispatch<React.SetStateAction<Array<Company>>>,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    isUpdate: boolean,
    setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>,
    departments: Array<Department>
}

export default function CompanyModal({companyId, companies, setCompanies, setShowModal, isUpdate, setIsUpdate, departments}:CompanyModalProps) {
    
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");
    const [dataset, setDataset] = useState<string>("");
    const [datasetError, setDatasetError] = useState<string>("");
    const [regions, setRegions] = useState<string>("");
    const [depts, setDepts] = useState<string>("");

    const regexEmail: RegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    const allRegions = [
        "AUVERGNE-RHÔNE-ALPES", "BOURGOGNE-FRANCHE-COMTÉ", "BRETAGNE", "CENTRE-VAL DE LOIRE", "CORSE", "GRAND EST", "HAUTS-DE-FRANCE",
        "ÎLE-DE-FRANCE", "NORMANDIE", "NOUVELLE-AQUITAINE", "OCCITANIE", "PAYS DE LA LOIRE", "PROVENCE-ALPES-CÔTE D'AZUR"
    ];
    
    const handleModalClose = () => {
        setShowModal(false);
        setIsUpdate(false);
    };

    const checkIsExist = async (field:string, value:string) => {
        let response = await axios.post("/company/check", {field: field, value: value});
        return response.data;
    }

    const checkIsEmailExist = async (field:string, value:string) => {
        let response = await axios.post("/user/check", {field: field, value: value});
        return response.data;
    }

    const checkData = () => {
        if (name.trim().length-1 < 0) setNameError("Le nom de la société ne peut pas être vide");
        if (email.trim().length-1 < 0) setEmailError("L'email de la société ne peut pas être vide");
    }

    const createCompany = () => {
        let newCompany:any = {
            name: name.trim(),
            email: email,
            dataset: dataset,
            regions: regions,
            depts: depts,
            canLogin: true,
        }
        checkData();
        if (name.trim().length > 0 && email.trim().length > 0) {
            setIsAdding(true);
            axios.post("/company/create", newCompany).then((response) => {
                let allCompanies = [...companies]
                newCompany._id = response.data;
                allCompanies.push(newCompany);
                setCompanies(allCompanies);
                setShowModal(false);
                setIsAdding(false);
                NotificationManager.success("La société a été créé.");
            })
        } else {
            NotificationManager.error("Impossible de créer la société.");
        }
    }

    const updateCompany = async (updatedField:string, updatedValue:any) => {
        let updatedData = {
            crudItemId: companyId,
            updatedField: updatedField,
            updatedValue: updatedValue
        }
        let isExist = false;
        let isExistEmail = false;
        if (updatedField === "name") isExist = await checkIsExist(updatedField, updatedValue);
        if (updatedField === "email") {
            isExist = await checkIsExist(updatedField, updatedValue);
            isExistEmail = await checkIsEmailExist(updatedField, updatedValue);
        };
        if (!isExist && !isExistEmail) {
            axios.post("/company/update", updatedData).then((response) => {
                let allCompanies:Array<any> = [...companies];
                let index: number = allCompanies.findIndex((item:Company) => item._id === companyId);
                allCompanies[index][updatedField] = response.data[updatedField];
                setCompanies(allCompanies);
                NotificationManager.success("Les modifications ont été sauvegardées");
            });
        } else {
            if (updatedField === "name") setNameError("Ce nom de société est déjà utilisé");
            if (updatedField === "email") setEmailError("Cet email est déjà utilisé");
            NotificationManager.error("Impossible de modifier le compte utilisateur.");
        }
    }

    useEffect(() => {
        if (isUpdate) {
            axios.post("/company/", {crudItemId: companyId}).then((response) => {
                let company = response.data;
                setName(company.name);
                setEmail(company.email);
                setDataset(company.dataset);
                setRegions(company.regions);
                setDepts(company.depts);
            })
        }
    }, [isUpdate, companyId])


    function isValid(updatedField:string, updatedValue:any) {
        let result = false;
        let errorName = "";
        let errorEmail = "";

        switch(updatedField) {
            case "name":
                if(updatedValue.length-1 < 0) {
                    errorName = "Le champs ne peut pas être vide !";
                } else {
                    result = true;
                }
                setName(updatedValue);
                setNameError(errorName);
                break;
            case "email":
                if(updatedValue.length-1 < 0) {
                    errorEmail = "Le champs ne peut pas être vide !";
                } else if (!updatedValue.match(regexEmail)) {
                    errorEmail = "Adresse e-mail non valide !";
                } else {
                    result = true;
                }
                setEmail(updatedValue)
                setEmailError(errorEmail);
                break;
            default:
                result = true;
        }
        return result;
    }

    const handleUpdate = async (updatedField:string, updatedValue:string) => {
        if (isValid(updatedField, updatedValue)) {
            if (updatedField === "email") setEmail(updatedValue);
            if (updatedField === "dataset") setDataset(updatedValue);
            if (updatedField === "regions") setRegions(updatedValue);
            if (updatedField === "depts") setDepts(updatedValue);

            if (isUpdate) {
                updateCompany(updatedField, updatedValue);
            } else {
                if (updatedField === "name") {
                    let isExist = await checkIsExist(updatedField, updatedValue);
                    if (isExist) {
                        if (updatedField === "name") setNameError("Ce nom de société est déjà utilisé");
                    }
                } else if (updatedField === "email") {
                    let isExist = await checkIsExist(updatedField, updatedValue);
                    let isExistEmail = await checkIsEmailExist(updatedField, updatedValue);
                    if (isExist || isExistEmail) {
                        if (updatedField === "email") setEmailError("Cet email est déjà utilisé");
                    }
                }
            }
        }
    }

    return (
        <Modal
            show={true}
            onHide={handleModalClose}
            backdrop={"static"}
            keyboard={false}
            size={undefined}
        >
            <Modal.Header closeButton>
                <Modal.Title>{isUpdate ? name : "Nouvelle société"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"username"}>Nom de société</InputGroup.Text>
                    <FormControl 
                        defaultValue={name} type={"text"} isInvalid={nameError !== ""} 
                        onBlur={(e) => handleUpdate("name", e.target.value)} 
                    />
                </InputGroup>
                {nameError !== "" && <Alert variant={"danger"} onClose={() => setNameError("")} dismissible><h6>{nameError}</h6></Alert>}

                <InputGroup className="mb-3">
                    <InputGroup.Text id={"email"}>Email</InputGroup.Text>
                    <FormControl 
                        defaultValue={email} type={"text"} isInvalid={emailError !== ""} 
                        onBlur={(e) => handleUpdate("email", e.target.value)} 
                    />
                </InputGroup>
                {emailError !== "" && <Alert variant={"danger"} onClose={() => setEmailError("")} dismissible><h6>{emailError}</h6></Alert>}
                
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"dataset"}>Plage de données</InputGroup.Text>
                    <FormControl as="select" name={"dataset"} value={dataset}
                        onChange={(e) => isUpdate ? handleUpdate("dataset", e.target.value) : setDataset(e.target.value)}
                    >
                        <option value={""}>Sélectionnez une plage de données</option>
                        <option value={"all"}>France entière</option>
                        <option value={"regions"}>Région(s)</option>
                        <option value={"depts"}>Département(s)</option>
                    </FormControl>
                </InputGroup>

                {dataset === "regions" &&
                    <InputGroup className="mb-3">
                        <InputGroup.Text id={"regions"}>Région(s)</InputGroup.Text>
                        <FormControl as="select" name={"regions"} value={regions}
                            onChange={(e) => handleUpdate("regions", e.target.value)}
                        >
                            <option value={""}>Sélectionnez une région</option>
                            {allRegions.map((item:string, i:number) => (<option key={"region"+i} value={item}>{item}</option>))}
                        </FormControl>
                    </InputGroup>
                }

                {dataset === "depts" && 
                    <InputGroup className="mb-3">
                        <InputGroup.Text id={"depts"}>Département(s)</InputGroup.Text>
                        <FormControl as="select" name={"depts"} value={depts}
                            onChange={(e) => handleUpdate("depts", e.target.value)}
                        >
                            <option value={""}>Sélectionnez un département</option>
                            {departments.sort((a,b) => ArrayUtils.ascendingSort(a.num, b.num)).map((item:Department) => (<option key={item._id} value={item.num}>{item.num} - {item.name}</option>))}
                        </FormControl>
                    </InputGroup>
                }

                {datasetError !== "" && <Alert variant={"danger"} onClose={() => setDatasetError("")} dismissible><h6>{datasetError}</h6></Alert>}
            </Modal.Body>
            {!isUpdate && <Modal.Footer>
                {isAdding ?
                    <Button variant={"primary"} disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Création en cours...</Button>
                    :
                    <Button variant="primary"  onClick={createCompany} disabled={nameError !== "" || emailError !== "" || datasetError !== "" || dataset === "" || (dataset === "regions" && regions === "") || (dataset === "depts" && depts === "")}>
                        <FontAwesomeIcon icon={faPlusCircle}/> Créer
                    </Button>
                    }
            </Modal.Footer>}
        </Modal>
    )
}