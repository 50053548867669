import { useState } from 'react'
import { Container, Row, DropdownButton, Dropdown, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import MarkerModal from '../Map/MarkerModal/MarkerModal';
import DateUtil from '../../utils/DateUtil';
import TableFiltered from '../../sub/TableFiltered';
import ExportButton from '../../sub/ExportButton';
import { Roles } from '../../enums/Roles';
import ArrayUtils from '../../utils/ArrayUtils';

import Groupment from "../../interfaces/Groupment";
import Salesman from "../../interfaces/Salesman";
import User from "../../interfaces/User";
import Label from "../../interfaces/Label";
import Marker from "../../interfaces/Marker";
import Util from "../../utils/Util";
import { MarkerPipe } from "../../enums/MarkerPipe";
import { RdvStatus } from "../../enums/RdvStatus";
import axios from "axios";
import Rdv from "../../interfaces/Rdv";

interface ReportsProps {
    username: string,
    userRole: number,
    data: Array<any>,
    groupments: Array<Groupment>,
    salesmen: Array<Salesman>,
    users: Array<User>
    labelsCategory: Array<Label>,
    labelsStatus: Array<Label>,
    setData: React.Dispatch<React.SetStateAction<Array<Marker>>>
}

export default function Reports({username, userRole, data, groupments, salesmen, users, labelsCategory, labelsStatus, setData}: ReportsProps) {
    const [filteredData, setFilteredData] = useState<Array<Marker>>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalSelectedTab, setModalSelectedTab] = useState<string>("");
    const [isMarkerAdd, setIsMarkerAdd] = useState<boolean>(false);
    const [markerId, setMarkerId] = useState<string>("");
    const [markerType, setMarkerType] = useState<string>("");

    const onChangeStatus = (markerId:string, rdvId:string, value:number) => {
        let markerIndex = data.findIndex((item:Marker) => item._id === markerId);
        let rdvIndex = data[markerIndex].rdv.findIndex((item:Rdv) => item.id === rdvId);
        let allRdv = [...data[markerIndex].rdv];
        allRdv[rdvIndex].status = value;
        let updatedData = {
            markerId: markerId,
            updatedField: "rdv",
            updatedValue: allRdv
        }

        axios.post("/markers/update", updatedData).then((response) => {
            let dataUpdated:any = [...data];
            dataUpdated[markerIndex]["rdv"] = response.data["rdv"];
            setData(dataUpdated);
        });
    }

    const handleModalShow = (marker:Marker, modalTab:string) => {
        if (marker === undefined) return;
        if (modalTab === "") return;
        setModalSelectedTab(modalTab);
        setMarkerId(marker._id)
        setMarkerType(marker.type)
        setShowModal(true);
    };

    const getClientData = (clientId:string, field: string) => {
        let client:Marker = data.find((item:Marker) => item._id === clientId)!;
        if (client === undefined) return "Inconnu(e)";
        return client[field as keyof Marker];
    }

    const getAllRdv = () => {
        let items:any = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i] !== undefined && data[i].rdv !== undefined && data[i].rdv.length > 0) {
                for (let y = 0; y < data[i].rdv.length; y++) {
                    if (data[i].rdv[y].report !== undefined) {
                        items.push({id: data[i]._id, rdv: data[i].rdv[y]});
                    }
                }
            }
        }
        return items.reverse();
    }

    const getExportData = () => {
        let data = filteredData.length > 0 ? filteredData : getTableData();
        return data.map((item) => item.id.data);
    }

    const getPipeOptions = () => {
        let pipeOptions: Array<{value:string, label:string}> = [];
        for(const pipe of Object.values(MarkerPipe)) {
            if (typeof pipe === 'string') pipeOptions.push({value: pipe, label: pipe})
        }
        return pipeOptions;
    }

    const getStatusOptions = () => {
        let statusOptions: Array<{value:number | string, label:string}> = [];
        for(const statut of Object.values(RdvStatus)) {
            if (typeof statut === 'number') {
                let value: string | number = Util.RdvStatusFormalyse(statut);
                if (userRole === Roles.ADMIN || userRole === Roles.SUPERADMIN) value = statut;
                statusOptions.push({value: value, label: Util.RdvStatusFormalyse(statut)})
            }
        }
        return statusOptions;
    }

    const getStatusComponent = (markerId:string, rdv:any) => {
        if (userRole !== Roles.ADMIN && userRole !== Roles.SUPERADMIN) return Util.RdvStatusFormalyse(rdv.status);
        return <Form.Select onChange={(e) => onChangeStatus(markerId, rdv.id, parseInt(e.target.value))} value={rdv.status}>
            {Object.values(RdvStatus).map((rdvStatus:any) => {
                if (typeof rdvStatus !== "number") return null;
                return <option key={rdvStatus} value={rdvStatus}>{Util.RdvStatusFormalyse(rdvStatus)}</option>
            })}
        </Form.Select>
    }

    const getTableData = () => {
        let data:Array<any> = [];
        let items = getAllRdv();
        let options = users.map((user:User) => { return {value: user.username, label: user.username}})
        let pipeOptions: Array<{value:string, label:string}> = getPipeOptions();
        let statutOptions: Array<{value:number | string, label:string}> = getStatusOptions();
        let sortedReport = items.sort((a:any,b:any) => ArrayUtils.descendingSort(new Date(a.rdv.report.createdAt).getTime(),new Date(b.rdv.report.createdAt).getTime()));
        sortedReport.map((item:any) => {
            return data.push({
                id: {data: item.id}, 
                date: {data: DateUtil.toddMMyyyy(item.rdv.report.createdAt) +"-"+DateUtil.getTime(item.rdv.report.createdAt), type: "input"}, 
                auteur: {data: item.rdv.report.author !== "" ? item.rdv.report.author : "Inconnu(e)", type: "select", options: options},
                prospect: {data: getClientData(item.id, "nom"), type:"input"},
                pipe: {data: getClientData(item.id, "pipe"), type: "select", options: pipeOptions},
                statut: {data: getStatusComponent(item.id, item.rdv), type: "select", options: statutOptions}
            });
        })
        return data;
    }

    const getTableAction = (itemId:any) => {
        return <DropdownButton
            align="end"
            title={<FontAwesomeIcon icon={faEye} />}
            id="dropdown-menu-align-end"
        >
            <Dropdown.Item onClick={() => handleModalShow(Util.getMarker(itemId, data)!, "prospectHeaders")}>Voir la fiche prospect</Dropdown.Item>
            <Dropdown.Item onClick={() => handleModalShow(Util.getMarker(itemId, data)!, "reports")}>Voir les comptes-rendus</Dropdown.Item>
            <Dropdown.Item onClick={() => handleModalShow(Util.getMarker(itemId, data)!, "commentaries")}>voir les commentaires</Dropdown.Item>
        </DropdownButton>
    }

    return (
        <>
        {!isMarkerAdd &&
            <Container className={"mt-5"}>
                {userRole === Roles.ADMIN && <Row xs="auto" className={"mb-4"}>
                    <ExportButton 
                        APIUrl={"/markers/exportReport"}
                        fileName={"rapports-de-visite-comptes-rendus"}
                        fileExtension={"xlsx"}
                        data={getExportData()}                
                    />
                </Row>}
                <TableFiltered
                    data={getTableData()}
                    setData={setFilteredData}
                    hasActions={true}
                    actionsComponent={((itemId:any) => getTableAction(itemId))}
                    hasFilters={true}
                    hasExport={true}
                />
                {showModal &&
                <MarkerModal
                    data={data}
                    setData={setData}
                    markerId={markerId}
                    markerType={markerType}
                    setShowModal={setShowModal}
                    isMarkerAdd={false}
                    setIsMarkerAdd={setIsMarkerAdd}
                    userRole={userRole}
                    username={username}
                    groupments={groupments}
                    salesmen={salesmen}
                    users={users}
                    labelsCategory={labelsCategory}
                    labelsStatus={labelsStatus}
                    selectTab={modalSelectedTab}
                />
                }
            </Container>
        }
        </>
    )
}