import React from "react";
import { RdvStatus } from "../enums/RdvStatus";
import { Roles } from "../enums/Roles";
import Groupment from "../interfaces/Groupment";
import Label from "../interfaces/Label";
import Marker from "../interfaces/Marker";
import Salesman from "../interfaces/Salesman";

export default class Util {

    static emptyString(val: any) {
        if (!(typeof val === 'string' || val instanceof String)) return false;

        return (!val || ("" + val).replace(" ", "") === "");
    }

    static calculatePoint(i:number, intervalSize:number, colorRangeInfo:any) {
        let { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
        return (useEndAsStart ? (colorEnd - (i * intervalSize)) : (colorStart + (i * intervalSize)));
    }
      
    /* Must use an interpolated color scale, which has a range of [0, 1] */
    static interpolateColors(dataLength:number, colorScale:any, colorRangeInfo:any) {
        let { colorStart, colorEnd } = colorRangeInfo;
        let colorRange = colorEnd - colorStart;
        let intervalSize = colorRange / dataLength;
        let i, colorPoint;
        let colorArray = [];

        for (i = 0; i < dataLength; i++) {
          colorPoint = this.calculatePoint(i, intervalSize, colorRangeInfo);
          colorArray.push(colorScale(colorPoint));
        }
        
        return colorArray;
    }

    static stringToColor = function(str:string) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        let colour = '#';
        for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xFF;
          colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    static formalize = (data:string) => {
        if (data === "libelle_categorie") return "CATEGORIE";
        if (data === "libelle_statut") return "STATUT";
        if (data === "client") return "RELATION COMMERCIALE";
        return data.split('_').join(' ').toUpperCase();
    }

    static displayData = (header:string, item:any, items:Array<any>) => {
        let result = item;
        if (item === "#N/A" || item === "" || item === null) result = "(non-renseigné(e))";
        if (header === "groupement") result = Util.getGroupementName(items, item);
        if (header === "libelle_categorie") result = Util.getLabelCategoryName(items, item);
        if (header === "libelle_statut") result = Util.getLabelStatusName(items, item);
        if (header === "commercial") result = Util.getSalesmanName(items, item);
        return result;
    }

    static truncateString = (str:string, maxChar:number) => {
        return (str.length > maxChar) ? str.slice(0, maxChar) + "..." : str;
    }

    static getMarker = (markerId:string, markers: Array<Marker>) => {
        return markers.find((item:Marker) => item._id === markerId);
    }

    static getGroupementName = (groupments:Array<Groupment>, groupmentId:string) => {
        let groupment = groupments.find((item:Groupment) => item._id === groupmentId);
        return groupment !== undefined ? groupment.name : "";
    }

    static getLabelCategoryName = (labelsCategory:Array<Label>, labelCategoryId:string) => {
        let labelCategory = labelsCategory.find((item:Label) => item._id === labelCategoryId);
        return labelCategory !== undefined ? labelCategory.name : "";
    }

    static getLabelStatusName = (labelsStatus:Array<Label>, labelStatusId:string) => {
        let labelStatus = labelsStatus.find(item => item._id === labelStatusId);
        return labelStatus !== undefined ? labelStatus.name : "";
    }

    static getSalesmanName = (salesmen:Array<Salesman>, salesmanId:string) => {
        let salesman = salesmen.find((item:Salesman) => item._id === salesmanId);
        if (salesman !== undefined) {
            let firstName = salesman.first_name !== undefined ? salesman.first_name : "";
            let name = salesman.name !== undefined ? salesman.name : "";
            return firstName +" "+ name;
        } else {
            return "Inconnu(e)";
        }
    }

    static getSalesmanEmail = (salesmen:Array<Salesman>, salesmanId:string) => {
        let salesman = salesmen.find((item:Salesman) => item._id === salesmanId);
        return salesman !== undefined ? salesman.email : "";
    }

    static getSalesmanAssistantEmail = (salesmen:Array<Salesman>, salesmanId:string) => {
        let salesman = salesmen.find((item:Salesman) => item._id === salesmanId);
        return salesman !== undefined ? salesman.assistantEmail : "";
    }

    static multiFilter(data: Array<any>, allLabel:string, filter:string, setState:React.Dispatch<React.SetStateAction<Array<any>>>) {
        if (filter === allLabel) return setState([allLabel]);

        let newData = [...data];

        if (data.includes(filter)) {
            newData = data.filter((item) => item !== filter);
        } else {
            newData = data.filter((item) => item !== allLabel);
            newData.push(filter);
        }
        setState(newData);
    }

    static RoleFormalyze = (userRole:number) => {
        let result = "";
        switch(userRole) {
            case Roles.SALESMAN:
                result = "Commercial";
                break;
            case Roles.ASSISTANT:
                result = "Assistante commercial";
                break;
            case Roles.ADMIN:
                result = "Administrateur";
                break;
            case Roles.SUPERADMIN:
                result = "Super-utilisateur";
                break;
            default:
                break;
        }
        return result;
    }

    static RdvStatusFormalyse = (status:number) => {
        switch(status) {
            case RdvStatus.UNDEFINED:
                return "NON RENSEIGNÉ";
            case RdvStatus.IN_PROGRESS:
                return "EN COURS";
            case RdvStatus.MOVED:
                return "DÉCALÉ";
            case RdvStatus.CLOSED:
                return "CLÔTURÉ";
            case RdvStatus.SALE:
                return "VENTE RÉALISÉE";
            default:
                return "Inconnu";
        }
    }
}