import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Marker from "../../../interfaces/Marker";
import { nanoid } from 'nanoid';
import Rdv from '../../../interfaces/Rdv';
import DateUtil from '../../../utils/DateUtil';
import EventModal from "../../../sub/EventModal";
import { MarkerPipe } from "../../../enums/MarkerPipe";

interface MarkerModalReportAddProps {
  isReportAdd: boolean,
  rdv?: any,
  username: string,
  marker: Marker,
  setShowModal: () => void,
  updateMarker: (updatedField: string, updatedValue: any) => void
}

export default function MarkerModalReportAdd({isReportAdd, rdv, username, marker, setShowModal, updateMarker}: MarkerModalReportAddProps) {

  const [attachedEventId, setAttachedEventId] = useState<string>("");
  const [attachedEvent, setAttachedEvent] = useState<Rdv>();
  const [pipe, setPipe] = useState<string>(marker.pipe);
  const [type, setType] = useState<string>("");
  const [process, setProcess] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const [confModal, setConfModal] = useState<JSX.Element | null>(null);

  useEffect(() => {
    setFormDisabled(
      attachedEventId.length === 0 ||
      type.length === 0 ||
      process.length === 0 ||
      body.length === 0);
  }, [attachedEventId, type, process, body]);

  const getEvent = (attachedEventId: string) => {
    let rdv:Array<Rdv> = [];
    if (marker.rdv !== undefined && marker.rdv.length > 0) rdv = [...marker.rdv];
    for (let i = 0; i < rdv.length; i++) {
      if (rdv[i].id === attachedEventId) setAttachedEvent(rdv[i])
    }
  }

  useEffect(() => {
    getEvent(attachedEventId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachedEventId])

  const openConfModal = () => {
    let title = "Attention";
    let content = "Le compte-rendu ne pourra pas être modifié ou supprimé.\nÊtes-vous sûr de vouloir enregistrer ce compte-rendu ?";
    let modal = <EventModal
                    title={title}
                    content={content}
                    context={"danger"}
                    close = {setConfModal}
                    confirm={() => create()}
                />
    setConfModal(modal);
}

  const create = () => {
    let events:Array<Object> = [];
    if (marker.rdv !== undefined && marker.rdv.length > 0) events = [...marker.rdv];
    
    let newReport = {};
    newReport = {
      id: nanoid(),
      author: username,
      createdAt: Date.now(),
      pipe: pipe,
      type: type,
      process: process,
      body: body
    };

    let updatedEvent = {};
    updatedEvent = {
      id: attachedEvent?.id,
      author: attachedEvent?.author,
      startDate: attachedEvent?.startDate,
      endDate: attachedEvent?.endDate,
      subject: attachedEvent?.subject,
      body: attachedEvent?.body,
      report: newReport
    };

    let newEvents = events.filter((item:any) => item.id !== attachedEventId);
    newEvents.push(updatedEvent);
    updateMarker("rdv", newEvents);
    updateMarker("pipe", pipe);
    setShowModal();
  }

  const getAllOptGroups = (events: any) => {
    if (events === undefined) return [];
    let groups: Array<any> = [];
    for (let i = 0; i < events.length; i++) {
      if ((isReportAdd && events[i].report === undefined) || (!isReportAdd && events[i].report !== undefined)) {
        if (!groups.includes(DateUtil.toDate(events[i].startDate))) {
          groups.push(DateUtil.toDate(events[i].startDate));
        }
      }
    }
    return groups;
  }

  return(
    <Modal
      show={true}
      onHide={() => setShowModal()}
      backdrop={"static"}
      keyboard={false}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isReportAdd ? "Nouveau compte-rendu" : "Compte-rendu"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="m-4">
          {isReportAdd && <Alert variant="danger">Attention, le compte-rendu ne sera plus modifiable après avoir cliqué sur le bouton "enregistrer".</Alert>}
          <Form.Group>
            <Form.Label>Rendez-vous rattaché</Form.Label>
              <Form.Control as="select" className="mb-2" onChange={(e) => setAttachedEventId(e.target.value)} disabled={!isReportAdd} defaultValue={isReportAdd ? attachedEventId : rdv.id}>
                <option key={"option"} value={""}></option>
                {getAllOptGroups(marker.rdv).map((group) => {
                  return <optgroup key={group} label={group}>
                    {marker.rdv !== undefined && marker.rdv.map((rdv:Rdv) => 
                      {
                        if ((isReportAdd && rdv.report === undefined) || (!isReportAdd && rdv.report !== undefined)) {
                          return group === DateUtil.toDate(rdv.startDate) && <option key={"option" + rdv.id} value={rdv.id}>{rdv.subject} - {DateUtil.getTime(rdv.startDate)}-{DateUtil.getTime(rdv.endDate)}</option>
                        } else {
                          return null;
                        }
                      }
                    )}
                  </optgroup>})
                }
              </Form.Control>
          </Form.Group>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Objectif du RDV</Form.Label>
                <Form.Control as="select" onChange={(e) => setType(e.target.value)} disabled={!isReportAdd} defaultValue={isReportAdd ? type : rdv.report.type}>
                  <option key={""} value={""}></option>
                  <option key={"Démarchage"} value={"Démarchage"}>Démarchage</option>
                  <option key={"Découverte"} value={"Découverte"}>Découverte</option>
                  <option key={"Signature"} value={"Signature"}>Signature</option>
                  <option key={"Formation"} value={"Formation"}>Formation</option>
                  <option key={"Test"} value={"Test"}>Test</option>
                  <option key={"Autre"} value={"Autre"}>Autre</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Modalité du RDV</Form.Label>
                <Form.Control as="select" onChange={(e) => setProcess(e.target.value)} disabled={!isReportAdd} defaultValue={isReportAdd ? process : rdv.report.process}>
                  <option key={""} value={""}></option>
                  <option key={"Visio"} value={"Visio"}>Visio</option>
                  <option key={"Téléphone"} value={"Téléphone"}>Téléphone</option>
                  <option key={"Physique"} value={"Physique"}>Physique</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>PIPE</Form.Label>
              <Form.Control as="select" className="mb-2" onChange={(e) => setPipe(e.target.value)} defaultValue={isReportAdd ? pipe : rdv.report.pipe} disabled={!isReportAdd}>
                {Object.values(MarkerPipe).map((pipe:any) => {
                  if (typeof pipe !== "string") return null;
                  return <option key={pipe} value={pipe}>{pipe}</option>
                })}
              </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Rapport</Form.Label>
            <Form.Control as="textarea"
              name="body"
              id="body"
              className="mb-2"
              style={{ height: '10em' }}
              value={isReportAdd ? body : rdv.report.body}
              disabled={!isReportAdd}
              onChange={(e) => setBody(e.target.value) } />
          </Form.Group>

          {isReportAdd && <Button color="primary"
            className="me-2"
            disabled={formDisabled}
            onClick={() => openConfModal()}>Enregistrer</Button>}
        </Form>
        {confModal}
      </Modal.Body>
    </Modal>
  );
}