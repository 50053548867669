import Lottie from 'react-lottie-player';
import * as dataFetching from "../42631-mechanical-engineering-setting.json";
import * as login from "../78126-secure-login.json";
import * as notFound from "../67012-404-error-animation.json";
import * as map from "../64376-maps.json";

//Lottie animation for loading (more anim at https://lottiefiles.com/)

interface AnimatedPictureProps {
    context?:any
}

export default function AnimatedPicture({context=dataFetching}: AnimatedPictureProps) {
    const getAnimationData = () => {
        switch(context) {
            case "login":
                return login;
            case "notFound":
                return notFound;
            case "map":
                return map;
            default:
                return dataFetching;
        }
    }
    return (
        <Lottie 
            loop
            animationData={getAnimationData()}
            play
            style={{width: 300, height: 300, margin: 'auto'}}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
    )
}