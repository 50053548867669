import { useState } from 'react'
import { Container, Button, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import MarkerModal from '../Map/MarkerModal/MarkerModal';
import DateUtil from '../../utils/DateUtil';
import TableFiltered from '../../sub/TableFiltered';
import ExportButton from '../../sub/ExportButton';
import { Roles } from '../../enums/Roles';
import ArrayUtils from '../../utils/ArrayUtils';

import Groupment from "../../interfaces/Groupment";
import Salesman from "../../interfaces/Salesman";
import User from "../../interfaces/User";
import Label from "../../interfaces/Label";
import Marker from "../../interfaces/Marker";
import Util from "../../utils/Util";

interface CommentsProps {
    username: string,
    userRole: number,
    data: Array<Marker>,
    groupments: Array<Groupment>,
    salesmen: Array<Salesman>,
    users: Array<User>
    labelsCategory: Array<Label>,
    labelsStatus: Array<Label>,
    setData: React.Dispatch<React.SetStateAction<Array<Marker>>>
}

export default function Comments({username, userRole, data, groupments, salesmen, users, labelsCategory, labelsStatus, setData}: CommentsProps) {
    const [filteredData, setFilteredData] = useState<Array<Marker>>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isMarkerAdd, setIsMarkerAdd] = useState<boolean>(false);
    const [markerId, setMarkerId] = useState<string>("");
    const [markerType, setMarkerType] = useState<string>("");

    const handleModalShow = (marker:Marker) => {
        if (marker === undefined) return;
        setMarkerId(marker._id)
        setMarkerType(marker.type)
        setShowModal(true);
    };

    const getClientName = (clientId:string) => {
        let client:Marker = data.find((item:Marker) => item._id === clientId)!;
        return client !== undefined ? client.nom : "Inconnue(e)";
    }

    const getAllComments = () => {
        let items = data.map((item:any) => {return  {id: item._id, comments: item.commentaires}});
        return items.sort((a,b) => ArrayUtils.descendingSort(new Date(a.comments[a.comments.length-1].date).getTime(),new Date(b.comments[b.comments.length-1].date).getTime()));
    }

    const getExportData = () => {
        let data = filteredData.length > 0 ? filteredData : getTableData();
        return data.map((item) => item.id.data);
    }

    const getTableData = () => {
        let data:Array<any> = [];
        let items = getAllComments();
        let options = users.map((user:User) => { return {value: user.username, label: user.username}})
        items.map((item:any) => {
            let lastComment = item.comments[item.comments.length-1]
            if (lastComment.comment !== "") {
                data.push({
                    id: {data: item.id}, 
                    date: {data: DateUtil.toddMMyyyy(lastComment.date), type: "input"}, 
                    auteur: {data: lastComment.username !== "" ? lastComment.username : "Inconnu(e)", type: "select", options: options},
                    prospect: {data: getClientName(item.id), type:"input"},
                    commentaire: {data: Util.truncateString(lastComment.comment,25), type: "input"}
                })
            }
            return null;
        })
        return data;
    }

    const getTableAction = (itemId:any) => {
        return <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => handleModalShow(Util.getMarker(itemId, data)!)}><FontAwesomeIcon icon={faEye} /></Button> 
    }

    return (
        <>
        {!isMarkerAdd &&
            <Container className={"mt-5"}>
                {userRole === Roles.ADMIN && <Row xs="auto" className={"mb-4"}>
                    <ExportButton 
                        APIUrl={"/markers/exportComment"}
                        fileName={"rapports-de-visite-commentaires"}
                        fileExtension={"xlsx"}
                        data={getExportData()}                
                    />
                </Row>}
                <TableFiltered
                    data={getTableData()}
                    setData={setFilteredData}
                    hasActions={true}
                    actionsComponent={((itemId:any) => getTableAction(itemId))}
                    hasFilters={true}
                    actionsLabel={"VOIR LA FICHE PROSPECT"}
                    hasExport={true}
                />
                {showModal &&
                <MarkerModal
                    data={data}
                    setData={setData}
                    markerId={markerId}
                    markerType={markerType}
                    setShowModal={setShowModal}
                    isMarkerAdd={false}
                    setIsMarkerAdd={setIsMarkerAdd}
                    userRole={userRole}
                    username={username}
                    groupments={groupments}
                    salesmen={salesmen}
                    users={users}
                    labelsCategory={labelsCategory}
                    labelsStatus={labelsStatus}
                />
                }
            </Container>
        }
        </>
    )
}