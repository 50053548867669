import axios from 'axios';
import { useEffect, useState } from 'react'
import { Container, Button } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPenAlt, faTrashAlt, faUnlock, faUser } from '@fortawesome/free-solid-svg-icons';

import UserModal from './UserModal';
import EventModal from '../../sub/EventModal';
import TableFiltered from '../../sub/TableFiltered';

import 'react-notifications/lib/notifications.css';
import AnimatedPicture from '../../sub/AnimatedPicture';
import User from "../../interfaces/User";
import Util from '../../utils/Util';

interface UserManagementProps {
    userInfo: {userId: string}
}

export default function UserManagement({userInfo}: UserManagementProps) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<Array<User>>([]);
    const [userId, setUserId] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [confModal, setConfModal] = useState<JSX.Element | null>(null);

    useEffect(() => {
        axios.get("/user/all").then(response => {
            setUsers(response.data);
            setIsLoading(false);
        })
    }, [])

    const handleEdit = (userId:string) => {
        setUserId(userId);
        setIsUpdate(true);
        setShowModal(true);
    }

    const handleCanLogin = (userId:string, userCanLogin:boolean) => {
        let updatedData = {
            userId: userId,
            updatedField: "canLogin",
            updatedValue: !userCanLogin
        }
        axios.post("/user/update", updatedData).then((response) => {
            let allUsers:Array<User> = [...users];
            let index = allUsers.findIndex((item:User) => item._id === userId);
            allUsers[index].canLogin = response.data.canLogin;
            setUsers(allUsers);
            NotificationManager.info("Ce compte utilisateur est "+ (response.data.canLogin ? "débloqué" : "bloqué" ));
        });
    }

    const deleteUser = (userId:string) => {
        axios.post("/user/delete", {userId: userId}).then(() => {
            const newUsers = users.filter((user:User) => user._id !== userId);
            setUsers(newUsers);
            setConfModal(null);
            NotificationManager.success("Le compte utilisateur a été supprimé.");
        })
    }

    const openConfModal = (id:string) => {
        let title = "Attention";
        let content = "Êtes-vous sûr de vouloir supprimer cet utilisateur ?";
        let modal = <EventModal
                        title={title}
                        content={content}
                        context={"danger"}
                        close = {setConfModal}
                        confirm={() => deleteUser(id)}
                    />
        setConfModal(modal);
    }

    const getTableData = () => {
        let data:Array<Object> = [];
        users.map((user:User) => {
            data.push({
                id: {data: user._id},
                nom_utilisateur: {data: user.username, type: "input"},
                email: {data: user.email, type: "input"},
                mot_de_passe: {data: "*************", type: "input"},
                fonction: {data: Util.RoleFormalyze(user.role), type: "input"},
                canLogin: {data: user.canLogin, type: "input"}
            });
            return null;
        })
        return data;
    }

    const getTableAction = (ItemId:string, canLogin:boolean) => {
        return <>
                {userInfo.userId !== ItemId && 
                <Button variant={canLogin ? "success" : "danger"} size={"sm"} className={"mx-2"} onClick={() => handleCanLogin(ItemId, canLogin)}><FontAwesomeIcon icon={canLogin ? faUnlock : faLock} /></Button>
                }
                <Button variant={"secondary"} size={"sm"} className={"mx-2"} onClick={() => handleEdit(ItemId)}><FontAwesomeIcon icon={faPenAlt} /></Button> 
                {userInfo.userId !== ItemId && 
                <Button variant={"danger"} size={"sm"} onClick={() => openConfModal(ItemId)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                }
            </>
    }

    return (
        <>
        {isLoading && 
            <Container>
                <AnimatedPicture />
                <h1>Chargement en cours...</h1>
            </Container>
        }
        
        {!isLoading && 
            <Container className={"mt-5"}>
                <Button className={"m-5"} onClick={() => setShowModal(true)}><FontAwesomeIcon icon={faUser}/> Ajouter un utilisateur</Button>
                <TableFiltered
                    data={getTableData()}
                    setData={null}
                    hasActions={true}
                    actionsComponent={(itemId:string, canLogin:boolean) => getTableAction(itemId, canLogin)}
                    hasFilters={true}
                />
                {showModal &&
                    <UserModal 
                        userId={userId}
                        users={users}
                        setUsers={setUsers}
                        setShowModal={setShowModal}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                    />
                }
                {confModal}
            </Container>
        }
        </>
    )
}