import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Roles } from "../../enums/Roles";
import ExportButton from "../../sub/ExportButton";
import TableFiltered from "../../sub/TableFiltered";
import Util from "../../utils/Util";
import MarkerAdd from "./MarkerAdd";
import MarkerModal from "./MarkerModal/MarkerModal";

import Groupment from "../../interfaces/Groupment";
import Marker from "../../interfaces/Marker";
import Salesman from "../../interfaces/Salesman";
import User from "../../interfaces/User";
import Label from "../../interfaces/Label";
import ViewSelection from "./ViewSelection";

interface TableViewProps {
    username: string,
    userRole: number,
    data: Array<Marker>,
    setData: React.Dispatch<React.SetStateAction<Array<Marker>>>,
    groupments: Array<Groupment>,
    salesmen: Array<Salesman>,
    labelsCategory: Array<Label>,
    labelsStatus: Array<Label>,
    users: Array<User>,
    userId: string,
    selectedView: string, 
    setSelectedView: React.Dispatch<React.SetStateAction<string>>,
}

export default function TableView({username, userRole, data, setData, groupments, salesmen, labelsCategory, labelsStatus, users, userId, selectedView, setSelectedView}:TableViewProps) {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [isMarkerAdd, setIsMarkerAdd] = useState<boolean>(false);
    const [markerId, setMarkerId] = useState<string>("");
    const [markerType, setMarkerType] = useState<string>("");

    const handleModalShow = (marker:Marker | undefined) => {
        if (marker === undefined) return;
        setMarkerId(marker._id)
        setMarkerType(marker.type)
        setShowModal(true);
    };

    const handleModalShowMarkerAdd = (markerId:string) => {
        setMarkerId(markerId);
        setShowModal(true);
    };

    const getMarker = (markerId:string) => {
        return data.find((item:Marker) => item._id === markerId);
    }

    const getTableData = () => {
        let tableData: Array<any> = [];
        let salesmanOptions = salesmen.map((salesman:Salesman) => {return {value: salesman.first_name + " "+ salesman.name, label: salesman.first_name + " "+ salesman.name}})
        let clients = ["CLIENT", "HORS CIBLE", "PROSPECT"];
        let clientOptions = clients.map((client:string) => {return {value: client, label: client}})
        data.map((item:Marker) => {
            let salesman:string = Util.getSalesmanName(salesmen, item.departement_details.salesman);
            let groupment:string = Util.getGroupementName(groupments, item.groupement)
            tableData.push({
                id: {data: item._id}, 
                prospect: {data: item.nom, type: "input"},
                commercial: {data: salesman, type: "select", options: salesmanOptions},
                groupement: {data: groupment, type: "input"},
                client: {data: item.client, type: "select", options: clientOptions},
                numero_interne: {data: item.num_interne, type: "input"},
                code_postal: {data: item.cp, type: "input"},
                ville: {data: item.ville, type: "input"}
            })
            return null;
        })
        return tableData;
    }

    const getTableAction = (itemId:string) => {
        return <>
                <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => handleModalShow(getMarker(itemId))}><FontAwesomeIcon icon={faEye} /></Button> 
            </>
    }

    return (
        <Container className={"mt-5"}>
            <ViewSelection 
                userId={userId} 
                selectedView={selectedView} 
                setSelectedView={setSelectedView} 
            />

            {userRole !== Roles.SALESMAN && 
            <Row xs="auto" className="justify-content-center mb-5">
                <Col xs="6" md="auto"><MarkerAdd setIsMarkerAdd={setIsMarkerAdd} handleModalShow={(index:string) => handleModalShowMarkerAdd(index)}/></Col>
                {userRole === Roles.ADMIN && <Col xs="6" md="auto"><ExportButton APIUrl={"/markers/export"} fileName={"prospects"} fileExtension={"xlsx"} data={null} /></Col>}
            </Row>}

            <TableFiltered
                data={getTableData()}
                setData={null}
                hasActions={true}
                actionsComponent={((itemId:string) => getTableAction(itemId))}
                hasFilters={true}
                actionsLabel={"VOIR LA FICHE PROSPECT"}
                hasExport={false}
            />

            {showModal &&
                <MarkerModal
                    data={data}
                    setData={setData}
                    markerId={markerId}
                    markerType={markerType}
                    setShowModal={setShowModal}
                    isMarkerAdd={isMarkerAdd}
                    setIsMarkerAdd={setIsMarkerAdd}
                    userRole={userRole}
                    username={username}
                    groupments={groupments}
                    salesmen={salesmen}
                    users={users}
                    labelsCategory={labelsCategory}
                    labelsStatus={labelsStatus}
                />
            }
        </Container>
    )
}