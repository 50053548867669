import { faLock, faSync, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import User from "../../interfaces/User";
import TableFiltered from "../../sub/TableFiltered";
import Util from "../../utils/Util";

interface CompanyUsersModalProps {
    userId: string,
    companyId: string,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CompanyUsersModal({userId, companyId, setShowModal}: CompanyUsersModalProps) {
    const [users, setUsers] = useState<Array<User>>([]);

    useEffect(() => {
        axios.post("/user/all/company", {companyId: companyId}).then((response) => {
            setUsers(response.data);
        });
    }, [companyId])

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleCanLogin = (userId:string, userCanLogin:boolean) => {
        let updatedData = {
            companyId: companyId,
            userId: userId,
            updatedField: "canLogin",
            updatedValue: !userCanLogin
        }
        axios.post("/user/update/company", updatedData).then((response) => {
            let allUsers:Array<User> = [...users];
            let index = allUsers.findIndex((item:User) => item._id === userId);
            allUsers[index].canLogin = response.data.canLogin;
            setUsers(allUsers);
            NotificationManager.info("Ce compte utilisateur est "+ (response.data.canLogin ? "débloqué" : "bloqué" ));
        });
    }

    const handleResetRgpd = (userId:string) => {
        axios.post("/rgpd/reset/user", {userId: userId}).then(() => {
            NotificationManager.success("Le RGPD a été réinitialisé pour cet utilisateur");
        });
    }

    const getTableData = () => {
        let data:Array<Object> = [];
        users.map((user:User) => {
            data.push({
                id: {data: user._id},
                nom_utilisateur: {data: user.username, type: "input"},
                email: {data: user.email, type: "input"},
                fonction: {data: Util.RoleFormalyze(user.role), type: "input"},
                canLogin: {data: user.canLogin, type: "input"}
            });
            return null;
        })
        return data;
    }

    const getTableAction = (ItemId:string, canLogin:boolean) => {
        return (
            <>
                {userId !== ItemId &&
                    <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip"+ItemId}>L'utilisateur est {canLogin ? "actif" : "bloqué"}</Tooltip>}>
                        <Button variant={canLogin ? "success" : "danger"} size={"sm"} className={"mx-2"} onClick={() => handleCanLogin(ItemId, canLogin)}><FontAwesomeIcon icon={canLogin ? faUnlock : faLock} /></Button>
                    </OverlayTrigger>
                }
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip"+ItemId}>Réinitialiser le RGPD</Tooltip>}>
                    <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => handleResetRgpd(ItemId)}><FontAwesomeIcon icon={faSync} /></Button>
                </OverlayTrigger>
            </>
        )
    }

    return (
        <Modal
            show={true}
            onHide={handleModalClose}
            backdrop={"static"}
            keyboard={false}
            size={"xl"}
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Gestion des utilisateurs"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TableFiltered
                    data={getTableData()}
                    setData={null}
                    hasActions={true}
                    actionsComponent={(itemId:string, canLogin:boolean) => getTableAction(itemId, canLogin)}
                    hasFilters={true}
                />
            </Modal.Body>
        </Modal>
    )
}