import L from "leaflet";

export default class MapUtil {
    static setMarkerColor = (type: any) => {
        const type_colors:any = {
            "PSDM": "red",
            "COLLECTIVITE": "blue",
            "PHARMACIE": "green",
            "USER": "violet"
        };
        return new L.Icon({
            iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${type_colors[type]}.png`,
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });
    }
}