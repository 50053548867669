import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Button, Row, InputGroup, Form, Col, Tabs, Tab, Container, Alert, Spinner} from 'react-bootstrap'
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt, faTrashAlt, faPlusCircle, faSync, faMapPin } from '@fortawesome/free-solid-svg-icons';
import MarkerModalComment from './MarkerModalComment';
import LocationAutosuggest from '../../../sub/LocationAutosuggest';
import EventModal from '../../../sub/EventModal';
// import MarkerModalDocument from './MarkerModalDocument';
import { Roles } from '../../../enums/Roles';

import 'react-notifications/lib/notifications.css';
import MarkerModalEvent from './MarkerModalEvent';
import { useAppContext } from '../../../AppContext';
import AnimatedPicture from '../../../sub/AnimatedPicture';
import Util from '../../../utils/Util';
import { UnauthenticatedTemplate } from '@azure/msal-react';

import Marker from "../../../interfaces/Marker";
import Salesman from "../../../interfaces/Salesman";
import User from "../../../interfaces/User";
import Groupment from "../../../interfaces/Groupment";
import Label from "../../../interfaces/Label";
import MarkerModalReport from './MarkerModalReport';
import { MarkerPipe } from "../../../enums/MarkerPipe";

interface MarkerModalProps {
    data: Array<Marker>,
    setData: React.Dispatch<React.SetStateAction<Array<Marker>>>,
    markerId: string | undefined,
    markerType: string | undefined | "PSDM" | "PHARMACIE" | "COLLECTIVITE",
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    isMarkerAdd: boolean,
    setIsMarkerAdd: React.Dispatch<React.SetStateAction<boolean>>,
    userRole: number,
    username: string,
    groupments: Array<Groupment>,
    salesmen: Array<Salesman>,
    users: Array<User>
    labelsCategory: Array<Label>,
    labelsStatus: Array<Label>,
    selectTab?: string
}

function MarkerModal({data, setData, markerId, markerType, setShowModal, isMarkerAdd, setIsMarkerAdd, userRole, username, groupments, salesmen, users, labelsCategory, labelsStatus, selectTab = "prospectHeaders"}:MarkerModalProps) {
    const [action, setAction] = useState<string>("read");
    const [selectedTab, setSelectedTab] = useState<string>(selectTab);
    const [error, setError] = useState<string>("");
    const [confModal, setConfModal] = useState<JSX.Element | null>(null);
    const [marker, setMarker] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(isMarkerAdd ? false : true);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);

    const items:any = {groupement: groupments, libelle_categorie: labelsCategory, libelle_statut: labelsStatus, commercial: salesmen};

    const regexEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    const app = useAppContext();

    useEffect(() => {
        !isMarkerAdd && axios.post("/markers/type", {markerId: markerId, markerType: markerType}).then((response) => {
            setMarker(response.data);
            setIsLoading(false);
            setIndex(data.findIndex((item:Marker) => item._id === markerId));
        });
    }, [isMarkerAdd, markerId, markerType, data])
    
    const allCategories = useMemo(() => [...Array.from(new Set(data.map((item:Marker) => item.type)))], [data]);
    const allClients = ["CLIENT", "HORS CIBLE", "PROSPECT"];
    
    const headers = [
        "type", "nom", "contact", "adresse", "cp", "ville", "telephone", "mail", "numero_dept",
        "dept", "region", "groupement", "libelle_categorie",
        "date_ouverture", "libelle_statut", "capacite", "finess"
    ];
    
    const getHeadersByType = (type: "PSDM" | "PHARMACIE" | "COLLECTIVITE" | string) => {
        switch(type) {
            case "PSDM":
            case "PHARMACIE":
                let newHeaders = [...headers];
                newHeaders.splice(newHeaders.indexOf("libelle_categorie"), 1);
                newHeaders.splice(newHeaders.indexOf("date_ouverture"), 1);
                newHeaders.splice(newHeaders.indexOf("libelle_statut"), 1);
                newHeaders.splice(newHeaders.indexOf("capacite"), 1);
                newHeaders.splice(newHeaders.indexOf("finess"), 1);
                newHeaders.push("siren");
                return newHeaders
            case "COLLECTIVITE":
                return headers;
            default:
                break;
        }
    }

    const [prospectHeaders, setProspectHeaders] = useState(!isMarkerAdd && marker !== undefined ? getHeadersByType(marker.type) : headers );

    useEffect(() => {
        !isMarkerAdd && marker !== undefined && setProspectHeaders(getHeadersByType(marker.type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMarkerAdd, marker])
    
    const saleHeaders = [
        "commercial", "mail_commercial", "mail_assistante_commerciale", "priorite_cciale_departement", 
        "lien_calendrier_ccial", "num_interne", "client", "pipe"
    ];

    const requiredHeaders = ["type", "groupement", "nom", "adresse", "cp", "ville", "commercial", "numero_dept", "dept", "region", "client"];
    const requiredHeadersCOLL = ["type", "groupement", "nom", "adresse", "cp", "ville", "commercial", "numero_dept", "dept", "region", "client", "libelle_categorie", "libelle_statut"];

    const initialFormData:any = {
        type: "",
        nom: "",
        contact: "",
        adresse: "",
        cp: "",
        ville: "",
        telephone: "",
        mail: "",
        numero_dept: "",
        priorite_cciale_departement: "Information liée au département",
        dept: "Assignation automatique",
        region: "Assignation automatique",
        commercial: "Information liée au département",
        mail_commercial: "Information liée au commercial",
        mail_assistante_commerciale: "Information liée au commercial",
        lien_calendrier_ccial: "",
        groupement: "",
        type_prospect: "",
        num_interne: "Génération automatique",
        client: "",
        pipe: "NON QUALIFIÉ",
        libelle_categorie: "",
        date_ouverture: "",
        libelle_statut: "",
        capacite: "",
        finess: "",
        siren: "",
        commentaires: [{username: "", comment: "", date: ""}],
        latitude: 0,
        longitude: 0,
        result_label: "",
        result_score: 0,
        result_type: "",
        result_id: "",
        result_housenumber: "",
        result_name: "",
        result_street: "",
        result_postcode: "",
        result_city: "",
        result_context: "",
        result_citycode: "",
        result_oldcitycode: "",
        result_oldcity: "",
        result_district: ""
    }

    const [formData, setFormData] = useState(initialFormData);

    const isRequired = (header:string) => {
        let allRequiredHeaders = requiredHeaders;
        if (formData.type === "COLLECTIVITE") allRequiredHeaders = requiredHeadersCOLL;
        return allRequiredHeaders.includes(header);
    }

    const isInvalidForm = () => {
        let isInvalid = false;
        let allRequiredHeaders = requiredHeaders;
        if (formData.type === "COLLECTIVITE") allRequiredHeaders = requiredHeadersCOLL;
        allRequiredHeaders.map((header:string) => {
            if (formData[header] === "") return isInvalid = true;
            return null;
        });
        return isInvalid;
    }

    const checkData = (field:string, value:string) => {
        if (isRequired(field) && value.trim().length-1 < 0) setError("Le champ "+Util.formalize(field)+" ne peut pas être vide")
        switch(field) {
            case "mail":
            case "mail_commercial":
            case "mail_assistante_commerciale":
                if (value.trim() !== "") {
                    if (!value.match(regexEmail)) setError("L'adresse e-mail du champ "+Util.formalize(field)+" n'est pas correcte.")
                }
                break;
            default:
                break
        }
    }

    const handleChange = (e:any) => {
        if (isMarkerAdd) {
            setFormData((oldData:any) => ({...oldData, [e.target.name]: e.target.value.toUpperCase()}));
            if (e.target.name === "type" && e.target.value !== "") {
                setProspectHeaders(getHeadersByType(e.target.value.toUpperCase()));
            }
        } else {
            setMarker((oldData:any) => ({...oldData, [e.target.name]: e.target.value.toUpperCase()}));
        }
    };
    
    const selectOptionNode = (items:Array<any>) => {
        return items.sort().map((item, i) => {
            return <option key={i} value={item}>{item.toUpperCase().trim()}</option>
        })
    }
    
    const selectNode = (header:string, items:Array<any>) => {
        return (
        <Form.Select name={header} defaultValue={!isMarkerAdd && marker !== undefined && (marker[header] || "")}
            onChange={(e:any) => isMarkerAdd ? handleChange(e) : updateMarker(header, e.target.value)}>
            <option value=""></option>
            {selectOptionNode(items)}
        </Form.Select>)
    }

    const selectOptionNode2 = (items:Array<any>) => {
        return items.sort().map((item, i) => {
            return <option key={i} value={item._id}>{item.first_name !== undefined ? item.first_name +" "+ item.name.toUpperCase() : item.name.toUpperCase().trim()}</option>
        })
    }

    const selectNode2 = (header:string, items:Array<any>) => {
        return (
        <Form.Select name={header} defaultValue={!isMarkerAdd && marker !== undefined && (marker[header] || "")} 
            onChange={(e:any) => isMarkerAdd ? handleChange(e) : updateMarker(header, e.target.value)}>
            <option value=""></option>
            {selectOptionNode2(items)}
        </Form.Select>)
    }

    const filterGroupments = (type: string) => {
        let filteredGroupments = [];
        for (let i = 0; i < groupments.length; i++) {
            if (type === groupments[i].type || groupments[i].type === "Tous" || groupments[i].type === "non-renseigné") {
                filteredGroupments.push(groupments[i]);
            }
        }
        return filteredGroupments;
    }

    const getAllPipe = () => {
        let allPipe = [];
        for(const pipe of Object.values(MarkerPipe)) {
            if (typeof pipe === 'string') allPipe.push(pipe);
        }
        return allPipe;
    }
    
    const getInputType = (header:string) => {
        let content = null;
        switch(header) {
            case "type":
                content = selectNode(header, allCategories);
                break;
            case "commercial":
                content = <Form.Control
                disabled
                name={header}
                defaultValue={!isMarkerAdd && marker !== undefined ? Util.displayData(header, marker["departement_details"].salesman,items[header]) || "" : formData[header].toUpperCase()}
                />
                break;
            case "client":
                content = selectNode(header, allClients);
                break;
            case "pipe":
                content = selectNode(header, getAllPipe());
                break;
            case "mail_commercial":
                content = <Form.Control
                disabled
                name={header}
                defaultValue={!isMarkerAdd && marker !== undefined ? Util.getSalesmanEmail(salesmen, marker["departement_details"].salesman) || "" : formData[header].toUpperCase()}
                />
                break;
            case "mail_assistante_commerciale":
                content = <Form.Control
                disabled
                name={header}
                defaultValue={!isMarkerAdd && marker !== undefined ? Util.getSalesmanAssistantEmail(salesmen, marker["departement_details"].salesman) || "" : formData[header].toUpperCase()}
                />
                break;
            case "date_ouverture": 
                content = <Form.Control
                type="date"
                name={header}
                onChange={isMarkerAdd ? handleChange : (e) => updateMarker(header, e.target.value.toUpperCase())}
                value={!isMarkerAdd && marker !== undefined ? marker[header] || "" : formData[header]}
                onBlur={(e) => !isMarkerAdd ? updateMarker(header, e.target.value.toUpperCase()) : checkData(e.target.name, e.target.value.toUpperCase())}
                />
                break;
            case "priorite_cciale_departement":
                content = <Form.Control
                disabled
                name={header}
                value={!isMarkerAdd && marker !== undefined ? marker["departement_details"].priorite_cciale || "" : formData[header].toUpperCase()}
                onBlur={(e) => !isMarkerAdd ? updateMarker(header, e.target.value.toUpperCase()) : checkData(e.target.name, e.target.value.toUpperCase())}
                />
                break;
            case "dept":
                content = <Form.Control
                disabled
                name={header}
                value={!isMarkerAdd && marker !== undefined ? marker["departement_details"].name || "" : formData[header].toUpperCase()}
                onBlur={(e) => !isMarkerAdd ? updateMarker(header, e.target.value.toUpperCase()) : checkData(e.target.name, e.target.value.toUpperCase())}
                />
                break;
            case "region":
                content = <Form.Control
                disabled
                name={header}
                value={!isMarkerAdd && marker !== undefined ? marker["departement_details"].region || "" : formData[header].toUpperCase()}
                onBlur={(e) => !isMarkerAdd ? updateMarker(header, e.target.value.toUpperCase()) : checkData(e.target.name, e.target.value.toUpperCase())}
                />
                break;
            case "num_interne":
                content = <Form.Control
                disabled
                name={header}
                defaultValue={!isMarkerAdd && marker !== undefined ? marker[header] || "" : formData[header].toUpperCase()}
                />
                break;
            case "groupement":
                let filteredGroupments: any = [];
                if (!isMarkerAdd && marker !== undefined) {
                    filteredGroupments = filterGroupments(marker["type"])
                } else {
                    filteredGroupments = filterGroupments(formData["type"])
                }
                content = selectNode2(header, filteredGroupments);
                break;
            case "libelle_categorie":
                content = selectNode2(header, labelsCategory);
                break;
            case "libelle_statut":
                content = selectNode2(header, labelsStatus);
                break;
            case "adresse":
                content = <LocationAutosuggest
                                defaultValue={!isMarkerAdd && marker !== undefined ? marker[header] || "" : formData[header]}
                                type={"housenumber"}
                                isMarkerAdd={isMarkerAdd}
                                setFullAddress={(type:string, address:string, city:string, cp:string, numDept:string, dept:string, region:string) => onChangeAutosuggest(type, address, city, cp, numDept, dept, region)}
                                setState={(value:string) => isMarkerAdd ? setFormData((oldData:any) => ({...oldData, adresse: value.toUpperCase()})) : updateMarker("adresse", value.toUpperCase())}
                            />
                break;
            case "ville":
                content = <LocationAutosuggest 
                                defaultValue={!isMarkerAdd && marker !== undefined ? marker[header] || "" : formData[header]}
                                type={"municipality"}
                                isMarkerAdd={isMarkerAdd}
                                setFullAddress={(type:string, address:string, city:string, cp:string, numDept:string, dept:string, region:string) => onChangeAutosuggest(type, address, city, cp, numDept, dept, region)}
                                setState={(value:string) => isMarkerAdd ? setFormData((oldData:any) => ({...oldData, ville: value.toUpperCase()})) : updateMarker("ville", value.toUpperCase())}
                            />
                break;
            default:
                content = <Form.Control 
                    type={"text"} 
                    name={header} 
                    value={!isMarkerAdd && marker !== undefined ? marker[header] || "" : formData[header].toUpperCase()} 
                    disabled={userRole === Roles.SALESMAN}
                    onChange={handleChange} 
                    onBlur={(e) => !isMarkerAdd ? updateMarker(header, e.target.value.toUpperCase()) : checkData(e.target.name, e.target.value.toUpperCase())}
                />
                break;
        }
        return content;
    }

    const onChangeAutosuggest = (type:string, address:string, city:string, cp:string, numDept:string, dept:string, region:string) => {
        if (isMarkerAdd) {
            if (type === "housenumber") {
                setFormData((oldData:any) => ({...oldData, adresse: address.trim().toUpperCase(), ville: city.trim().toUpperCase(), cp: cp.trim(), numero_dept: numDept.trim(), dept: dept.trim().toUpperCase(), region: region.trim().toUpperCase()}));
            } else {
                setFormData((oldData:any) => ({...oldData, ville: city.trim().toUpperCase(), cp: cp.trim(), numero_dept: numDept.trim(), dept: dept.trim().toUpperCase(), region: region.trim().toUpperCase()}));
            }
        } else {
            updateMarker("adresse", address.toUpperCase());
            updateMarker("ville", city.toUpperCase());
            updateMarker("cp", cp);
            updateMarker("numero_dept", numDept);
        }
    }

    const getModalContent = (headers:Array<string>) => {
        return headers.map((header, i) => {
            if (!isMarkerAdd && marker !== undefined) {
                let value = marker[header as keyof Marker];
                if (header === "priorite_cciale_departement") value = marker["departement_details"].priorite_cciale;
                if (header === "dept") value = marker["departement_details"].name;
                if (header === "region") value = marker["departement_details"].region;
                if (header === "commercial") value = marker["departement_details"].salesman;
                if (header === "mail_commercial") value = Util.getSalesmanEmail(salesmen, marker["departement_details"].salesman);
                if (header === "mail_assistante_commerciale") value = Util.getSalesmanAssistantEmail(salesmen, marker["departement_details"].salesman);
                return <Row xs="auto" key={"content" + i}><h6><span className="text-primary">{Util.formalize(header)}:</span> {Util.displayData(header,value,items[header])}</h6></Row>
            } else {
                return <Form.Group as={Row} key={"content" + i} className="mb-3" controlId="formPlaintextEmail">
                <Form.Label column sm="4"><span style={isRequired(header) ? {color: "red"} : {color: "black"}}>{Util.formalize(header)}{isRequired(header) && "*"}</span></Form.Label>
                    <Col sm="8">
                        {getInputType(header)}
                    </Col>
                </Form.Group>
            }
        })
    }

    const updateModalContent = (headers:Array<string>) => {
        return headers.map((header, i) => {
            return <Row xs="auto" key={"content" + i}>
                    <InputGroup className="mb-3">
                        <Col sm="5" xs="12">
                            <InputGroup.Text id={"content-title-"+i}>{Util.formalize(header)}</InputGroup.Text>
                        </Col>
                        <Col sm="7" xs="12">
                            {getInputType(header)}
                        </Col>
                    </InputGroup>
            </Row>
        })
    }

    const handleModalClose = () => {
        setShowModal(false);
        setIsMarkerAdd(false);
    };

    const deleteMarker = () => {
        handleModalClose();

        if (marker === undefined) return;

        axios.post("/markers/delete", {markerId: marker._id}).then(() => {
            const markers = data.filter((item:any) => item._id !== marker._id);
            setData(markers);
            NotificationManager.success("Le marqueur a été supprimé.");
        });
    }

    const updateMarker = (updatedField:string, updatedValue:any) => {
        if (marker === undefined) return;
        let copyData:any = [...data];
        let previousValue:any = copyData[index][updatedField];
        if (previousValue === updatedValue) return;

        let updatedData = {
            markerId: marker._id,
            updatedField: updatedField,
            updatedValue: updatedValue
        }
        checkData(updatedField, updatedValue);
        if (error === "") {
            axios.post("/markers/update", updatedData).then((response) => {
                let dataUpdated:any = [...data];
                dataUpdated[index][updatedField] = response.data[updatedField];
                setData(dataUpdated);
                if (updatedField === "adresse" || updatedField === "cp" || updatedField === "ville") locate();
            });
        }
        if (updatedField === "type" && updatedValue !== "") {
            setProspectHeaders(getHeadersByType(updatedValue));
        }
    }

    const locate = async () => {
        if (marker === undefined) return;

        let address = marker.adresse+" "+marker.cp+" "+marker.ville;
        let newLocation:any = {markerId: marker._id}
        try {
            await axios.get(encodeURI("https://api-adresse.data.gouv.fr/search/?q="+address), {headers:null}).then((response) => {
                newLocation = {...newLocation,
                    latitude: response.data.features[0].geometry.coordinates[1],
                    longitude: response.data.features[0].geometry.coordinates[0],
                    result_context: response.data.features[0].properties.context,
                    result_score: response.data.features[0].properties.score
                };
            })
        } catch (error) {
            console.log(error);
            NotificationManager.error("Le service de localisation est indisponible.");
        }
        axios.post("/markers/locate", newLocation).then((response) => {
            let dataUpdated = [...data];
            dataUpdated[index] = response.data;
            setData(dataUpdated);
            NotificationManager.success("L'adresse a été synchronisée.");
        });
    }

    const createMarker = async () => {
        setIsAdding(true);
        let address = formData.adresse+" "+formData.cp+" "+formData.ville;
        let result = {...formData};
        if (formData.type === "PHARMACIE" || formData.type === "PSDM") {
            delete result.libelle_categorie;
            delete result.libelle_statut;
            delete result.capacite;
            delete result.finess;
        }
        try {
            await axios.get(encodeURI("https://api-adresse.data.gouv.fr/search/?q="+address), {headers:null}).then((response) => {
                result = {...result, 
                    latitude: response.data.features[0].geometry.coordinates[1],
                    longitude: response.data.features[0].geometry.coordinates[0],
                    result_context: response.data.features[0].properties.context,
                    result_score: response.data.features[0].properties.score
                };
            })
        } catch (error) {
            console.log(error);
            NotificationManager.error("Le service de localisation est indisponible.");
        }
        axios.post("/markers/create", result).then((response) => {
            let dataUpdated = [...data];
            dataUpdated.push(response.data)
            setShowModal(false)
            setIsMarkerAdd(false)
            setData(dataUpdated);
            setIsAdding(false);
            NotificationManager.success(`Le marqueur ${response.data.num_interne} a été créé.`);
        });
    }

    let resultScore = (!isMarkerAdd && marker !== undefined) || !isLoading ? (marker.result_score*100).toFixed(0) : 0;

    const handleTab = (tab:string) => {
        setSelectedTab(tab);
        setAction("read");
    }

    const handleUpdateButton = () => {
        setAction("update");
    }

    const openConfModal = () => {
        let title = "Attention";
        let content = "Êtes-vous sûr de vouloir supprimer ce marqueur ?";
        let modal = <EventModal
                        title={title}
                        content={content}
                        context={"danger"}
                        close = {(value:any) => setConfModal(value)}
                        confirm={() => deleteMarker()}
                    />
        setConfModal(modal);
    }

    return (
        <Modal
            show={true}
            onHide={handleModalClose}
            backdrop={"static"}
            keyboard={false}
            restoreFocus={false}
            size="lg"
        >
            {isLoading && 
            <Container>
                <AnimatedPicture />
            </Container>
            }
            {!isLoading && 
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{!isMarkerAdd && marker !== undefined ? marker.nom +" | "+ marker.num_interne : "Nouveau marqueur"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {confModal}
                    <Tabs id="tab_marker" 
                        activeKey={selectedTab} 
                        onSelect={(tab) => handleTab(tab!)}>
                            
                        <Tab eventKey="prospectHeaders" title="Prospect">
                            <Container className="mt-3 mb-3">
                                {action === "read" ? getModalContent(prospectHeaders!) : updateModalContent(prospectHeaders!)}
                                {!isMarkerAdd && 
                                    <Row className="mt-3 mb-3">
                                        <Col><Button onClick={locate} className={"mt-1 mb-2"}><FontAwesomeIcon icon={faSync}/> Synchroniser l'adresse</Button></Col>
                                        <Col><span>Fiabilité de l'adresse: {resultScore} %</span></Col>
                                    </Row>
                                }
                            </Container>
                        </Tab>

                        <Tab eventKey="saleHeaders" title="Commercial">
                            <Container className="mt-3 mb-3">
                                {action === "read" ? getModalContent(saleHeaders) : updateModalContent(saleHeaders)}
                            </Container>
                        </Tab>

                        {!isMarkerAdd && marker !== undefined && <Tab eventKey="commentaries" title="Commentaires">
                            <MarkerModalComment
                            userRole={userRole}
                            username={username}
                            updateMarker={(updatedField:string, updatedValue:any) => updateMarker(updatedField, updatedValue)}
                            marker={marker}
                            />
                        </Tab>}

                        {/* {!isMarkerAdd && <Tab eventKey="documents" title="Documents">
                            <MarkerModalDocument
                            data={data}
                            marker={marker}
                            index={index}
                            setData={setData}
                            />
                        </Tab>} */}
                        {!isMarkerAdd && <Tab eventKey="event" title="Rendez-vous" mountOnEnter>
                            <UnauthenticatedTemplate>
                                <Button variant="primary mt-3 mb-3" onClick={app.signIn}>Se connecter à Office 365</Button>
                            </UnauthenticatedTemplate>
                            <MarkerModalEvent
                                userRole={userRole}
                                marker={marker}
                                updateMarker={updateMarker}
                                salesmen={salesmen}
                            />
                        </Tab>}

                        {!isMarkerAdd && <Tab eventKey="reports" title="Comptes-rendus">
                            <MarkerModalReport
                                username={username}
                                userRole={userRole}
                                marker={marker}
                                updateMarker={updateMarker}
                            />
                        </Tab>}
                    </Tabs>
                    {error !== "" && <Alert variant={"danger"} onClose={() => setError("")} dismissible><h6>{error}</h6></Alert>}
                </Modal.Body>

                {!isMarkerAdd && selectedTab !== "commentaries" && selectedTab !== "documents" && selectedTab !== "event" && selectedTab !== "reports" &&
                <Modal.Footer>
                    {marker !== undefined && <Button variant="primary" href={encodeURI("https://www.google.com/maps/dir/?api=1&destination="+ marker.adresse+" "+marker.cp+" "+marker.ville+"&travelmode=driving")} target="_blank"><FontAwesomeIcon icon={faMapPin} color={"hsl(0deg 100% 100%)"}/> Créer un itinéraire</Button>}
                    {(userRole !== Roles.SALESMAN && action === "read") &&
                        <Button variant="secondary" onClick={handleUpdateButton}><FontAwesomeIcon icon={faPenAlt} color={"hsl(0deg 100% 100%)"}/> Modifier</Button>
                    }
                    {userRole !== Roles.SALESMAN && <Button variant="danger" onClick={openConfModal}><FontAwesomeIcon icon={faTrashAlt} color={"hsl(0deg 100% 100%)"}/> Supprimer</Button>}
                </Modal.Footer>
                }
            </>
            }

            {isMarkerAdd &&
                <Modal.Footer>
                    {isAdding ?
                    <Button variant={"primary"} disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Ajout en cours...</Button>
                    :
                    <Button variant="primary" onClick={createMarker} disabled={isInvalidForm()}>
                        <FontAwesomeIcon icon={faPlusCircle}/> Ajouter
                    </Button>
                    }
                </Modal.Footer>
            }
        </Modal>
    )
}

export default MarkerModal;