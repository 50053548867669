import axios from 'axios';
import { useEffect, useState } from 'react'
import { Container, Tabs, Tab } from 'react-bootstrap';

import AnimatedPicture from '../../sub/AnimatedPicture';
import Reports from "./Reports";
import Comments from "./Comments";

import Groupment from "../../interfaces/Groupment";
import Salesman from "../../interfaces/Salesman";
import User from "../../interfaces/User";
import Label from "../../interfaces/Label";
import Marker from "../../interfaces/Marker";

export default function VisitReports({username, userRole}:{username:string, userRole:number}) {
    const [selectedTab, setSelectedTab] = useState<string | null>("reports");
    const [data, setData] = useState<Array<Marker>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [groupments, setGroupments] = useState<Array<Groupment>>([])
    const [salesmen, setSalesmen] = useState<Array<Salesman>>([])
    const [users, setUsers] = useState<Array<User>>([]);
    const [labelsCategory, setLabelsCategory] = useState<Array<Label>>([]);
    const [labelsStatus, setLabelsStatus] = useState<Array<Label>>([]);

    useEffect(() => {
        axios.all([
            axios.get("/markers/visitreport"),
            axios.get("/groupment/all"),
            axios.get("/salesman/all"),
            axios.get("/user/all"),
            axios.get("/labelCategory/all"),
            axios.get("/labelStatus/all")
        ]).then(axios.spread((markersResponse, groupsResponse, salesmanResponse, usersResponse, labelsCategoryResponse, labelsStatusResponse) => {
            setData(markersResponse.data);
            setGroupments(groupsResponse.data);
            setSalesmen(salesmanResponse.data);
            setUsers(usersResponse.data);
            setLabelsCategory(labelsCategoryResponse.data);
            setLabelsStatus(labelsStatusResponse.data);
            setIsLoading(false);
        }))
    }, [])

    return (
        <>
        {isLoading && 
            <Container>
                <AnimatedPicture />
                <h1>Chargement en cours...</h1>
            </Container>
        }
        
        {!isLoading &&
        <Container className={"mt-3"}>
            <Tabs defaultActiveKey={selectedTab || ""} onSelect={(tab: string | null) =>setSelectedTab(tab)} id="visit-reports-tabs" className="mb-3">
                <Tab eventKey="reports" title="Rapports">
                    <Reports 
                        username={username}
                        userRole={userRole}
                        data={data}
                        groupments={groupments}
                        salesmen={salesmen}
                        users={users}
                        labelsCategory={labelsCategory}
                        labelsStatus={labelsStatus}
                        setData={setData}
                    />
                </Tab>
                <Tab eventKey="comments" title="Commentaires">
                    <Comments 
                        username={username}
                        userRole={userRole}
                        data={data}
                        groupments={groupments}
                        salesmen={salesmen}
                        users={users}
                        labelsCategory={labelsCategory}
                        labelsStatus={labelsStatus}
                        setData={setData}
                    />
                </Tab>
            </Tabs>
        </Container>
        }
        </>
    )
}