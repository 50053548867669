import { useState } from 'react';
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import Util from '../../utils/Util';

interface MapFiltersButtonProps {
    button: Array<string | object>,
    filter: (filter: string) => void,
    variant: string | "button" | "select" | "search",
    isId?: boolean,
    category?: Array<string>
}

export default function MapFiltersButton({ button, filter, variant, isId = false, category}:MapFiltersButtonProps) {

    const [active, setActive] = useState<Array<string>>(["button0"]);

    const setColor = (type: "PSDM" | "COLLECTIVITE" | "PHARMACIE") => {
        const type_colors = {
            "PSDM": 0,
            "COLLECTIVITE": 220,
            "PHARMACIE": 110
        };
        return `hsl(${type_colors[type]}deg 50% 50%)`;
    }

    const setActiveButton = (button: string) => {
        Util.multiFilter(active, "button0", button, setActive);
    }

    const formalize = (data: string) => {
        let result = data;
        switch (data) {
            case "#N/A":
            case "":
            case " ":
            case null:
                result = "Non-renseigné";
                break;
            default:
                break;
        }
        return result;
    }

    const displayButton = () => {
        return button.map((cat:any, i:number) => {
            let label = cat;
            let filterData = cat;
            if (typeof(cat) === "object") {
                label = cat.first_name +" "+ cat.name.toUpperCase();
                filterData = cat._id
            }
            return (
                <Col key={"button" + i} xs="12" md="auto">
                    <Button
                        variant={"outline-secondary mb-2"}
                        size="sm"
                        active={active.includes("button" + i) ? true : false}
                        onClick={() => { filter(filterData); setActiveButton("button" + i) }}
                    >
                        <FontAwesomeIcon icon={faMapMarkerAlt} color={setColor(label.toUpperCase())} /> {formalize(label)}
                    </Button>
                </Col>
            )
        })
    }

    const displaySelect = () => {
        return <Col xs="12" md="auto">
            <Form.Control as="select" size="sm" className='map-filters-select' onChange={(e) => filter(e.target.value)}>
                {button.map((cat:any, i:number) => {
                    let label = (isId && cat !== "Tous") ? cat.first_name !== undefined ? cat.name + " " + cat.first_name : cat.name : cat;
                    let value = (isId && cat !== "Tous") ? cat._id : cat;
                    let option = <option key={"option" + i} value={value}>{formalize(label)}</option>
                    if (category !== undefined && !category.includes("Tous")) {
                        if (category.includes(cat.type) || cat.type === "Tous" || cat.type === "non-renseigné" || cat === "Tous") return option;
                        else return null;
                    } else {
                        return option;
                    }
                })}
            </Form.Control>
        </Col>
    }

    const displaySearch = () => {
        return (
            <>
                <Col xs="12" md="auto">
                    <FormControl defaultValue={""} size="sm" placeholder={"Rechercher"} onBlur={(e) => filter(e.target.value.toUpperCase())} />
                </Col>
                <Col xs="12" md="auto">
                    <Button variant={"primary"} size="sm"><FontAwesomeIcon icon={faSearch} /></Button>
                </Col>
            </>
        )
    }

    return (
        <Row xs="auto" className={"mb-1"}>
            {variant === "button" && displayButton()}
            {variant === "select" && displaySelect()}
            {variant === "search" && displaySearch()}
        </Row>
    )
}