import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Map from "./components/Map/Map";
import Settings from "./components/Settings/Settings";
import NavBar from "./components/NavBar";
import NotFound from "./components/NotFound";
import Login from "./components/Login";
import axios from "axios";
import UserManagement from "./components/UserManagement/UserManagement";
import { Roles } from "./enums/Roles";
import EventModal from "./sub/EventModal";
import MarkersToCheck from "./components/MarkersToCheck/MarkersToCheck";
import { MsalProvider } from "@azure/msal-react";
import ProvideAppContext from "./AppContext";
import Stats from "./components/Stats/Stats";
import { IPublicClientApplication } from "@azure/msal-browser/dist/app/IPublicClientApplication";
import NotificationsContainer from "./sub/NotificationsContainer";
import CompanyManagement from "./components/CompanyManagement/CompanyManagement";
import Footer from "./components/Footer";
import RgpdModal from "./components/RgpdModal";
import VisitReports from "./components/VisitReports/VisitReports";

interface AppProps {
    pca: IPublicClientApplication
}

export default function App({pca}: AppProps) {
    const [modal, setModal] = useState<JSX.Element | null>(null);
    const [showRgpdModal, setShowRgpdModal] = useState<boolean>(false);

    const storedUser = sessionStorage.getItem('user');

    axios.defaults.baseURL = process.env.REACT_APP_BE_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const openErrorModal = (message: string) => {
        let title = "Erreur";
        let content =  message;
        let modal = <EventModal
                        title={title}
                        content={content}
                        context={"danger"}
                        close = {setModal}
                        confirm={() => setModal(null)}
                    />
        setModal(modal);
    }
    
    const checkIsLogged = () => {
        let isLogged = false;
        if (storedUser !== null) isLogged = true;
        return isLogged;
    }
    
    let user: { userId: any; } | null = null;
    if (storedUser !== null) user = JSON.parse(storedUser);
    
    const sessionJWT = sessionStorage.getItem('jwt');
    
    if (sessionJWT && user) {
        axios.defaults.headers.common['jwtToken'] = sessionJWT;
    } else {
        axios.defaults.headers.common['jwtToken'] = null;
    }

    const [isLogged, setIsLogged] = useState<boolean>(() => checkIsLogged());
    const [userRole, setUserRole] = useState<number>(Roles.SALESMAN);
    const [username, setUsername] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user === null) return;
        axios.all([
            axios.post("/user/role", {userId: user.userId}),
            axios.post("/user/username", {userId: user.userId}),
            axios.get("/rgpd/"),
        ]).then(axios.spread((roleResponse, usernameResponse, rgpdResponse) => {
            setUserRole(roleResponse.data)
            setUsername(usernameResponse.data);
            if (rgpdResponse.data === null) setShowRgpdModal(true);
            setIsLoading(false);
        }))
    }, [user])

    useEffect(() => {
        axios.interceptors.response.use(undefined, (err) => {
            if (err.response && err.response.status === 500) {
                const errorMessage = (err.response.hasOwnProperty('data') && err.response.data.hasOwnProperty('message')) ? err.response.data.message : "Une erreur inattendue s'est produite.";
                openErrorModal(errorMessage)
                return new Promise(() => { });
            }
            else if (err.response && err.response.status === 403) {
                const hash = window.location.hash;
                window.location.href = "/#/";
                if (hash.indexOf("map") !== -1) return new Promise(() => { });
            }
            else throw err;
        });
    }, [user])

    return (
        <MsalProvider instance={ pca }>
            <ProvideAppContext>
                <Router>
                    <div className="App min-vh-100">
                        {isLogged && <NavBar 
                            setIsLogged={setIsLogged} 
                            userRole={userRole} 
                            username={username} 
                        />}

                        {modal}

                        {isLogged && showRgpdModal &&
                            <RgpdModal 
                                setIsLogged={setIsLogged}
                                setShowRgpdModal={setShowRgpdModal}
                            />
                        }
                        
                        {/* Little ticks to use notification container on TypeScript file */}
                        <NotificationsContainer/>

                        {!isLoading && 
                            <Switch>
                                <Route exact path="/">
                                    {isLogged ? userRole !== Roles.SUPERADMIN ? <Redirect to="/map" /> : <Redirect to="/companies" /> : <Redirect to="/auth" />}
                                </Route>
                                <Route exact path="/auth">
                                    {!isLogged ? <Login setIsLogged={setIsLogged} /> : <Redirect to={"/"} />}
                                </Route>
                                <Route exact path="/map">
                                    {isLogged && userRole !== Roles.SUPERADMIN ? <Map userId={user?.userId} /> : <Redirect to={"/auth"} />}
                                </Route>
                                <Route exact path="/settings">
                                    {isLogged && userRole !== Roles.SALESMAN ? <Settings /> : <Redirect to={"/auth"} />}
                                </Route>
                                <Route exact path="/users">
                                    {isLogged && userRole === Roles.ADMIN ? <UserManagement userInfo={user?.userId} /> : <Redirect to={"/auth"} />}
                                </Route>
                                <Route exact path="/companies">
                                    {isLogged && userRole === Roles.SUPERADMIN ? <CompanyManagement userId={user?.userId} /> : <Redirect to={"/auth"} />}
                                </Route>
                                <Route exact path="/markers-to-check">
                                    {isLogged && userRole !== Roles.SALESMAN && userRole !== Roles.SUPERADMIN ? <MarkersToCheck userRole={userRole} username={username} /> : <Redirect to={"/auth"} />}
                                </Route>
                                <Route exact path="/visit-reports">
                                    {isLogged ? <VisitReports username={username} userRole={userRole} /> : <Redirect to={"/auth"} />}
                                </Route>
                                <Route exact path="/stats">
                                    {isLogged && userRole === Roles.ADMIN ? <Stats /> : <Redirect to={"/auth"} />}
                                </Route>
                                <Route path="*">
                                    <NotFound />
                                </Route>
                            </Switch>
                        }

                        {isLoading && 
                            <Switch>
                                <Route exact path="/">
                                    {isLogged ? userRole !== Roles.SUPERADMIN ? <Redirect to="/map" /> : <Redirect to="/companies" /> : <Redirect to="/auth" />}
                                </Route>
                                <Route exact path="/auth">
                                    {!isLogged ? <Login setIsLogged={setIsLogged} /> : <Redirect to={"/"} />}
                                </Route>
                                <Route exact path="/map">
                                    {isLogged && userRole !== Roles.SUPERADMIN ? <Map userId={user?.userId} /> : <Redirect to={"/auth"} />}
                                </Route>
                                <Route exact path="/companies">
                                    {isLogged && userRole === Roles.SUPERADMIN ? <CompanyManagement userId={user?.userId} /> : <Redirect to={"/auth"} />}
                                </Route>
                            </Switch>
                        }
                    </div>
                    
                    <Footer lang={"fr"} />
                </Router>
            </ProvideAppContext>
        </MsalProvider>
    );
}