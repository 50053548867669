import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal, Button, InputGroup, FormControl, Alert } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import ArrayUtils from '../utils/ArrayUtils';
import StringUtil from '../utils/StringUtil';

import 'react-notifications/lib/notifications.css';

interface CrudModalProps {
    apiRouterPathRoot: string,
    crudItemId: string,
    crudItems: Array<any>,
    setCrudItems: any,
    setShowModal: any,
    isUpdate: boolean,
    setIsUpdate: any,
    elementLabel: string,
    isSalesmanCrud?: boolean,
    isGroupmentCrud?: boolean,
    isDepartmentCrud?: boolean,
    salesmen: any
}

function CrudModal({apiRouterPathRoot, crudItemId, crudItems, setCrudItems, setShowModal, isUpdate, setIsUpdate, elementLabel, isSalesmanCrud=false, isGroupmentCrud=false, isDepartmentCrud=false, salesmen}:CrudModalProps) {

    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [nameError, setNameError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [simCrudItems, setSimCrudItems] = useState<any>([]);
    const [type, setType] = useState("Tous");
    const [num, setNum] = useState("");
    const [region, setRegion] = useState("");
    const [numError, setNumError] = useState("");
    const [selectedSalesman, setSelectedSalesman] = useState("");
    const [selectedPriority, setSelectedPriority] = useState("");
    const [salesmanEmail, setSalesmanEmail] = useState("");
    const [salesmanAssistantEmail, setSalesmanAssistantEmail] = useState("");

    const handleModalClose = () => {
        setShowModal(false);
        setIsUpdate(false);
    };

    const checkSimilarities = (value:string) => {
        let crudItemsName = [];
        let simCrudItems = [];

        for (let crudItem of crudItems) crudItemsName.push(crudItem.name);
        outer: for (let m0 of crudItemsName) {
            let m1 = value;

            if (m0 === m1) continue;

            for (let s of simCrudItems) {
                if ((s[0] === m1 && s[1] === m0) || (s[0] === m0 && s[1] === m1)) {
                    continue outer;
                }
            }
            if (StringUtil.similarity(m0, m1) > .6) {
                simCrudItems.push([m0, m1]);
            }
        }
        setSimCrudItems(simCrudItems);
    }

    const isExist = (value:string) => {
        let result = false;
        let crudItem = crudItems.find(item => item.name === value.toUpperCase().trim());
        if (crudItem !== undefined) result = true;
        return result;
    }

    const checkIsDisabled = (field:string, value:string) => {
        switch(field) {
            case "name":
                (value.trim().length-1 < 0) ? setNameError("Le nom ne peut pas être vide") : setName(value);
                break;
            case "first_name":
                (value.trim().length-1 < 0) ? setFirstNameError("Le prénom ne peut pas être vide") : setFirstName(value);
                break;
            default:
                break;
        }
    }

    const isDisabled = () => {
        let isDisabled = false;
        if (name.trim().length-1 < 0) {setNameError("Le nom ne peut pas être vide"); isDisabled = true}
        if (isSalesmanCrud && firstName.trim().length-1 < 0) {setFirstNameError("Le prénom ne peut pas être vide"); isDisabled = true};
        if (isDepartmentCrud && num.trim().length-1 < 0) {setNumError("Le numéro de département ne peut pas être vide"); isDisabled = true};
        return isDisabled;
    }

    const createCrudItem = () => {
        let newCrudItem:any = {name: name};
        if (isSalesmanCrud) newCrudItem = {first_name: firstName, name: name, email: salesmanEmail, assistantEmail: salesmanAssistantEmail};
        if (isGroupmentCrud) newCrudItem = {name: name, type: type};
        if (isDepartmentCrud) newCrudItem = {num: num, name: name, salesman: selectedSalesman};
        if (nameError === "" && firstNameError === "" && !isDisabled()) {
            if (!isExist(name)) {
                axios.post(apiRouterPathRoot + "/create", newCrudItem).then((response) => {
                    let allCrudItems = [...crudItems];
                    newCrudItem._id = response.data;
                    allCrudItems.push(newCrudItem);
                    if (isDepartmentCrud) {
                        setCrudItems(allCrudItems.sort((a,b) => ArrayUtils.ascendingSort(a.num,b.num)));    
                    } else {
                        setCrudItems(allCrudItems.sort((a,b) => ArrayUtils.ascendingSort(a.name,b.name)));
                    }
                    setShowModal(false);
                    NotificationManager.success("L'élément a été créé.");
                })
            } else {
                NotificationManager.error("Impossible de créer cet élément.");
                setNameError("L'élément \""+name+"\" existe déjà.")
            }
        } else {
            NotificationManager.error("Impossible de créer cet élément.");
        }
    }

    const updateCrudItem = async (updatedField:string, updatedValue:any) => {
        let updatedData = {
            crudItemId: crudItemId,
            updatedField: updatedField,
            updatedValue: updatedValue
        }
        if (!isExist(updatedValue)) {
            if (nameError === "" && firstNameError === "" && !isDisabled() && updatedValue.trim() !== "") {
                axios.post(apiRouterPathRoot + "/update", updatedData).then((response) => {
                    let allCrudItems = [...crudItems];
                    let index = allCrudItems.findIndex(item => item._id === crudItemId);
                    allCrudItems[index][updatedField] = response.data[updatedField];
                    if (isDepartmentCrud) {
                        setCrudItems(allCrudItems.sort((a,b) => ArrayUtils.ascendingSort(a.num,b.num)));    
                    } else {
                        setCrudItems(allCrudItems.sort((a,b) => ArrayUtils.ascendingSort(a.name,b.name)));
                    }
                    NotificationManager.success("Les modifications ont été sauvegardées");
                });
            } else {
                NotificationManager.error("Impossible de modifier cet élément.");
                setNameError("L'élément ne peut pas être vide.")
            }
        } else {
            NotificationManager.error("Impossible de modifier cet élément.");
            setNameError("L'élément \""+updatedValue+"\" existe déjà.")
        }
    }

    useEffect(() => {
        if (isUpdate) {
            axios.post(apiRouterPathRoot, {crudItemId: crudItemId}).then((response) => {
                let item = response.data;
                setName(item.name);
                isSalesmanCrud && setFirstName(item.first_name);
                isSalesmanCrud && setSalesmanEmail(item.email);
                isSalesmanCrud && setSalesmanAssistantEmail(item.assistantEmail);
                isGroupmentCrud && setType(item.type);
                isDepartmentCrud && setNum(item.num);
                isDepartmentCrud && setRegion(item.region);
                isDepartmentCrud && setSelectedSalesman(item.salesman);
                isDepartmentCrud && setSelectedPriority(item.priorite_cciale);
            })
        }
    }, [isUpdate, crudItemId, apiRouterPathRoot, isSalesmanCrud, isGroupmentCrud, isDepartmentCrud])

    const handleUpdate = (updatedField:string, updatedValue:any) => {
        if (isGroupmentCrud) setType(updatedValue);
        if (isDepartmentCrud && updatedField === "salesman") setSelectedSalesman(updatedValue);
        if (isDepartmentCrud && updatedField === "priorite_cciale") setSelectedPriority(updatedValue);
        updateCrudItem(updatedField, updatedValue);
    }

    return (
        <Modal
            show={true}
            onHide={handleModalClose}
            backdrop={"static"}
            keyboard={false}
            size={undefined}
        >
            <Modal.Header closeButton>
                <Modal.Title>{isUpdate ? firstName +" "+ name : "Ajout d'un " + elementLabel}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isDepartmentCrud && 
                    <InputGroup className="mb-3">
                        <InputGroup.Text id={"num_dept"}>Numéro de département</InputGroup.Text>
                        <FormControl 
                            defaultValue={num} name={"num_dept"} type={"text"} isInvalid={numError !== ""} disabled={isDepartmentCrud}
                            onChange={(e) => {checkSimilarities(e.target.value); setNum(e.target.value.toUpperCase().trim()); checkIsDisabled(e.target.name, e.target.value)}}
                            onBlur={(e) => isUpdate ? handleUpdate("num", e.target.value.toUpperCase().trim()) : setNum(e.target.value.toUpperCase().trim())} 
                        />
                    </InputGroup>
                }
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"name"}>Nom</InputGroup.Text>
                    <FormControl 
                        defaultValue={name} name={"name"} type={"text"} isInvalid={nameError !== ""} disabled={isDepartmentCrud}
                        onChange={(e) => {checkSimilarities(e.target.value); setName(e.target.value.toUpperCase().trim()); checkIsDisabled(e.target.name, e.target.value)}}
                        onBlur={(e) => isUpdate ? handleUpdate("name", e.target.value.toUpperCase().trim()) : setName(e.target.value.toUpperCase().trim())} 
                    />
                </InputGroup>

                {isDepartmentCrud && 
                    <InputGroup className="mb-3">
                        <InputGroup.Text id={"region"}>Région</InputGroup.Text>
                        <FormControl 
                            defaultValue={region} name={"region"} type={"text"} disabled={isDepartmentCrud}
                        />
                    </InputGroup>
                }

                {isSalesmanCrud &&
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"first_name"}>Prénom</InputGroup.Text>
                    <FormControl 
                        defaultValue={firstName} name={"first_name"} type={"text"} isInvalid={firstNameError !== ""} 
                        onChange={(e) => {checkSimilarities(e.target.value); checkIsDisabled(e.target.name, e.target.value)}}
                        onBlur={(e) => isUpdate ? handleUpdate("first_name", e.target.value.trim()) : setFirstName(e.target.value.trim())} 
                    />
                </InputGroup>
                }

                {isSalesmanCrud &&
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"email"}>Email</InputGroup.Text>
                    <FormControl 
                        defaultValue={salesmanEmail} name={"email"} type={"text"}
                        onChange={(e) => {checkSimilarities(e.target.value); checkIsDisabled(e.target.name, e.target.value)}}
                        onBlur={(e) => isUpdate ? handleUpdate("email", e.target.value.trim()) : setSalesmanEmail(e.target.value.trim())} 
                    />
                </InputGroup>
                }

                {isSalesmanCrud &&
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"assistantEmail"}>Email assistante</InputGroup.Text>
                    <FormControl 
                        defaultValue={salesmanAssistantEmail} name={"assistantEmail"} type={"text"}
                        onChange={(e) => {checkSimilarities(e.target.value); checkIsDisabled(e.target.name, e.target.value)}}
                        onBlur={(e) => isUpdate ? handleUpdate("assistantEmail", e.target.value.trim()) : setSalesmanAssistantEmail(e.target.value.trim())} 
                    />
                </InputGroup>
                }

                {isGroupmentCrud && 
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"type"}>Type</InputGroup.Text>
                    <FormControl as="select" name={"type"} value={type}
                        onChange={(e) => isUpdate ? handleUpdate("type", e.target.value) : setType(e.target.value)}
                    >
                        <option value={"Tous"}>Tous</option>
                        <option value={"COLLECTIVITE"}>COLLECTIVITE</option>
                        <option value={"PHARMACIE"}>PHARMACIE</option>
                        <option value={"PSDM"}>PSDM</option>
                    </FormControl>
                </InputGroup>
                }

                {isDepartmentCrud && 
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"salesman"}>Commercial assigné</InputGroup.Text>
                    <FormControl as="select" name={"salesman"} value={selectedSalesman}
                        onChange={(e) => isUpdate && e.target.value !== "" ? handleUpdate("salesman", e.target.value) : e.target.value !== "" && setSelectedSalesman(e.target.value)}
                    >
                        <option value={""}>Sélectionnez un commercial</option>
                        {salesmen.map((item:any, i:number) => <option key={"option"+i} value={item._id}>{item.first_name} {item.name}</option>)}
                    </FormControl>
                </InputGroup>
                }

                {isDepartmentCrud &&
                    <InputGroup className="mb-3">
                        <InputGroup.Text id={"priorite_cciale"}>Priorité commerciale</InputGroup.Text>
                        <FormControl 
                            defaultValue={selectedPriority} name={"priorite_cciale"} type={"number"} min={1} max={7}
                            onChange={(e) => isUpdate ? handleUpdate("priorite_cciale", e.target.value.trim()) : setSelectedPriority(e.target.value.trim())}
                        />
                    </InputGroup>
                }

                {numError !== "" && <Alert variant={"danger"} onClose={() => setNumError("")} dismissible><h6>{numError}</h6></Alert>}
                {nameError !== "" && <Alert variant={"danger"} onClose={() => setNameError("")} dismissible><h6>{nameError}</h6></Alert>}
                {firstNameError !== "" && <Alert variant={"danger"} onClose={() => setFirstNameError("")} dismissible><h6>{firstNameError}</h6></Alert>}
                {simCrudItems.length > 0 && <Alert variant={"warning"}><h6>Éléments similaires :</h6>
                    <ul>
                        {simCrudItems.map((item:Array<any>, i:number) => <li key={i}>{item[0]}</li>)}
                    </ul>
                </Alert>}
            </Modal.Body>
            {!isUpdate && <Modal.Footer>
                <Button variant="primary" onClick={createCrudItem}>Terminer</Button>
            </Modal.Footer>}
        </Modal>
    )
}

export default CrudModal;