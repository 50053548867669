import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FormControl, InputGroup, Row, Table, Button, Col, Alert } from 'react-bootstrap';
import Pagination from 'react-paginate';
import Util from '../utils/Util';

interface TableFilteredProps {
    data: Array<any>,
    setData: any,
    hasActions?:boolean,
    actionsComponent?: any,
    hasFilters?: boolean,
    actionsLabel?: string,
    hasExport?: boolean,
    maxOnPage?: number
}

function TableFiltered({data, setData, hasActions=false, actionsComponent, hasFilters=false, actionsLabel="ACTION", hasExport=false, maxOnPage=10}:TableFilteredProps) {
    const [filteredData, setFilteredData] = useState<any>([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [filters, setFilters] = useState<any>({});

    const handlePageClick = (event:any) => {
        const selectedPage = event.selected;
        setSelectedPage(selectedPage + 1);
    };

    const filterData =  (header:string, e:any) => {
        const newSearchTerm:any = {...filters,[header]:e.target.value.toUpperCase()}
        if (e.target.value === "") delete newSearchTerm[header]
        setFilters(newSearchTerm);
        if (hasExport) setData(filteredData);
    }

    useEffect(() => {
        const filtersKeys = Object.keys(filters);
        setFilteredData(
            filtersKeys.length === 0
            ? data
            : data.filter((item:any) => {
                const hasCorrespondences = filtersKeys
                .map( key => {
                    if (typeof item[key].data === "object") {
                        if (item[key].data.props === undefined) return null;
                        if (item[key].data.props.value === undefined) return null;
                        return item[key].data.props.value.toString().toUpperCase().includes(filters[key].toString().toUpperCase())
                    } else {
                        return item[key].data.toUpperCase().includes(filters[key].toUpperCase())
                    }
                })
                .every(curVal=> !!curVal);
                return hasCorrespondences;
              })
        );
    }, [data, filters]);

    const resetFilters = () => {
        setFilteredData(data);
        setFilters("");
    }

    const displayAllTableHeaders = () => {
        let headers:Array<string> = [];
        if (data[0] !== undefined) {
            Object.entries(data[0]).forEach(([key, value]) => headers.push(key));
        }
        return headers.map((header, y) => header !== "id" && header !== "canLogin" && <th key={"header"+y} className={"p-4"}>{Util.formalize(header)}</th>)
    }

    const displayAllData = () => {
        const itemOfLastItem = selectedPage * maxOnPage;
        const indexOfFirstItem = itemOfLastItem - maxOnPage;
        let paginatedData = filteredData.slice(indexOfFirstItem , itemOfLastItem);
        return paginatedData.map((item:any, i:number) => {
            let values:Array<any> = [];
            Object.entries(item).forEach(([key, value]) => key !== "id" && key !== "canLogin" && values.push(item[key].data));
            return (
                <tr key={"data"+i}>
                    {values.map((value,y) => <td key={"value"+y} className={"p-3"}>{value}</td>)}
                    {hasActions && <td className={"pt-2"}>{!item.canLogin ? actionsComponent(item.id.data) : actionsComponent(item.id.data, item.canLogin.data)}</td>}
                </tr>
            )
        })
    }

    const displayAllFilters = () => {
        let headers:Array<any> = [];
        if (data[0] !== undefined) {
            Object.entries(data[0]).forEach(([key, value]) => headers.push({label: key, type: data[0][key].type, options: data[0][key].options}));
        }
        return headers.map((header, y) => {
            if (header.label !== "id" && header.label !== "canLogin") {
                switch(header.type) {
                    case "select":
                        let options = header.options.map((option:any, z:number) => <option key={"option"+z} value={option.value}>{option.label}</option>)
                        return (
                            <Col key={"filter"+y}>
                                <FormControl className='mb-3' value={filters[header.label] || ""} as="select" onChange={(e) => setFilters({...filters, [header.label]: e.target.value})} onBlur={(e) => {hasExport && setData(filteredData)}}>
                                    <option value={""}>{Util.formalize(header.label)}</option>
                                    {options}
                                </FormControl>
                            </Col>
                        )
                    default:
                        return (
                            <Col key={"filter"+y}>
                                <InputGroup className="mb-3">
                                    <FormControl value={filters[header.label] || ""} onChange={(e) => setFilters({...filters, [header.label]: e.target.value})} onBlur={(e) => filterData(header.label, e)} placeholder={Util.formalize(header.label)}/>
                                    <Button><FontAwesomeIcon icon={faSearch}/></Button>
                                </InputGroup>
                            </Col>
                        )
                }
            } else return null;
        })
    }

    return (
        <>
            {data.length > 0 ? 
            <>
                {hasFilters && 
                <Row xs="auto">
                    {displayAllFilters()}
                    <Col><Button variant={"warning"} onClick={() => resetFilters()} disabled={filters.length === 0}><FontAwesomeIcon icon={faFilter} /></Button></Col>
                </Row>}
                <Table striped bordered responsive size={"sm"} className={"mt-4"}>
                    <thead>
                        <tr>
                            {displayAllTableHeaders()}
                            {hasActions && <th className={"p-4"}>{actionsLabel.toUpperCase()}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {displayAllData()}
                    </tbody>
                </Table>

                {filteredData.length > maxOnPage && <Row xs="auto" className="justify-content-center">
                    <Pagination
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(filteredData.length / maxOnPage)}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        pageRangeDisplayed={maxOnPage} 
                        marginPagesDisplayed={maxOnPage}
                    />
                </Row>}
            </>
            :
            <Alert variant={"info"}>Aucune information</Alert>
            }
        </>
    )
}

export default TableFiltered
