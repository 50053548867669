import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import React from 'react';

interface MarkerAddProps {
    setIsMarkerAdd: React.Dispatch<React.SetStateAction<boolean>>,
    handleModalShow: (markerId: string) => void
}

export default function MarkerAdd({setIsMarkerAdd, handleModalShow}: MarkerAddProps) {
    const handleCreateButton = () => {
        setIsMarkerAdd(true); 
        handleModalShow("");
    }
    return (
        <Button variant={"primary"} onClick={handleCreateButton}><FontAwesomeIcon icon={faPlusCircle}/> Ajouter un marqueur</Button>
    )
}