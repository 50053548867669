import Crud from '../../sub/Crud';

export default function SettingsSalesmen() {
    return (
        <Crud
            apiRouterPathRoot={"/salesman"}
            elementLabel={"commercial"}
            isSalesmanCrud={true}
        />
   )
}