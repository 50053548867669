import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Container, FormControl, InputGroup, Button, Row, Alert } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import AnimatedPicture from '../sub/AnimatedPicture';

interface LoginProps {
    setIsLogged: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Login({setIsLogged}: LoginProps) {
    axios.defaults.baseURL = process.env.REACT_APP_BE_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isPasswordShowed, setIsPasswordShowed] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const history = useHistory();

    const login = () => {
        axios.post("/auth/login", { email: email, password: password }).then((response) => {
            sessionStorage.setItem("user", JSON.stringify(response.data));
            sessionStorage.setItem('jwt', response.headers.jwttoken);
            setIsLogged(true)
            history.push("/map");
        }).catch(() => {
            setHasError(true)
        })
    }

    return (
        <Container className="mt-5">
            <Row>
                <AnimatedPicture context={"login"}/>
            </Row>
            <Row className="justify-content-center">
                <Card style={{ width: '20rem' }}>
                    <Card.Body>
                        <Card.Title>Connexion</Card.Title>
                        <InputGroup className="mb-2">
                            <InputGroup.Text id={"email"}><FontAwesomeIcon icon={faUser}/></InputGroup.Text>
                            <FormControl defaultValue={""} type={"email"} placeholder={"e-mail"} onChange={(e) => setEmail(e.target.value) } />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id={"password"}><FontAwesomeIcon icon={faLock}/></InputGroup.Text>
                            <FormControl defaultValue={""} type={isPasswordShowed ? "text" : "password"} placeholder={"Mot de passe"} onChange={(e) => setPassword(e.target.value) } />
                            <Button variant="outline-secondary" onClick={() => setIsPasswordShowed(!isPasswordShowed)}><FontAwesomeIcon icon={isPasswordShowed ? faEye : faEyeSlash}/></Button>
                        </InputGroup>
                        {hasError && <Alert variant={"danger"} onClose={() => setHasError(false)} dismissible><h6>E-mail ou mot de passe incorrect</h6></Alert>}
                        <Button variant="primary" onClick={login}>Se connecter</Button>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}