import { Modal, Button } from 'react-bootstrap';

interface EventModalProps {
    size?: 'sm' | 'lg' | 'xl' | undefined,
    context: string,
    title: string,
    content: string,
    hideCancelButton?: boolean,
    cancelButtonLabel?: string,
    modalButtonConfirm?: string,
    close: React.Dispatch<React.SetStateAction<JSX.Element | null>>,
    confirm: () => void
}

function EventModal({size, context, title, content, hideCancelButton, cancelButtonLabel, modalButtonConfirm, close, confirm}:EventModalProps) {
    return (
        <div className="modal-bg show">
            <Modal show={true} onHide={() => close(null)} backdrop={"static"} dialogClassName={"modal-dialog " + size ? size : ""} size={size ? size : undefined} >
                <Modal.Header closeButton>
                    <Modal.Title bsPrefix={"modal-title text-" + context || "info"}>
                        {title ? title : "Confirmer"}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {content}
                </Modal.Body>

                <Modal.Footer>
                    {!hideCancelButton &&
                        <Button variant="secondary" onClick={() => close(null)}>
                            {cancelButtonLabel ? cancelButtonLabel : "Annuler"}
                        </Button>
                    }
                    <Button variant={"btn btn-" + context} onClick={() => confirm()}>
                        {modalButtonConfirm ? modalButtonConfirm : "Confirmer"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EventModal;