const config = {
    appId: process.env.REACT_APP_API_OUTLOOK,
    redirectUri: process.env.REACT_APP_FE_URL,
    scopes: [
      'user.read',
      'mailboxsettings.read',
      'calendars.readwrite'
    ]
};
  
export default config;