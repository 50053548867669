import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { getUserSharedCalendars, getUserSharedCalendarEvents } from '../../../GraphService';
import { useAppContext } from '../../../AppContext';
import { findIana } from "windows-iana";
import DateUtil from "../../../utils/DateUtil";
import Util from "../../../utils/Util";
import Salesman from "../../../interfaces/Salesman";
import { Calendar, Event } from 'microsoft-graph';
import Marker from '../../../interfaces/Marker';

interface MarkerModalEventAddProps {
  marker: Marker,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  updateMarker: (updatedField: string, updatedValue: any) => void,
  salesmen: Array<Salesman>
}

export default function MarkerModalEventImport({marker, setShowModal, updateMarker, salesmen}: MarkerModalEventAddProps) {
  const app = useAppContext();

  const [calendars, setCalendars] = useState<Calendar[]>();
  const [calendar, setCalendar] = useState<Calendar>();

  const [events, setEvents] = useState<Event[]>();
  const [event, setEvent] = useState<Event>();

  const [formDisabled, setFormDisabled] = useState<boolean>(true);

  useEffect(() => {
    setFormDisabled(calendar === undefined || event === undefined)
  }, [calendar, event]);

  useEffect(() => {
    const loadCalendars = async() => {
      if (app.user && !calendars) {
        try {
          const calendars = await getUserSharedCalendars(app.authProvider!);
          setCalendars(calendars);
          if (marker.departement_details.salesman !== undefined) {
            let salesmanEmail = Util.getSalesmanEmail(salesmen, marker.departement_details.salesman);
            let salesmanCalendar = calendars.find((item) => item.owner?.address === salesmanEmail);
            if (salesmanCalendar !== undefined) setCalendar(salesmanCalendar);
          }
        } catch (err: any) {
          app.displayError!(err.message);
        }
      }
    };

    loadCalendars();
  })

  useEffect(() => {
    const loadEvents = async() => {
      if (app.user && calendar && calendar.id) {
        try {
          const ianaTimeZones = findIana(app.user?.timeZone!);
          const events = await getUserSharedCalendarEvents(app.authProvider!, calendar.id, ianaTimeZones[0].valueOf());
          let onlyNewEvents = filterEventAlreadyAdded(events);
          setEvents(onlyNewEvents);
        } catch (err: any) {
          app.displayError!(err.message);
        }
      }
    };
    setEvent(undefined);
    setEvents(undefined);
    loadEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar]);

  const filterEventAlreadyAdded = (events: any) => {
    if (marker.rdv === undefined) return [];
    for (let i = events.length - 1; i >= 0; i--) {
      for (let j=0; j < marker.rdv.length; j++) {
        if (events[i] && (events[i].id === marker.rdv[j].id)) {
          events.splice(i, 1);
        }
      }
    }
    return events;
  }

  const importEvent = async () => {
    if (event === undefined) return;
    if (marker.rdv === undefined) return;
    let events:Array<Object> = [...marker.rdv];
    let newEvent = {};
    newEvent = {
      id: event.id,
      author: event.organizer!.emailAddress!.name,
      attendees: event.attendees,
      startDate: event.start!.dateTime,
      endDate: event.end!.dateTime,
      subject: event.subject,
      body: event.bodyPreview
    };
    events.push(newEvent);
    updateMarker("rdv", events)
    setShowModal(false);
  }

  const selectCalendarHandleChange = (value: string) => {
    if (calendars === undefined) return;
    let calendar = calendars.find((item) => item.id === value);
    setCalendar(calendar);
  }

  const selectEventHandleChange = (value: string) => {
    if (events === undefined) return;
    let event = events.find((item) => item.id === value);
    setEvent(event);
  }

  const getAllOptGroups = () => {
    if (events === undefined) return [];
    let onlyNewEvents = filterEventAlreadyAdded(events);
    let groups: Array<any> = [];
    for (let i = 0; i < onlyNewEvents.length; i++) {
      if (!groups.includes(DateUtil.toDate(onlyNewEvents[i].start?.dateTime))) {
        groups.push(DateUtil.toDate(onlyNewEvents[i].start?.dateTime));
      }
    }
    return groups;
  }

  return(
    <Modal
      show={true}
      onHide={() => setShowModal(false)}
      backdrop={"static"}
      keyboard={false}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{"Import de RDV"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="m-4">
          <Form.Group className="mb-2">
            <Form.Label>Calendrier concerné</Form.Label>
            <Form.Select onChange={(e) => selectCalendarHandleChange(e.target.value)} value={calendar ? calendar.id : ""}>
              <option key={"option"} value={""}></option>
              {calendars !== undefined && calendars.map((item:any, i:number) => 
                <option key={"option" + i} value={item.id}>{item.name} - {item.owner.address}</option>
              )}
            </Form.Select>
          </Form.Group>
          {calendar && <Form.Group className="mb-2">
            <Form.Label>RDV</Form.Label>
            <Form.Select onChange={(e) => selectEventHandleChange(e.target.value)}>
              <option key={"option"} value={""}></option>

              {getAllOptGroups().map((group) => {
                return <optgroup key={group} label={group}>
                  {events !== undefined && events.map((item:any, i:number) => 
                    group === DateUtil.toDate(item.start.dateTime) && <option key={"option" + i} value={item.id}>{item.subject} - {DateUtil.getTime(item.start.dateTime)}-{DateUtil.getTime(item.end.dateTime)}</option>
                  )}
                </optgroup>})}
            </Form.Select>
          </Form.Group>}
          {calendar && event &&
          <>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Début</Form.Label>
                <Form.Control type="text"
                  name="start"
                  id="start"
                  value={DateUtil.toDate(event.start?.dateTime)+" - "+DateUtil.getTime(event.start?.dateTime)}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Fin</Form.Label>
                <Form.Control type="text"
                  name="end"
                  id="end"
                  value={DateUtil.toDate(event.end?.dateTime)+" - "+DateUtil.getTime(event.end?.dateTime)}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea"
              name="body"
              id="body"
              className="mb-3"
              style={{ height: '10em' }}
              value={event.bodyPreview || ""}
              disabled
              />
          </Form.Group>
          </>}
          <Button color="primary"
            className="me-2"
            disabled={formDisabled}
            onClick={() => importEvent()}>Importer</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}