import { useEffect, useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import Label from '../../interfaces/Label';
import Marker from '../../interfaces/Marker';
import ArrayUtils from '../../utils/ArrayUtils';
import MapFiltersButton from "./MapFiltersButton";

interface MapFiltersProps {
    data: Array<Marker>,
    setCategory: (filter: string) => void,
    setSalesman: (filter: string) => void,
    setGroup: React.Dispatch<React.SetStateAction<string>>,
    setClient: React.Dispatch<React.SetStateAction<string>>,
    setLabelCategory: React.Dispatch<React.SetStateAction<string>>,
    setLabelStatus: React.Dispatch<React.SetStateAction<string>>,
    setInternalRef: React.Dispatch<React.SetStateAction<string>>,
    setNumeroDept: React.Dispatch<React.SetStateAction<string>>,
    setCp: React.Dispatch<React.SetStateAction<string>>,
    groupments: Array<Object>,
    salesmen: Array<Object>,
    labelsCategory: Array<Label>,
    labelsStatus: Array<Label>,
    category: Array<string>,
    visibility: string
}

export default function MapFilters({data, setCategory, setSalesman, setGroup, setClient, setLabelStatus, setInternalRef, setNumeroDept, setCp, groupments, salesmen, labelsCategory, labelsStatus, category, visibility}:MapFiltersProps) {
    // Data filters (categories and salesman)
    const allCategories = useMemo<Array<string>>(() => ["Tous", ...Array.from(new Set(data.map((item:any) => item.type).sort()))], [data]);
    const [buttonsTypes, setButtonTypes] = useState(allCategories);
    
    const allSalesman = useMemo<Array<string | object>>(() => ["Tous", ...Array.from(new Set(salesmen.sort((a:any,b:any) => ArrayUtils.ascendingSort(a.name,b.name))))], [salesmen]);
    const [buttonsSalesman, setButtonSalesman] = useState(allSalesman);

    const allGroups = useMemo<Array<string | object>>(() => ["Tous", ...Array.from(new Set(groupments.sort((a:any,b:any) => ArrayUtils.ascendingSort(a.name,b.name))))], [groupments]);
    const [buttonsGroup, setButtonGroup] = useState(allGroups);

    const allLabelsStatus = useMemo<Array<string | object>>(() => ["Tous", ...Array.from(new Set(labelsStatus.sort((a:any,b:any) => ArrayUtils.ascendingSort(a,b.name))))], [labelsStatus]);
    const [buttonsLabelStatus, setButtonLabelStatus] = useState(allLabelsStatus);

    const [buttonsClient] = useState<Array<string>>(["Tous", "CLIENT", "HORS CIBLE", "PROSPECT"]);

    useEffect(() => {
        setButtonTypes(allCategories)
        setButtonSalesman(allSalesman)
        setButtonGroup(allGroups)
        setButtonLabelStatus(allLabelsStatus)
    }, [allCategories, allSalesman, allGroups, allLabelsStatus])
    
    return (
        <Container fluid className={'map-menu-filters '+visibility}>
            <Row className={"mb-1 mt-5"}>
                <Container fluid className='mt-3'>

                    <Row md="auto" className='justify-content-center'><h6>Types de comptes</h6></Row>
                    <Row md="auto" className="justify-content-center mb-3">
                        <MapFiltersButton 
                            button={buttonsTypes}
                            filter={(button:string) => setCategory(button)}
                            variant={"button"} 
                        />
                    </Row>
                    
                    <Row md="auto" className='justify-content-center'><h6>Commerciaux</h6></Row>
                    <Row md="auto" className="justify-content-center mb-3">
                        <MapFiltersButton 
                            button={buttonsSalesman}
                            filter={(button:string) => setSalesman(button)}
                            variant={"button"} 
                        />
                    </Row>

                    <Row md="auto" className='justify-content-center'><h6>Groupement</h6></Row>
                    <Row md="auto" className="justify-content-center mb-3">
                        <MapFiltersButton 
                            button={buttonsGroup}
                            filter={(button:string) => setGroup(button)}
                            variant={"select"}
                            isId={true}
                            category={category}
                        />
                    </Row>

                    <Row md="auto" className='justify-content-center'><h6>Statut</h6></Row>
                    <Row md="auto" className="justify-content-center mb-3">
                        <MapFiltersButton 
                            button={buttonsLabelStatus}
                            filter={(button:string) => setLabelStatus(button)}
                            variant={"select"}
                            isId={true}
                        />
                    </Row>

                    <Row md="auto" className='justify-content-center'><h6>Relation commerciale</h6></Row>
                    <Row md="auto" className="justify-content-center mb-3">
                        <MapFiltersButton 
                            button={buttonsClient}
                            filter={(button:string) => setClient(button)}
                            variant={"select"} 
                        />
                    </Row>

                    <Row md="auto" className='justify-content-center'><h6>Numéro interne</h6></Row>
                    <Row md="auto" className="justify-content-center mb-3">
                        <MapFiltersButton 
                            button={[{}]}
                            filter={(button: string) => setInternalRef(button)}
                            variant={"search"} 
                        />
                    </Row>

                    <Row md="auto" className='justify-content-center'><h6>Numéro département</h6></Row>
                    <Row md="auto" className="justify-content-center mb-3">
                        <MapFiltersButton 
                            button={[{}]}
                            filter={(button: string) => setNumeroDept(button)}
                            variant={"search"} 
                        />
                    </Row>

                    <Row md="auto" className='justify-content-center'><h6>Code postal</h6></Row>
                    <Row md="auto" className="justify-content-center mb-3">
                        <MapFiltersButton 
                            button={[{}]}
                            filter={(button: string) => setCp(button)}
                            variant={"search"} 
                        />
                    </Row>
                </Container>
            </Row>
        </Container>
    )
}