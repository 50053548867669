import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container, Form, Tab, Tabs } from 'react-bootstrap'
import AnimatedPicture from '../../sub/AnimatedPicture';
import StatsBySalesman from './salesmen/StatsBySalesman';
import StatsByCommercialRelation from './salesmen/StatsByCommercialRelation';
import StatsByRegion from './region/StatsByRegion';
import Salesman from "../../interfaces/Salesman";
import Marker from "../../interfaces/Marker";

export default function Stats() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [salesmen, setSalesmen] = useState<Array<Salesman>>([]);
    const [data, setData] = useState<Array<Marker>>([]);
    const [selectedTab, setSelectedTab] = useState<string | null>("salesmen");

    useEffect(() => {
        axios.all([
            axios.get("/markers/stats"),
            axios.get("/salesman/all")
        ]).then(axios.spread((markersResponse, salesmanResponse) => {
            setData(markersResponse.data);
            setSalesmen(salesmanResponse.data);
            setIsLoading(false);
        }))

        axios.get("/salesman/all").then((salesmanResponse) => {
            setSalesmen(salesmanResponse.data);
            setIsLoading(false);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    const getDataInfo = (labels:Array<string>) => {
        let data = [];
        for (let i = 0; i < labels.length; i++) {
            data.push({value: labels[i], label: labels[i]})
        }
        return data;
    }

    const getCount = (salesmanId:string, filterField:string, filterValue:string) => {
        if (filterField === undefined || filterValue === "Tous") {
            return data.filter((item:Marker) => item.departement_details.salesman === salesmanId).length;
        } else {
            return data.filter((item:Marker) => item.departement_details.salesman === salesmanId && item[filterField as keyof Marker] === filterValue).length;
        }
    }

    const getFilterSelection = (data:Array<object>, filter:any, setfilter:any, allSelection:boolean=true) => {
        return (
            <Form.Group>
                {allSelection && <Form.Check type="radio" label="Tous" value="Tous" onChange={(e) => setfilter(e.target.value)} checked={filter === "Tous"} />}
                {data.map((item:any, i:number) => {
                    return <Form.Check  key={"option"+i} type="radio" label={item.label} value={item.value} onChange={(e) => setfilter(e.target.value)} checked={filter === item.value} />
                })}
            </Form.Group>                                                           
        )
    }

  return (
    <>
        {isLoading && 
            <Container>
                <AnimatedPicture />
                <h1>Chargement en cours...</h1>
            </Container>
        }

        {!isLoading && 
            <Container className="mt-4 mb-5">
                <Tabs defaultActiveKey={selectedTab || ""} onSelect={(tab: string | null) =>setSelectedTab(tab)} id="settings-tabs" className="mb-3">
                    <Tab eventKey="salesmen" title="Commerciaux" mountOnEnter={true} unmountOnExit={true}>
                        <StatsByCommercialRelation
                            data={data}
                            salesmen={salesmen}
                            getFilterSelection={(data:Array<object>, filter:any, setfilter:any, allSelection:boolean=true) => getFilterSelection(data, filter, setfilter, allSelection)}
                            getDataInfo={(labels:Array<string>) => getDataInfo(labels)}
                        />
                        <StatsBySalesman
                            data={salesmen}
                            labels={["Client", "Hors Cible", "Prospect"]}
                            chartTitle={"Établissements associés (précis)"}
                            filterField={"client"}
                            getCount={(salesmanId:string, filterField:string, filterValue:string) => getCount(salesmanId, filterField, filterValue)}
                            getFilterSelection={(data:Array<object>, filter:any, setfilter:any, allSelection:boolean=true) => getFilterSelection(data, filter, setfilter, allSelection)}
                        />
                        <StatsBySalesman
                            data={salesmen}
                            labels={["COLLECTIVITE", "PHARMACIE", "PSDM"]}
                            chartTitle={"Établissements associés (par type)"}
                            filterField={"type"}
                            getCount={(salesmanId:string, filterField:string, filterValue:string) => getCount(salesmanId, filterField, filterValue)}
                            getFilterSelection={(data:Array<object>, filter:any, setfilter:any, allSelection:boolean=true) => getFilterSelection(data, filter, setfilter, allSelection)}
                        />
                    </Tab>
                    <Tab eventKey="region" title="Régions" mountOnEnter={true} unmountOnExit={true}>
                        <StatsByRegion
                            data={data}
                            getFilterSelection={(data:Array<object>, filter:any, setfilter:any, allSelection:boolean=true) => getFilterSelection(data, filter, setfilter, allSelection)}
                            getDataInfo={(labels:Array<string>) => getDataInfo(labels)}
                        />
                    </Tab>
                </Tabs>
            </Container>
        }
    </>
  )
}