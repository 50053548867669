export default class ArrayUtils {
    static arrayRemove(arr: Array<any>, value: any) { 
        return arr.filter((ele) => { 
            return ele !== value; 
        });
    }
    
    static ascendingSort(a:any,b:any) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    }
    
    static descendingSort(a:any, b:any) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
    }
}