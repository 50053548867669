import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Roles } from '../enums/Roles';

interface NavBarProps {
    setIsLogged: React.Dispatch<React.SetStateAction<boolean>>,
    userRole: number,
    username: string
}

export default function NavBar({setIsLogged, userRole, username}:NavBarProps) {
    const logout = () => {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("jwt");
        setIsLogged(false);
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Container>
                <Navbar.Brand href="/map">
                    <img
                        src="./e4map_logo.png"
                        width="140"
                        height="50"
                        className="d-inline-block align-top"
                        alt="E4MAP"
                    />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav className="me-auto">
                        {userRole !== Roles.SUPERADMIN && <Nav.Link href="/map">Carte</Nav.Link>}
                        {userRole !== Roles.SUPERADMIN && <Nav.Link href="/visit-reports">Rapports de visite</Nav.Link>}
                        {userRole !== Roles.SALESMAN && userRole !== Roles.SUPERADMIN && <Nav.Link href="/markers-to-check">Marqueurs à vérifier</Nav.Link>}
                        {userRole === Roles.ADMIN && <Nav.Link href="/users">Gestion des utilisateurs</Nav.Link>}
                        {userRole === Roles.SUPERADMIN && <Nav.Link href="/companies">Gestion des sociétés</Nav.Link>}
                        {userRole === Roles.ADMIN && <Nav.Link href="/stats">Statistiques</Nav.Link>}
                        {userRole !== Roles.SALESMAN && userRole !== Roles.SUPERADMIN && <Nav.Link href="/settings">Configuration</Nav.Link>}
                    </Nav>
                    {username !== "" && <Button variant={"primary"} className={"mx-2"}><FontAwesomeIcon icon={faUser}/> {username}</Button>}
                    {<Button variant={"secondary"} onClick={logout}><FontAwesomeIcon icon={faSignOutAlt}/> Déconnexion</Button>}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}