import axios from 'axios';
import { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { NotificationManager } from 'react-notifications';

import 'react-notifications/lib/notifications.css';

interface LocationAutosuggestProps {
    defaultValue: string,
    type: "housenumber" | "municipality",
    isMarkerAdd: boolean,
    setFullAddress: any,
    setState: any
}

function LocationAutosuggest({defaultValue, type, isMarkerAdd, setFullAddress, setState}:LocationAutosuggestProps) {
    const [value, setValue] = useState(defaultValue);
    const [suggestions, setSuggestions] = useState([]);

    const getDefaultValue = () => {
        setValue(defaultValue)
    }

    const getSuggestionValue = (suggestion: any) => {
        let context = suggestion.properties.context.split(",");
        let numDept = context[0].trim();
        let dept = context[1].trim();
        let region = context[2] !== undefined ? context[2].trim() : context[1].trim();
        if (type === "housenumber") {
            setFullAddress(type, suggestion.properties.name, suggestion.properties.city, suggestion.properties.postcode, numDept, dept, region);
        } else {
            setFullAddress(type, "", suggestion.properties.city, suggestion.properties.postcode, numDept, dept, region);
        }
        return suggestion.properties.name
    };

    const renderSuggestion = (suggestion: any) => (
        <div>
            {suggestion.properties.label}
        </div>
    );

    const getSuggestions = (value: string, data: any) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : data.filter((item:any) =>
            item.properties.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    const getData = (value: string) => {
        setTimeout(() => {
          if (value.length > 3) {
            axios
              .get(
                "https://api-adresse.data.gouv.fr/search/?q=" +
                  value +
                  "&type=" +
                  type +
                  "&autocomplete=1",
                { headers: null }
              )
              .then((response) => {
                let result = response.data.features;
                setSuggestions(result);
              })
              .catch(() =>
                NotificationManager.info(
                  "Le service d'autocomplétion d'adresse est indisponible ou surchargé."
                )
              );
          } else {
            setSuggestions([]);
          }
        }, 1000);
    }

    const onChange = (event: any, { newValue }: {newValue: string}) => {
        setValue(newValue.toUpperCase());
        getData(newValue.toUpperCase());
        if (isMarkerAdd) setState(newValue.toUpperCase());
    };

    const onBlur = (event: any) => {
        setValue(event.target.value.toUpperCase());
        setState(event.target.value.toUpperCase());
        getData(event.target.value.toUpperCase());
    };

    const onSuggestionsFetchRequested = ({ value }: {value:string}) => {
        setSuggestions(getSuggestions(value, suggestions));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const inputProps = {
        placeholder: '',
        value,
        onChange,
        onBlur
    };

    useEffect(() => {
        isMarkerAdd && getDefaultValue();
    });

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    )
}

export default LocationAutosuggest;