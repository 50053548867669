import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Bar, Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
import Util from '../utils/Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import html2canvas from 'html2canvas';
import { interpolateRainbow } from 'd3-scale-chromatic';
interface ChartProps {
    chartType: any,
    setChartType: any,
    data: Array<number>,
    dataLabels: Array<string>,
    chartTitle: string,
    filterTitle: string,
    filtersSelection: any
}

function ChartView({chartType, setChartType, data, dataLabels, chartTitle, filterTitle, filtersSelection}: ChartProps) {

    const exportRef = useRef<any>();

    const colorRangeInfo = {colorStart: 0,colorEnd: 1,useEndAsStart: false};
    const colorScale = interpolateRainbow;

    const colors = Util.interpolateColors(data.length, colorScale, colorRangeInfo);
    
    const chartDataDoughnut = {
        labels: dataLabels,
        datasets: [
            {
                data: data,
                borderColor: ['white'],
                backgroundColor: colors,
                pointBackgroundColor: 'rgba(255,206,86,0.2)',
            }
            
        ]
    }

    const getBarData = () => {
        let result = []
        for (let i = 0; i < dataLabels.length; i++) {
            result.push({label: dataLabels[i], data: [data[i]], backgroundColor: colors[i]})
        }
        return result;
    }

    const chartDataBar = {
        labels: ["Données"],
        datasets: getBarData()
    }

    const chartOption = {
        maintainAspectRatio: false,
        responsive:true,
        plugins: {
            title: {
                display: true,
                text: chartTitle,
                color:'dark-grey',
                font: {
                    size:30
                },
                padding:{
                    top:30,
                    bottom:30
                },
                responsive:true,
                animation:{
                    animateScale: true,
                }
            },
            datalabels: {
                display: true,
                color: 'light-grey'
            }
        }
    }

    const downloadImage = (blob:any, fileName:string) => {
        const fakeLink:any = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;
        
        fakeLink.href = blob;
        
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        
        fakeLink.remove();
    };

    const exportAsImage = async (element:any, imageFileName:string) => {
        const html:any = document.getElementsByTagName("html")[0];
        const body:any = document.getElementsByTagName("body")[0];
        let htmlWidth = html.clientWidth;
        let bodyWidth = body.clientWidth;
        const newWidth = element.scrollWidth - element.clientWidth;
        if (newWidth > element.clientWidth) {
            htmlWidth += newWidth;
            bodyWidth += newWidth;
        }
        html.style.width = htmlWidth + "px";
        body.style.width = bodyWidth + "px";
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
        html.style.width = null;
        body.style.width = null;
    };


    return (
        <Card className="mt-3 mb-2" ref={exportRef}>
            <Card.Body>
                <Row xs="auto">
                    <Col xs="auto" md="auto" className="mx-auto my-auto">
                        {chartType === "doughnut" && <Doughnut id={"doughnut"} data={chartDataDoughnut} plugins={[ChartDataLabels]} options={chartOption} height="600px" width="600px" />}
                        {chartType === "bar" && <Bar id={"bar"} data={chartDataBar} plugins={[ChartDataLabels]} options={chartOption} height="600px" width="600px" />}
                    </Col>
                    <Col xs="auto" md="auto" className="mx-auto my-auto">
                        <Row xs="auto" className="mb-5">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Type de diagramme</Card.Title>
                                    <Form.Group>
                                        <Form.Check type="radio" label="Circulaire" value="doughnut" onChange={(e) => setChartType(e.target.value)} checked={chartType === "doughnut"} />
                                        <Form.Check type="radio" label="Barres" value="bar" onChange={(e) => setChartType(e.target.value)} checked={chartType === "bar"} />
                                    </Form.Group> 
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row xs="auto" className="mb-5">
                            <Card>
                                <Card.Body>
                                    <Card.Title>{filterTitle}</Card.Title>
                                    {filtersSelection}
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row xs="auto" className="mb-5">
                            <Button variant="primary" onClick={() => exportAsImage(exportRef.current, chartTitle)}><FontAwesomeIcon icon={faDownload}/> Télécharger les statistiques</Button>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ChartView;