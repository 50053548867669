export default class DateUtil {

    static toDate(date:any) {
        date = new Date(date);
        let dd = date.getDate();
        let MM = date.getMonth() + 1;
        const yyyy = date.getFullYear();

        if (dd < 10) dd = `0${dd}`;
        if (MM < 10) MM = `0${MM}`;

        return `${dd}/${MM}/${yyyy}`;
    }

    static toyyyyMMdd(date:any) {
        if (!date) return "";

        let inputDate = new Date(date);

        let year:number = inputDate.getFullYear();
        let month:number = inputDate.getMonth() + 1;
        let day:number = inputDate.getDate();

        let yearStr:string = year.toString();
        let monthStr:string = month.toString();
        let dayStr:string = day.toString();

        if (month < 10) monthStr = "0" + month;
        if (day < 10) dayStr = "0" + day;

        let formattedDate = `${yearStr}-${monthStr}-${dayStr}`;

        return formattedDate;
    }

    static toddMMyyyy(date:any) {
        if (!date) return "";

        let inputDate = new Date(date);

        let year:number = inputDate.getFullYear();
        let month:number = inputDate.getMonth() + 1;
        let day:number = inputDate.getDate();

        let yearStr:string = year.toString();
        let monthStr:string = month.toString();
        let dayStr:string = day.toString();

        if (month < 10) monthStr = "0" + month;
        if (day < 10) dayStr = "0" + day;

        let formattedDate = `${dayStr}/${monthStr}/${yearStr}`;

        return formattedDate;
    }

    static getTime(date:any) {
        if (!date) return "";

        let inputDate = new Date(date);

        let hour:number = inputDate.getHours();
        let minutes:number = inputDate.getMinutes();

        let hourStr:string = hour.toString();
        let minutesStr:string = minutes.toString();
        
        if (hour < 10) hourStr = "0" + hour;
        if (minutes < 10) minutesStr = "0" + minutes;

        let formattedDate = `${hourStr}h${minutesStr}`;

        return formattedDate;
    }
}