import axios from 'axios';
import { useEffect, useState } from 'react'
import { Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faLock, faPenAlt, faSync, faTrashAlt, faUnlock, faUserFriends } from '@fortawesome/free-solid-svg-icons';

import CompanyModal from './CompanyModal';
import EventModal from '../../sub/EventModal';
import TableFiltered from '../../sub/TableFiltered';

import 'react-notifications/lib/notifications.css';
import AnimatedPicture from '../../sub/AnimatedPicture';
import Company from "../../interfaces/Company";
import Department from "../../interfaces/Department";
import CompanyUsersModal from "./CompanyUsersModal";

interface CompanyManagementProps {
    userId: string
}

export default function CompanyManagement({userId}: CompanyManagementProps) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [depts, setDepts] = useState<Array<Department>>([]);
    const [companies, setCompanies] = useState<Array<Company>>([]);
    const [companyId, setCompanyId] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showUsersModal, setShowUsersModal] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [confModal, setConfModal] = useState<JSX.Element | null>(null);

    useEffect(() => {
        axios.all([
            axios.get("/company/all"),
            axios.get("/department/all"),
        ]).then(axios.spread((companiesResponse, deptsResponse) => {
            setCompanies(companiesResponse.data);
            setDepts(deptsResponse.data);
            setIsLoading(false);
        }))
    }, [])

    const handleEdit = (userId:string) => {
        setCompanyId(userId);
        setIsUpdate(true);
        setShowModal(true);
    }

    const handleCanLogin = (companyId:string, userCanLogin:boolean) => {
        let updatedData = {
            crudItemId: companyId,
            updatedField: "canLogin",
            updatedValue: !userCanLogin
        }
        axios.post("/company/update", updatedData).then((response) => {
            let allCompanies:Array<Company> = [...companies];
            let index = allCompanies.findIndex((item:Company) => item._id === companyId);
            allCompanies[index].canLogin = response.data.canLogin;
            setCompanies(allCompanies);
            NotificationManager.info("Cette société est "+ (response.data.canLogin ? "débloqué" : "bloqué" ));
        });
    }

    const handleResetRgpd = (companyId:string) => {
        axios.post("/rgpd/reset/company", {companyId: companyId}).then(() => {
            NotificationManager.success("Le RGPD a été réinitialisé pour cette société");
        });
    }

    const deleteCompany = (companyId:string) => {
        axios.post("/company/delete", {crudItemId: companyId}).then(() => {
            const newCompanies = companies.filter((company:Company) => company._id !== companyId);
            setCompanies(newCompanies);
            setConfModal(null);
            NotificationManager.success("La société a été supprimé.");
        })
    }

    const openConfModal = (id:string) => {
        let title = "Attention";
        let content = "Êtes-vous sûr de vouloir supprimer cette société ?";
        let modal = <EventModal
                        title={title}
                        content={content}
                        context={"danger"}
                        close = {setConfModal}
                        confirm={() => deleteCompany(id)}
                    />
        setConfModal(modal);
    }

    const openUsersModal = (id:string) => {
        setCompanyId(id);
        setShowUsersModal(true);
    }

    const datasetFormalyze = (dataset:string) => {
        let result = "";
        switch(dataset) {
            case "all":
                result = "France entière";
                break;
            case "regions":
                result = "Région(s)";
                break;
            case "depts":
                result = "Département(s)";
                break;
            default:
                break;
        }
        return result;
    } 

    const datasetOptions = [
        {value: "France entière", label: "France entière"},
        {value: "Région(s)", label: "Région(s)"},
        {value: "Département(s)", label: "Département(s)"}
    ];

    const getTableData = () => {
        let data:Array<Object> = [];
        companies.map((company:Company) => {
            data.push({
                id: {data: company._id},
                nom: {data: company.name, type: "input"},
                plage_de_données : {data: datasetFormalyze(company.dataset), type: "select", options: datasetOptions},
                canLogin: {data: company.canLogin, type: "input"}
            });
            return null;
        })
        return data;
    }

    const getTableAction = (ItemId:string, canLogin:boolean) => {
        return (
            <>
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip"+ItemId}>Utilisateurs de la société</Tooltip>}>
                    <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => openUsersModal(ItemId)}><FontAwesomeIcon icon={faUserFriends} /></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip"+ItemId}>Réinitialiser le RGPD</Tooltip>}>
                    <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => handleResetRgpd(ItemId)}><FontAwesomeIcon icon={faSync} /></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip"+ItemId}>La société est {canLogin ? "active" : "bloquée"}</Tooltip>}>
                    <Button variant={canLogin ? "success" : "danger"} size={"sm"} className={"mx-2"} onClick={() => handleCanLogin(ItemId, canLogin)}><FontAwesomeIcon icon={canLogin ? faUnlock : faLock} /></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip"+ItemId}>Modifier la société</Tooltip>}>
                    <Button variant={"secondary"} size={"sm"} className={"mx-2"} onClick={() => handleEdit(ItemId)}><FontAwesomeIcon icon={faPenAlt} /></Button> 
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip"+ItemId}>Supprimer la société</Tooltip>}>
                    <Button variant={"danger"} size={"sm"} onClick={() => openConfModal(ItemId)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                </OverlayTrigger>
            </>
        )
    }

    return (
        <>
        {isLoading && 
            <Container>
                <AnimatedPicture />
                <h1>Chargement en cours...</h1>
            </Container>
        }
        
        {!isLoading && 
            <Container className={"mt-5"}>
                <Button className={"m-5"} onClick={() => setShowModal(true)}><FontAwesomeIcon icon={faBuilding}/> Ajouter une société</Button>
                <TableFiltered
                    data={getTableData()}
                    setData={null}
                    hasActions={true}
                    actionsComponent={(itemId:string, canLogin:boolean) => getTableAction(itemId, canLogin)}
                    hasFilters={true}
                />
                {showModal &&
                    <CompanyModal 
                        companyId={companyId}
                        companies={companies}
                        setCompanies={setCompanies}
                        setShowModal={setShowModal}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                        departments={depts}
                    />
                }
                {showUsersModal &&
                    <CompanyUsersModal 
                        userId={userId}
                        companyId={companyId}
                        setShowModal={setShowUsersModal}
                    />
                }
                {confModal}
            </Container>
        }
        </>
    )
}