import { faBuilding, faPenAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import EventModal from './EventModal';
import TableFiltered from './TableFiltered';
import ArrayUtils from '../utils/ArrayUtils';
import CrudModal from './CrudModal';

import 'react-notifications/lib/notifications.css';
import AnimatedPicture from './AnimatedPicture';
import Util from "../utils/Util";

function Crud({apiRouterPathRoot, elementLabel, isSalesmanCrud=false, isGroupmentCrud=false, isDepartmentCrud=false}:{apiRouterPathRoot:string, elementLabel:string, isSalesmanCrud?:boolean, isGroupmentCrud?:boolean, isDepartmentCrud?:boolean}) {
    const [crudItems, setCrudItems] = useState([]);
    const [crudItemId, setCrudItemId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<any>(null);
    const [confModal, setConfModal] = useState<any>(null);
    const [salesmen, setSalesmen] = useState([]);

    useEffect(() => {
        axios.get(apiRouterPathRoot + "/all").then(response => {
            let crudItems = response.data;
            if (isDepartmentCrud) {
                setCrudItems(crudItems.sort((a:any,b:any) => ArrayUtils.ascendingSort(a.num,b.num)));
                axios.get("/salesman/all").then((salesmanResponse) => {
                    setSalesmen(salesmanResponse.data);
                    setIsLoading(false);
                })
            } else {
                setCrudItems(crudItems.sort((a:any,b:any) => ArrayUtils.ascendingSort(a.name,b.name)));
                setIsLoading(false);
            }
        })
    }, [apiRouterPathRoot, isDepartmentCrud])

    const getCount = async (crudItemId:string) => {
        let response = await axios.post(apiRouterPathRoot + "/count", {crudItemId: crudItemId});
        return response.data;
    }

    const handleEdit = (userId:string) => {
        setCrudItemId(userId);
        setIsUpdate(true);
        setShowModal(true);
    }

    const deleteItem = async (crudItemId:string) => {
        let count = await getCount(crudItemId)
        if (count === 0) {
            axios.post(apiRouterPathRoot + "/delete", {crudItemId: crudItemId}).then(() => {
                const newcrudItems = crudItems.filter((item:any) => item._id !== crudItemId);
                if (isDepartmentCrud) {
                    setCrudItems(newcrudItems.sort((a:any,b:any) => ArrayUtils.ascendingSort(a.num,b.num)));    
                } else {
                    setCrudItems(newcrudItems.sort((a:any,b:any) => ArrayUtils.ascendingSort(a.name,b.name)));
                }
                setConfModal(null);
                NotificationManager.success("L'élément a été supprimé.");
            })
        } else {
            NotificationManager.error("Impossible de supprimer cet élément.");
            setError(<Alert variant="danger" onClose={() => setError(null)} dismissible>Impossible de supprimer cet élément : {count} établissements associés</Alert>)
        }
    }

    const openConfModal = (id:string) => {
        let title = "Attention";
        let content = "Êtes-vous sûr de vouloir supprimer cet élément ?";
        let modal = <EventModal
                        title={title}
                        content={content}
                        context={"danger"}
                        close = {(value:any) => setConfModal(value)}
                        confirm={() => deleteItem(id)}
                    />
        setConfModal(modal);
    }

    const getTableData = () => {
        let data:Array<Object> = [];
        crudItems.map((item:any) => {
            switch(true) {
                case isSalesmanCrud:
                    data.push({id: {data: item._id}, commercial: {data: item.first_name +" "+ item.name.toUpperCase(), type: "input"}, email: {data: item.email, type: "input"}, email_assistante: {data: item.assistantEmail, type: "input"}});
                    break;
                case isGroupmentCrud:
                    data.push({id: {data: item._id}, groupement: {data: item.name, type: "input"}, type: {data: item.type, type: "input"}});
                    break;
                case isDepartmentCrud:
                    let salesman:string = Util.getSalesmanName(salesmen, item.salesman);
                    data.push({id: {data: item._id}, numéro: {data: item.num, type: "input"}, département: {data: item.name, type: "input"}, région: {data: item.region, type: "input"}, commercial: {data: salesman, type: "input"}, priorité_cciale: {data: item.priorite_cciale, type: "input"}});
                    break;
                default:
                    data.push({id: {data: item._id}, [elementLabel]: {data: item.name, type: "input"}});
                    break;
            }
            return null;
        })
        return data;
    }

    const getTableAction = (ItemId:any) => {
        return <>
                <Button variant={"secondary"} size={"sm"} className={"mx-2"} onClick={() => handleEdit(ItemId)}><FontAwesomeIcon icon={faPenAlt} /></Button> 
                {!isDepartmentCrud && <Button variant={"danger"} size={"sm"} onClick={() => openConfModal(ItemId)}><FontAwesomeIcon icon={faTrashAlt} /></Button>}
            </>
    }

    // use it to add departments for the first time
    const addDepts = () => {
        // API GOUV: https://api.gouv.fr/les-api/api-geo
        axios.get("https://geo.api.gouv.fr/departements").then((responseDept) => {
            for (let i = 0; i < responseDept.data.length; i++) {
                axios.get("https://geo.api.gouv.fr/regions/"+responseDept.data[i].codeRegion).then((responseRegion) => {
                    let newCrudItem:any = {num: responseDept.data[i].code, name: responseDept.data[i].nom, region: responseRegion.data.nom}
                    axios.post(apiRouterPathRoot + "/create", newCrudItem);
                })
            }
            setTimeout(() => window.location.reload(), 2000)
        })
    }

    return (
        <>
            {isLoading && 
                <Container>
                    <AnimatedPicture />
                    <h1>Chargement en cours...</h1>
                </Container>
            }

            {!isLoading && 
                <Container className={"mt-5"}>
                    {!isDepartmentCrud && <Button className={"m-5"} onClick={() => setShowModal(true)}><FontAwesomeIcon icon={faBuilding}/> Ajouter un {elementLabel}</Button>}
                    {isDepartmentCrud && crudItems.length === 0 && <Button className={"m-5"} onClick={() => addDepts()}><FontAwesomeIcon icon={faBuilding}/> Ajouter les départements</Button>}
                    {error}
                    <TableFiltered 
                        data={getTableData()}
                        setData={null}
                        hasActions={true}
                        actionsComponent={((ItemId:any) => getTableAction(ItemId))}
                        hasFilters={true}
                    />
                    {showModal &&
                    <CrudModal
                        apiRouterPathRoot={apiRouterPathRoot}
                        crudItemId={crudItemId}
                        crudItems={crudItems}
                        setCrudItems={setCrudItems}
                        setShowModal={setShowModal}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                        elementLabel={elementLabel}
                        isSalesmanCrud={isSalesmanCrud}
                        isGroupmentCrud={isGroupmentCrud}
                        isDepartmentCrud={isDepartmentCrud} 
                        salesmen={salesmen}
                    />
                    }
                    {confModal}
                </Container>
            }
        </>
    )
}

export default Crud
