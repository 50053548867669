import { faPenAlt, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react'
import { Container, Row, Button, Form, Col, FormControl, Alert } from 'react-bootstrap';
import { Attendee, Event } from 'microsoft-graph';
import { createEvent } from '../../../GraphService';
import Pagination from 'react-paginate';
import { Roles } from '../../../enums/Roles';
import EventModal from '../../../sub/EventModal';
import DateUtil from '../../../utils/DateUtil';
import ArrayUtils from '../../../utils/ArrayUtils';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAppContext } from '../../../AppContext';
import Comment from '../../../interfaces/Comment';
import Marker from '../../../interfaces/Marker';

interface MarkerModalCommentProps {
    userRole: number,
    username: string,
    updateMarker: (updatedField: string, comments: Array<Comment>) => void,
    marker: Marker
}

function MarkerModalComment({userRole, username, updateMarker, marker}:MarkerModalCommentProps) {
    const [isCommentAdd, setIsCommentAdd] = useState(false);
    const [commentToAdd, setCommentToAdd] = useState("");
    const [errorCommentToAdd, setErrorCommentToAdd] = useState("");
    const [indexToUpdate, setIndexToUpdate] = useState(-1);
    const [selectedPage, setSelectedPage] = useState(1);
    const [confModal, setConfModal] = useState<any>(null);
    const [isReminder, setIsReminder] = useState<boolean>(false);
    const [reminderStart, setReminderStart] = useState('');

    const MAX_ON_PAGE = 3;

    const app = useAppContext();

    const fullAddress = marker.adresse + " " + marker.cp + " " + marker.ville;

    const reminderSubject:string = "Rappel - ["+marker.nom+" ("+marker.ville+")]";
    const reminderBody:string = marker.nom +"\n"+ fullAddress +"\n"+ marker.contact +"\n"+ marker.telephone +"\n"+ marker.mail?.toLowerCase() +"\n\nVotre commentaire : "+ commentToAdd;

    const handleAddCommentButton = () => {
        setIsCommentAdd(false);
        let now = Date.now();
        let comments = marker.commentaires !== undefined ? [...marker.commentaires] : [];
        let newComment = {username: username, date: now, comment: commentToAdd};
        comments.push(newComment);
        updateMarker("commentaires", comments);

        if (isReminder) {
            const attendeeArray: Attendee[] = [{
                emailAddress: {
                  address: app.user!.email
                }
            }];
    
            const newEvent: Event = {
                subject: reminderSubject,
                // Only add if there are attendees
                attendees: attendeeArray,
                // Specify the user's time zone so
                // the start and end are set correctly
                start: {
                  dateTime: reminderStart,
                  timeZone: app.user?.timeZone
                },
                end: {
                  dateTime: reminderStart,
                  timeZone: app.user?.timeZone
                },
                // Only add if a body was given
                body: reminderBody.length > 0 ? {
                  contentType: 'text',
                  content: reminderBody
                } : undefined
            };
    
            try {
                createEvent(app.authProvider!, newEvent).then(() => {setIsReminder(false); setReminderStart("")})
            } catch (err) {
                app.displayError!('Error creating event', JSON.stringify(err));
            }
        }
    }

    const onChangeAddComment = (value:string) => {
        if (value.trim().length > 0) {
            setErrorCommentToAdd("");
            setCommentToAdd(value);
        } else {
            setErrorCommentToAdd("Le commentaire ne peut pas être vide !");
        }
    }

    const updateComment = (commentIndex:number, value:string) => {
        let comments:Array<Comment> = marker.commentaires !== undefined ? [...marker.commentaires] : [];
        comments[commentIndex].comment = value 
        updateMarker("commentaires", comments);
        setIndexToUpdate(-1)
    }

    const handleUpdateCommentButton = (commentIndex:number) => {
        setIndexToUpdate(commentIndex);
    }

    const handleDeleteCommentButton = (commentIndex:number) => {
        let comments:Array<any> = marker.commentaires !== undefined ? [...marker.commentaires] : [];
        comments.splice(commentIndex, 1);
        if (comments.length < 1) comments.push([{username: "", date: Date.now(), comment: ""}])
        updateMarker("commentaires", comments);
        setConfModal(null);
    }

    const handlePageClick = (event:any) => {
        const selectedPage = event.selected;
        setSelectedPage(selectedPage + 1);
    };

    const openConfModal = (id:number) => {
        let title = "Attention";
        let content = "Êtes-vous sûr de vouloir supprimer ce commentaire ?";
        let modal = <EventModal
            title={title}
            content={content}
            context={"danger"}
            close={(value:any) => setConfModal(value)}
            confirm={() => handleDeleteCommentButton(id)} 
        />
        setConfModal(modal);
    }

    const content = () => {
        let sortedComments = marker.commentaires?.sort((a:any, b:any) => ArrayUtils.descendingSort(new Date(a.date).getTime(),new Date(b.date).getTime()));
        const itemOfLastItem = selectedPage * MAX_ON_PAGE;
        const indexOfFirstItem = itemOfLastItem - MAX_ON_PAGE;
        let paginatedData = sortedComments?.slice(indexOfFirstItem , itemOfLastItem);
        return paginatedData?.map((comment:any,i:number) => {
            if (comment.comment !== "") {
                let author = comment.username !== "" ? comment.username : "Inconnu(e)";
                let date = DateUtil.toddMMyyyy(comment.date) + " - " + DateUtil.getTime(comment.date);
                return <Container key={"comment" + i} className={"mb-3"}>
                        <Row><span>Auteur: {author}  |  Date: {date}</span></Row>
                        <Row xs={"auto"}>
                            <Col xs lg="10">
                                {indexToUpdate !== i ? <Alert variant={"info"}>{comment.comment}</Alert>
                                : <FormControl as="textarea" style={{ height: '10em' }} defaultValue={comment.comment} onBlur={(e) => updateComment(i, e.target.value)} />
                                }
                            </Col>
                            { (username === comment.username || userRole === Roles.ADMIN) &&
                                <>
                                    <Col xs lg="1"><Button variant={"secondary"} onClick={() => handleUpdateCommentButton(i)}><FontAwesomeIcon icon={faPenAlt} /></Button></Col>
                                    <Col xs lg="1"><Button variant={"danger"} onClick={() => openConfModal(i)}><FontAwesomeIcon icon={faTrashAlt} /></Button></Col>
                                </>
                            }
                        </Row>
                    </Container>
            } else {
                return null;
            }
        })
    }

    return (
        <Container className="mt-3 mb-3">
            {!isCommentAdd && <Row xs="auto"><Button onClick={() => setIsCommentAdd(true)} className={"mt-1 mb-4"}><FontAwesomeIcon icon={faPlusCircle}/> Ajouter un commentaire</Button></Row>}
            {isCommentAdd && 
            <>
                <span>Nouveau commentaire</span>
                <Form.Group as={Row} key={"new_comment"} className="mb-3" controlId="formPlaintextEmail">
                    <Col sm="8">
                        <Form.Control as="textarea" style={{ height: '10em' }} type={"text"} name={"commentaire"} isInvalid={errorCommentToAdd !== ""} defaultValue={commentToAdd} onChange={(e) => onChangeAddComment(e.target.value)} />
                        {errorCommentToAdd !== "" && <Alert variant={"danger"}>{errorCommentToAdd}</Alert>}
                    </Col>
                </Form.Group>

                <Row xs="auto">
                    <Col xs="6" md="auto">
                        <Form.Check type={"checkbox"} className='mb-2' onClick={() => setIsReminder(!isReminder)}  label={"Me créer un rappel"}/>
                    </Col>

                    <Col xs="6" md="auto">
                        {isReminder && <>
                            <UnauthenticatedTemplate>
                                <Button variant="primary mt-3 mb-3" onClick={app.signIn}>Se connecter à Office 365</Button>
                            </UnauthenticatedTemplate>

                            <AuthenticatedTemplate>
                                <Form.Control type="datetime-local"
                                    name="start"
                                    id="start"
                                    value={reminderStart}
                                    onChange={(e) => setReminderStart(e.target.value) } 
                                />
                            </AuthenticatedTemplate>
                        </>}
                    </Col>
                </Row>

                <Button variant="primary" className={"mb-5"} disabled={errorCommentToAdd !== "" || commentToAdd.trim().length === 0 || (isReminder && reminderStart === "")} onClick={handleAddCommentButton}><FontAwesomeIcon icon={faPlusCircle}/> Ajouter</Button>
            </>}

            {marker.commentaires !== undefined && (marker.commentaires.length > 1 || (marker.commentaires.length === 1 && marker.commentaires[0].comment !== "")) ? content() : <Alert variant={"info"}>Aucun commentaire pour le moment.</Alert>}
            {confModal}

            {marker.commentaires !== undefined && marker.commentaires.length > MAX_ON_PAGE && <Row xs="auto" className="justify-content-center">
                <Pagination
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(marker.commentaires.length / MAX_ON_PAGE)}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"} 
                    pageRangeDisplayed={MAX_ON_PAGE} 
                    marginPagesDisplayed={MAX_ON_PAGE}
                />
            </Row>}
        </Container>
    )
}

export default MarkerModalComment
