import { faPlusCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Container, Button, Alert, Row } from 'react-bootstrap';
// import EventModal from '../../../sub/EventModal';
import TableFiltered from '../../../sub/TableFiltered';
import ArrayUtils from '../../../utils/ArrayUtils';
import DateUtil from '../../../utils/DateUtil';
import MarkerModalEventAdd from './MarkerModalEventAdd';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Marker from "../../../interfaces/Marker";
import Salesman from "../../../interfaces/Salesman";
import MarkerModalEventImport from "./MarkerModalEventImport";
import { getEvent } from '../../../GraphService';
import { useAppContext } from '../../../AppContext';
import { findIana } from 'windows-iana';
// import { NotificationManager } from 'react-notifications';

import 'react-notifications/lib/notifications.css';

interface MarkerModalEventProps {
    userRole: number,
    marker: Marker,
    updateMarker: (updatedField: string, updatedValue: any) => void,
    salesmen: Array<Salesman>
}

export default function MarkerModalEvent({userRole, marker, updateMarker, salesmen}: MarkerModalEventProps) {
    const app = useAppContext();

    const [isSync, setIsSync] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    // const [confModal, setConfModal] = useState(false);

    const getTableData = () => {
        let data:Array<Object> = [];
        let rdv:Array<Object> = [];
        if (marker.rdv !== undefined && marker.rdv.length > 0) rdv = [...marker.rdv];
        let sortedRdv = rdv.sort((a:any, b:any) => ArrayUtils.descendingSort(new Date(a.startDate).getTime(),new Date(b.startDate).getTime()));
        sortedRdv.map((rdv:any) => {
            data.push({
                id: {data: rdv.id},
                organisateur: {data: rdv.author, type: "input"},
                sujet: {data: rdv.subject, type: "input"},
                debut:{data: DateUtil.toDate(rdv.startDate) + " (" + DateUtil.getTime(rdv.startDate) + ")", type: "input"},
                fin: {data: DateUtil.toDate(rdv.endDate) + " (" + DateUtil.getTime(rdv.endDate)+ ")", type: "input"}
            });
            return null;
        })
        return data;
    }

    const syncAllEvents = async () => {
        if (marker.rdv === undefined) return;
        const ianaTimeZones = findIana(app.user?.timeZone!);
        let events:Array<Object> = [];
        for (let i = 0; i < marker.rdv.length; i++) {
            try {
                const event = await getEvent(app.authProvider!, marker.rdv[i].id, ianaTimeZones[0].valueOf());
                let newEvent = {};
                newEvent = {
                    id: event.id,
                    author: event.organizer!.emailAddress!.name,
                    attendees: event.attendees,
                    startDate: event.start!.dateTime,
                    endDate: event.end!.dateTime,
                    subject: event.subject,
                    body: marker.rdv[i].body,
                    report: marker.rdv[i].report,
                    status: marker.rdv[i].status
                };
                events.push(newEvent);
            } catch (err: any) {
                let newEvent = {};
                newEvent = {
                    id: marker.rdv[i].id,
                    author: marker.rdv[i].author,
                    attendees: marker.rdv[i].attendees,
                    startDate: marker.rdv[i].startDate,
                    endDate: marker.rdv[i].endDate,
                    subject: marker.rdv[i].subject,
                    body: marker.rdv[i].body,
                    report: marker.rdv[i].report,
                    status: marker.rdv[i].status
                };
                events.push(newEvent);
                app.displayError!(err.message);
            }
        }
        updateMarker("rdv", events)
        // NotificationManager.success("Les RDV ont été synchronisés.");
    }

    useEffect(() => {
        if (!isSync && app.user !== undefined && marker.rdv !== undefined && marker.rdv.length > 0) {
            syncAllEvents();
            setIsSync(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSync, app, marker])

    return (
        <Container>
            <AuthenticatedTemplate>
                <Row xs="auto">
                    <Button className={"mt-3 mb-4 me-3"} onClick={() => setShowAddModal(true)}><FontAwesomeIcon icon={faPlusCircle}/> Ajouter un RDV</Button>
                    <Button className={"mt-3 mb-4 me-3"} onClick={() => setShowImportModal(true)}><FontAwesomeIcon icon={faUpload}/> Importer un RDV</Button>
                    {/* {marker.rdv !== undefined && marker.rdv.length > 0 && 
                        <Button className={"mt-3 mb-4"} onClick={() => syncAllEvents()}><FontAwesomeIcon icon={faSync}/> Synchroniser les RDV</Button>
                    } */}
                </Row>
            </AuthenticatedTemplate>

            {marker.rdv !== undefined && marker.rdv.length > 0 ?
            <TableFiltered
                data={getTableData()}
                setData={null}
                hasActions={false}
                maxOnPage={3}
            />
            : <Alert variant={"info"} className="mt-3">Aucun RDV pour le moment.</Alert>
            }

            {showAddModal &&
            <MarkerModalEventAdd
                marker={marker}
                setShowModal={setShowAddModal}
                updateMarker={updateMarker}
                salesmen={salesmen}
            />
            }

            {showImportModal &&
            <MarkerModalEventImport
                marker={marker}
                setShowModal={setShowImportModal}
                updateMarker={updateMarker}
                salesmen={salesmen}
            />
            }
            {/* {confModal} */}
        </Container>
    )
}