import { useEffect, useState } from "react";
import ChartView from "../../../sub/ChartView";

interface StatsBySalesmanProps {
    data: Array<any>,
    labels: Array<string>,
    chartTitle:string, 
    filterField:string, 
    getCount: (salesmanId:string, filterField:string, filterValue:string) => number, 
    getFilterSelection: (data:Array<object>, filter:any, setfilter:any, allSelection:boolean) => JSX.Element
}

export default function StatsBySalesman({data, labels, chartTitle, filterField, getCount, getFilterSelection}: StatsBySalesmanProps) {
    const [chartData, setChartData] = useState<any>([]);
    const [selectedFilter, setSelectedFilter] = useState<string>(data[0]._id);
    const [selectedType, setSelectedType] = useState<string>("doughnut");

    const getSalesmanDataType = () => {
        let data = [];
        let count = 0;
        for (let i = 0; i < labels.length; i++) {
            count = getCount(selectedFilter, filterField, labels[i].toUpperCase())
            data.push(count);
        }
        setChartData(data);
    }

    const getSalesmenInfo = () => {
        let result = [];
        for (let i = 0; i < data.length; i++) {
            result.push({value: data[i]._id, label: data[i].first_name + " " + data[i].name})
        }
        return result;
    }

    useEffect(() => {
        getSalesmanDataType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedFilter])

    return (
        <ChartView
            chartType={selectedType}
            setChartType={setSelectedType}
            data={chartData}
            dataLabels={labels}
            chartTitle={chartTitle}
            filterTitle={"Commerciaux"}
            filtersSelection={getFilterSelection(getSalesmenInfo(), selectedFilter, setSelectedFilter, false)}
        />
    )
}