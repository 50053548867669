import { LatLngExpression, LocationEvent } from "leaflet"
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Marker, Tooltip, useMapEvents } from 'react-leaflet'
import MapUtil from '../utils/MapUtil'

export default function LocationMarker() {
    const [position, setPosition] = useState<LatLngExpression | null>(null)
    const map = useMapEvents({
        click() {
            map.locate()
        },
        locationfound(e: LocationEvent) {
            setPosition(e.latlng)
        },
    })
  
    return position === null ? null : (
        <Marker position={position} icon={MapUtil.setMarkerColor("USER")}>
            <Tooltip>
                <Card style={{ width: '20rem' }}>
                    <Card.Body>
                        <Card.Text>Votre position actuelle</Card.Text>
                    </Card.Body>
                </Card>
            </Tooltip>
        </Marker>
    )
}