import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'react-bootstrap';
import FileUtil from '../utils/FileUtil';

interface ExportButtonProps {
    APIUrl: string,
    fileName: string,
    fileExtension: "pdf" | "xlsx",
    data: Array<Object> | null
}

export default function ExportButton({APIUrl, fileName, fileExtension, data}:ExportButtonProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const handleExportButton = () => {
        setLoading(true);
        return FileUtil.dowloadFileHack(APIUrl, fileName, fileExtension, data, setLoading);
    }
    
    return (
        <>
        {loading ?
        <Button variant={"primary"} disabled onClick={handleExportButton}>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            /> Exportation en cours...</Button>
        :
        <Button variant={"primary"} onClick={handleExportButton}><FontAwesomeIcon icon={faFileDownload}/> Exporter les données</Button>
        }
        </>
    )
}