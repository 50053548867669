import { useEffect } from "react";
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useMap } from "react-leaflet";
import MapUtil from "../utils/MapUtil";
import Util from "../utils/Util";
import Marker from "../interfaces/Marker";
import Salesman from "../interfaces/Salesman";


interface MarkerClusterProps {
    markers: Array<Marker>,
    onMarkerClick: (markerId: string) => void,
    salesmen: Array<Salesman>
}

const markerClusters = L.markerClusterGroup({maxClusterRadius: 100});

export default function MarkerCluster({ markers, onMarkerClick, salesmen }: MarkerClusterProps) {
    const map = useMap();

    useEffect(() => {
        markerClusters.clearLayers();
        markers.forEach((marker:Marker) => {
            let latitude = (marker.latitude !== null && marker.latitude.toString() !== "") ? marker.latitude : 0;
            let longitude = (marker.longitude !== null && marker.longitude.toString() !== "") ? marker.longitude : 0;
            return L.marker([latitude, longitude], {icon: MapUtil.setMarkerColor(marker.type)})
                .on("click", () => onMarkerClick(marker._id))
                .bindTooltip("<div class='p-2'><h5>"+marker.nom+"</h5><h6>Commercial: "+Util.displayData("commercial", marker.departement_details.salesman, salesmen)+"</h6></div>")
                .addTo(markerClusters)
            }
        );

        map.addLayer(markerClusters);
    }, [markers, map, onMarkerClick, salesmen]);
    return null;
};