import { Container, Nav, Navbar } from "react-bootstrap";

interface FooterProps {
    lang: string
}

export default function Footer({lang}: FooterProps) {
    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="pt-1 pb-2">
            <Container>
                <Nav className="m-auto">
                    <Nav.Link href={"tou-" + lang + ".html"} target={"blank"}>Condition générales</Nav.Link>
                    <Nav.Link href={"release-notes-" + lang + ".html"} target={"blank"}>Version {process.env.REACT_APP_VERSION}</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}