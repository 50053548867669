import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import AnimatedPicture from "../../sub/AnimatedPicture";
import MapView from "./MapView";
import TableView from "./TableView";

import Groupment from "../../interfaces/Groupment";
import User from "../../interfaces/User";
import Marker from "../../interfaces/Marker";
import Salesman from "../../interfaces/Salesman";
import Label from "../../interfaces/Label";

interface MapProps {
    userId: string
}

export default function Map({userId}: MapProps) {
    axios.defaults.baseURL = process.env.REACT_APP_BE_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const [user, setUser] = useState<User>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedView, setSelectedView] = useState<string>("");

    const [data, setData] = useState<Array<Marker>>([]);
    const [groupments, setGroupments] = useState<Array<Groupment>>([]);
    const [salesmen, setSalesmen] = useState<Array<Salesman>>([]);
    const [labelsCategory, setLabelsCategory] = useState<Array<Label>>([]);
    const [labelsStatus, setLabelsStatus] = useState<Array<Label>>([]);
    const [users, setUsers] = useState<Array<User>>([]);

    useEffect(() => {
        let cancel = false;
        
        axios.all([
            axios.get("/markers/map"),
            axios.get("/groupment/all"),
            axios.get("/salesman/all"),
            axios.get("/user/all"),
            axios.get("/labelCategory/all"),
            axios.get("/labelStatus/all"),
            axios.post("/user", {userId: userId})
        ]).then(axios.spread((markersResponse, groupsResponse, salesmanResponse, usersResponse, labelCategoryResponse, labelStatusResponse, userResponse) => {
            if (cancel) return;
            setData(markersResponse.data);
            setGroupments(groupsResponse.data);
            setSalesmen(salesmanResponse.data);
            setUsers(usersResponse.data);
            setLabelsCategory(labelCategoryResponse.data);
            setLabelsStatus(labelStatusResponse.data);
            setUser(userResponse.data);
            setSelectedView(userResponse.data.selected_view);
            setIsLoading(false);
        }))

        return () => {
            cancel = true;
        }
    }, [userId])

    return (
        <>
        {isLoading && 
            <Container>
                <AnimatedPicture context={"map"} />
                <h1>Chargement en cours...</h1>
            </Container>
        }

        {!isLoading && user !== undefined && user._id !== undefined &&
            <Container fluid>
                {selectedView === "map" &&
                    <MapView
                        userRole={user.role}
                        username={user.username}
                        data={data}
                        setData={setData}
                        groupments={groupments}
                        salesmen={salesmen}
                        labelsCategory={labelsCategory}
                        labelsStatus={labelsStatus}
                        users={users}
                        userId={user._id} 
                        selectedView={selectedView} 
                        setSelectedView={setSelectedView}
                    />
                }

                {selectedView === "table" &&
                    <TableView
                        userRole={user.role}
                        username={user.username}
                        data={data}
                        setData={setData}
                        groupments={groupments}
                        salesmen={salesmen}
                        labelsCategory={labelsCategory}
                        labelsStatus={labelsStatus}
                        users={users}
                        userId={user._id} 
                        selectedView={selectedView} 
                        setSelectedView={setSelectedView}
                    />
                }
            </Container>
        }
        </>
    )
}