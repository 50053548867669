import { Container, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faTable } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

interface ViewSelectionProps {
    userId: string,
    selectedView: string,
    setSelectedView: React.Dispatch<React.SetStateAction<string>>
}

export default function ViewSelection({userId, selectedView, setSelectedView}: ViewSelectionProps) {

    const changeView = (value:string) => {
        let updatedData = {
            userId: userId,
            updatedField: "selected_view",
            updatedValue: value
        }

        axios.post("/user/update", updatedData).then(() => {
            setSelectedView(value);
        });
    }

    return (
        <Container fluid className="mt-3 mb-3">
            <Row xs="auto" className="justify-content-center">
                <ToggleButtonGroup id="buttongroup" name="buttongroup" defaultValue={selectedView} onChange={(value) => changeView(value)}>
                    <ToggleButton id="map" value={"map"} variant={selectedView === "map" ? "primary" : "secondary"}>
                        <FontAwesomeIcon icon={faMap}/> Vue carte
                    </ToggleButton>

                    <ToggleButton id="table" value={"table"} variant={selectedView === "table" ? "primary" : "secondary"}>
                        <FontAwesomeIcon icon={faTable}/> Vue tableau
                        </ToggleButton>
                </ToggleButtonGroup>
            </Row>
        </Container>
    )
}