import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AnimatedPicture from '../sub/AnimatedPicture';

export default function NotFound() {
    return (
        <Container>
            <h1 className={"mt-5"}>Erreur 404</h1>
            <AnimatedPicture context={"notFound"}/>
            <h3>Il semblerait que cette page soit introuvable...</h3>
            <br/>
            <Link to={"/map"}>Retourner à la carte</Link>
        </Container>
    )
}