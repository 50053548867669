import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal, Button, InputGroup, FormControl, Alert } from 'react-bootstrap';
import { Roles } from '../../enums/Roles';
import { NotificationManager } from 'react-notifications';

import 'react-notifications/lib/notifications.css';
import User from "../../interfaces/User";

interface UserModalProps {
    userId: string,
    users: Array<User>,
    setUsers: React.Dispatch<React.SetStateAction<Array<User>>>,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    isUpdate: boolean,
    setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

function UserModal({userId, users, setUsers, setShowModal, isUpdate, setIsUpdate}:UserModalProps) {

    const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [role, setRole] = useState<number>(Roles.SALESMAN);
    const [password, setPassword] = useState<string>("");
    const [usernameError, setUsernameError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string | JSX.Element>("");
    
    const regexName: RegExp = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    const regexEmail: RegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const regexPassword: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?-_.&])[A-Za-z\d@$!%*?-_.&]{8,}$/;

    const handleModalClose = () => {
        setShowModal(false);
        setIsUpdate(false);
    };

    const checkIsExist = async (field:string, value:string | number) => {
        let response = await axios.post("/user/check", {field: field, value: value});
        return response.data;
    }

    const checkData = () => {
        if (username.trim().length-1 < 0) setUsernameError("Le nom d'utilisateur ne peut pas être vide");
        if (email.trim().length-1 < 0) setEmailError("L'adresse e-mail ne peut pas être vide");
        if (password.trim().length-1 < 0) setPasswordError("Le mot de passe ne peut pas être vide");
    }

    const createUser = () => {
        let newUser:User = {
            username: username.trim(),
            email: email.trim(),
            role: role,
            password: password,
            canLogin: true
        }
        checkData();
        if (username.trim().length > 0 && email.trim().length > 0 && password.trim().length > 0) {
            axios.post("/user/create", newUser).then((response) => {
                let allUsers = [...users]
                newUser._id = response.data;
                allUsers.push(newUser);
                setUsers(allUsers);
                setShowModal(false);
                NotificationManager.success("Le compte utilisateur a été créé.");
            })
        } else {
            NotificationManager.error("Impossible de créer le compte utilisateur.");
        }
    }

    const updateUser = async (updatedField:string, updatedValue:string | number) => {
        let updatedData = {
            userId: userId,
            updatedField: updatedField,
            updatedValue: updatedValue
        }
        let isExist = false;
        if (updatedField === "email" || updatedField === "username") isExist = await checkIsExist(updatedField, updatedValue);
        if (!isExist) {
            axios.post("/user/update", updatedData).then((response) => {
                let allUsers:Array<any> = [...users];
                let index: number = allUsers.findIndex((item:User) => item._id === userId);
                allUsers[index][updatedField] = response.data[updatedField];
                setUsers(allUsers);
                NotificationManager.success("Les modifications ont été sauvegardées");
            });
        } else {
            if (updatedField === "username") setUsernameError("Ce nom d'utilisateur est déjà utilisé");
            if (updatedField === "email") setEmailError("Cette adresse e-mail est déjà utilisée");
            NotificationManager.error("Impossible de modifier le compte utilisateur.");
        }
    }

    useEffect(() => {
        if (isUpdate) {
            axios.post("/user/", {userId: userId}).then((response) => {
                let user = response.data;
                setUsername(user.username);
                setEmail(user.email);
                setRole(user.role);
            })
        }
    }, [isUpdate, userId])


    function isValid(updatedField:string, updatedValue:any) {
        let result = false;
        let errorName = "";
        let errorEMail = "";
        let errorPassword:string | JSX.Element = "";

        switch(updatedField) {
            case "username":
                if(updatedValue.length-1 < 0) {
                    errorName = "Le champs ne peut pas être vide !";
                } else if (!updatedValue.match(regexName)) {
                    errorName = "le champs ne peut contenir que des lettres !";
                } else {
                    result = true;
                }
                setUsername(updatedValue)
                setUsernameError(errorName)
                break;
            case "email":
                if(updatedValue.length-1 < 0) {
                    errorEMail = "Le champs ne peut pas être vide !";
                } else if (!updatedValue.match(regexEmail)) {
                    errorEMail = "Adresse e-mail non valide !";
                } else {
                    result = true;
                }
                setEmail(updatedValue)
                setEmailError(errorEMail);
                break;
            case "password":
                if(updatedValue.length-1 < 0) {
                    errorPassword = "Le champs ne peut pas être vide !";
                } else if (!updatedValue.match(regexPassword)) {
                    errorPassword = <ul>
                        <li>minimum 8 caractères</li>
                        <li>minimum 1 lettre minuscule</li>
                        <li>minimum 1 lettre majuscule</li>
                        <li>minimum 1 chiffre</li>
                        <li>minimum 1 caractère spécial</li>
                    </ul>;
                } else {
                    result = true;
                }
                setPassword(updatedValue)
                setPasswordError(errorPassword);
                break;
            case "role":
                result = true;
                setRole(updatedValue);
                break;
            default:
        }
        return result;
    }

    const handleUpdate = async (updatedField:string, updatedValue:string | number) => {
        if (isValid(updatedField, updatedValue)) {
            if (isUpdate) {
                updateUser(updatedField, updatedValue);
            } else {
                if (updatedField === "username" || updatedField === "email") {
                    let isExist = await checkIsExist(updatedField, updatedValue);
                    if (isExist) {
                        if (updatedField === "username") setUsernameError("Ce nom d'utilisateur est déjà utilisé");
                        if (updatedField === "email") setEmailError("Cette adresse e-mail est déjà utilisée");
                    }
                }
            }
        }
    }

    return (
        <Modal
            show={true}
            onHide={handleModalClose}
            backdrop={"static"}
            keyboard={false}
            size={undefined}
        >
            <Modal.Header closeButton>
                <Modal.Title>{isUpdate ? username : "Nouvel utilisateur"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <InputGroup.Text id={"role"}>Fonction</InputGroup.Text>
                    <FormControl 
                        value={role} as="select"
                        onChange={(e) => handleUpdate("role", e.target.value)}
                    >
                        <option value={Roles.SALESMAN}>Commercial</option>
                        <option value={Roles.ASSISTANT}>Assistante Commercial</option>
                        <option value={Roles.ADMIN}>Administrateur</option>
                    </FormControl>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Text id={"username"}>Nom d'utilisateur</InputGroup.Text>
                    <FormControl 
                        defaultValue={username} type={"text"} isInvalid={usernameError !== ""} 
                        onBlur={(e) => handleUpdate("username", e.target.value)} 
                    />
                </InputGroup>
                {usernameError !== "" && <Alert variant={"danger"} onClose={() => setUsernameError("")} dismissible><h6>{usernameError}</h6></Alert>}

                <InputGroup className="mb-3">
                    <InputGroup.Text id={"email"}>Adresse e-mail</InputGroup.Text>
                    <FormControl 
                        defaultValue={email} type={"email"} isInvalid={emailError !== ""}
                        onBlur={(e) => handleUpdate("email", e.target.value)} 
                    />
                </InputGroup>
                {emailError !== "" && <Alert variant={"danger"} onClose={() => setEmailError("")} dismissible><h6>{emailError}</h6></Alert>}

                {!isChangingPassword && isUpdate && <Button variant={"primary"} onClick={() => setIsChangingPassword(true)}>Changer le mot de passe</Button>}
                {(!isUpdate || isChangingPassword) && 
                    <>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id={"password"}>Mot de passe</InputGroup.Text>
                            <FormControl 
                                defaultValue={password} type={"password"} isInvalid={passwordError !== ""} 
                                onBlur={(e) => handleUpdate("password", e.target.value)} 
                            />
                        </InputGroup>
                        {passwordError !== "" && <Alert variant={"danger"} onClose={() => setPasswordError("")} dismissible><h6>{passwordError}</h6></Alert>}
                    </>
                }
            </Modal.Body>
            {!isUpdate && <Modal.Footer>
                <Button variant="primary" onClick={createUser} disabled={usernameError !== "" || emailError !== "" || passwordError !== ""}>Terminer</Button>
            </Modal.Footer>}
        </Modal>
    )
}

export default UserModal;