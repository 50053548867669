import { faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import { Roles } from '../../../enums/Roles';
// import EventModal from '../../../sub/EventModal';
import TableFiltered from '../../../sub/TableFiltered';
import ArrayUtils from '../../../utils/ArrayUtils';
import DateUtil from '../../../utils/DateUtil';
import MarkerModalReportAdd from './MarkerModalReportAdd';
import Rdv from '../../../interfaces/Rdv';

interface MarkerModalReportProps {
    userRole: number,
    username: string,
    marker: any,
    updateMarker: (updatedField: string, updatedValue: any) => void,
}

export default function MarkerModalReport({userRole, username, marker, updateMarker}: MarkerModalReportProps) {
    const [showModal, setShowModal] = useState<boolean>(false);
    // const [confModal, setConfModal] = useState(false);

    const [isReportAdd, setIsReportAdd] = useState<boolean>(true);
    const [rdv, setRdv] = useState<Rdv>();

    const getTableData = () => {
        let data:Array<Object> = [];
        let rdv:Array<Object> = [];
        if (marker.rdv !== undefined && marker.rdv.length > 0) rdv = [...marker.rdv];
        let sortedRdv = rdv.sort((a:any, b:any) => ArrayUtils.descendingSort(new Date(a.startDate).getTime(),new Date(b.startDate).getTime()));
        sortedRdv.map((rdv:any) => {
            if (rdv.report === undefined) return null;
            data.push({
                id: {data: rdv.report.id},
                auteur: {data: rdv.report.author, type: "input"},
                RDV: {data: rdv.subject +" - "+ DateUtil.toDate(rdv.startDate), type: "input"},
                créé_le:{data: DateUtil.toDate(rdv.report.createdAt) +"-"+ DateUtil.getTime(rdv.report.createdAt), type: "input"},
            });
            return null;
        })
        return data;
    }

    const getTableAction = (itemId:string) => {
        return <>
                <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => handleModalShow(getRdvByReportId(itemId))}><FontAwesomeIcon icon={faEye} /></Button> 
            </>
    }

    const handleModalShow = (rdv: any) => {
        setRdv(rdv);
        setIsReportAdd(false);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setRdv(undefined);
        setIsReportAdd(true);
        setShowModal(false);
    }

    const getRdvByReportId = (reportId: string) => {
        if (marker.rdv === undefined) return {};
        return marker.rdv.find((item: Rdv) => item.report && item.report.id === reportId);
    }

    const isReportExist = () => {
        if (marker.rdv === undefined || marker.rdv.length === 0) return false;

        for (let i = 0; i < marker.rdv.length; i++) {
            if (marker.rdv[i].report !== undefined) return true;
        }
    }

    return (
        <Container>
            {userRole !== Roles.ASSISTANT && 
                <Button className={"mt-3"} onClick={() => setShowModal(true)}><FontAwesomeIcon icon={faPlusCircle}/> Ajouter un compte-rendu</Button>
            }

            {isReportExist() ?
            <TableFiltered
                data={getTableData()}
                setData={null}
                hasActions={true}
                actionsComponent={(itemId:string) => getTableAction(itemId)}
                maxOnPage={3}
            />
            : <Alert variant={"info"} className="mt-3">Aucun compte-rendu pour le moment.</Alert>
            }

            {showModal &&
            <MarkerModalReportAdd
                isReportAdd={isReportAdd}
                rdv={rdv}
                username={username}
                marker={marker}
                setShowModal={handleModalClose}
                updateMarker={updateMarker}
            />
            }
            {/* {confModal} */}
        </Container>
    )
}