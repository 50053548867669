import { useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import SettingsGroupments from './SettingsGroupments';
import SettingsSalesmen from './SettingsSalesmen';
import SettingslabelsCategory from './SettingsLabelCategory';
import SettingslabelsStatus from './SettingsLabelStatus';
import SettingsDepartments from "./SettingsDepartments";

export default function Settings() {
    const [selectedTab, setSelectedTab] = useState<string | null>("department");

    return (
        <Container className={"mt-3"}>
            <Tabs defaultActiveKey={selectedTab || ""} onSelect={(tab: string | null) =>setSelectedTab(tab)} id="settings-tabs" className="mb-3">
                <Tab eventKey="department" title="Gestion des départements" mountOnEnter={true} unmountOnExit={true}><SettingsDepartments /></Tab>
                <Tab eventKey="salesmen" title="Gestion des commerciaux" mountOnEnter={true} unmountOnExit={true}><SettingsSalesmen /></Tab>
                <Tab eventKey="groupments" title="Gestion des groupements" mountOnEnter={true} unmountOnExit={true}><SettingsGroupments /></Tab>
                <Tab eventKey="labelsCategory" title="Gestion des catégories" mountOnEnter={true} unmountOnExit={true}><SettingslabelsCategory /></Tab>
                <Tab eventKey="labelsStatus" title="Gestion des statuts" mountOnEnter={true} unmountOnExit={true}><SettingslabelsStatus /></Tab>
            </Tabs>
        </Container>
    )
}