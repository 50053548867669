import { useEffect, useState } from "react";
import Marker from "../../../interfaces/Marker";
import Salesman from "../../../interfaces/Salesman";
import ChartView from "../../../sub/ChartView";

interface StatsEstablishmentsGlobalProps {
    data: Array<Marker>,
    salesmen: Array<Salesman>,
    getFilterSelection: (data:Array<object>, filter:any, setfilter:any) => JSX.Element, 
    getDataInfo: (labels:Array<string>) => Array<{value:string, label:string}>
}

export default function StatsEstablishmentsGlobal({data, salesmen, getFilterSelection, getDataInfo}: StatsEstablishmentsGlobalProps) {
    const [salesmenDataGlobal, setSalesmenDataGlobal] = useState<any>([]);
    const [selectedFilterGlobal, setSelectedFilterGlobal] = useState<string>("Tous");
    const [selectedTypeGlobal, setSelectedTypeGlobal] = useState<string>("doughnut");

    const commercialRelationLabels = ["CLIENT", "HORS CIBLE", "PROSPECT"];

    const getSalesmenLabel = () => {
        let allSalesmen = [];
        for (let i = 0; i < salesmen.length; i++) {
            allSalesmen.push(salesmen[i].first_name + " " + salesmen[i].name)
        }
        return allSalesmen;
    }

    const getCount = (salesmanId:string, filterField:string, filterValue:string) => {
        if (filterField === undefined || filterValue === "Tous") {
            return data.filter((item:Marker) => item.departement_details.salesman === salesmanId).length;
        } else {
            return data.filter((item:Marker) => item.departement_details.salesman === salesmanId && item[filterField as keyof Marker] === filterValue).length;
        }
    }

    const getSalesmanDataGlobal = () => {
        let data = [];
        let count = 0;
        for (let i = 0; i < salesmen.length; i++) {
            count = getCount(salesmen[i]._id, "client", selectedFilterGlobal)
            data.push(count);
        }
        setSalesmenDataGlobal(data);
    }

    useEffect(() => {
        getSalesmanDataGlobal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedFilterGlobal])

    return (
        <ChartView
            chartType={selectedTypeGlobal}
            setChartType={setSelectedTypeGlobal}
            data={salesmenDataGlobal}
            dataLabels={getSalesmenLabel()}
            chartTitle={"Établissements associés (global)"}
            filterTitle={"Relation commerciale"}
            filtersSelection={getFilterSelection(getDataInfo(commercialRelationLabels), selectedFilterGlobal, setSelectedFilterGlobal)}
        />
    )
}