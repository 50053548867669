import axios, { AxiosError, AxiosResponse } from 'axios';

export default class FileUtil {
    static dowloadFileHack(url: string, fileName: string, type: string, data:any, setState:any) {
        let contentType:string;

        switch (type) {
            case 'pdf':
                contentType = "application/pdf";
                break;
            case 'xlsx':
                contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                break;
            default:
                contentType = "text/plain";
                break;
        }

        const dowloadFile = (response:AxiosResponse) => {
            // Save received data into a file according to contentType
            let dataReceived = new Blob([response.data], { type: contentType });

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(dataReceived);
            link.download = fileName + "." + type;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setState(false);
        }

        if (typeof (data) !== 'undefined') {
            axios.post(url, data, { responseType: "arraybuffer" }).then(function (response: AxiosResponse) {
                dowloadFile(response);
            }).catch(function (error: AxiosError) {
                throw error;
            });
        } else {
            axios.get(url, data).then(function (response: AxiosResponse) {
                dowloadFile(response);
            }).catch(function (error: AxiosError) {
                throw error;
            });
        }
    };

    static extension(fileName:string){
        let fragments = fileName.split(".");
        let extension = fragments[fragments.length-1];
        return extension.toLowerCase();
    }

    static extensionIsOk(fileName:string, acceptedExtensions:Array<string>){
        let extension = FileUtil.extension(fileName);
        for(let ext of acceptedExtensions){
            if(extension === ext) return true;
        }
        return false;
    }
}