import axios from 'axios';
import { useEffect, useState } from 'react'
import { Container, Button, Row, FormControl, InputGroup, Col, Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';

import MarkerModal from '../Map/MarkerModal/MarkerModal';
import TableFiltered from '../../sub/TableFiltered';
import AnimatedPicture from '../../sub/AnimatedPicture';
import ArrayUtils from '../../utils/ArrayUtils';

import Marker from "../../interfaces/Marker";
import Groupment from "../../interfaces/Groupment";
import Salesman from "../../interfaces/Salesman";
import User from "../../interfaces/User";
import Label from "../../interfaces/Label";

interface MarkersToCheckProps {
    username: string,
    userRole: number
}

export default function MarkersToCheck({username, userRole}: MarkersToCheckProps) {
    const [data, setData] = useState<Array<Marker>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isMarkerAdd, setIsMarkerAdd] = useState<boolean>(false);
    const [groupments, setGroupments] = useState<Array<Groupment>>([])
    const [salesmen, setSalesmen] = useState<Array<Salesman>>([])
    const [users, setUsers] = useState<Array<User>>([]);
    const [labelsCategory, setLabelsCategory] = useState<Array<Label>>([]);
    const [labelsStatus, setLabelsStatus] = useState<Array<Label>>([]);
    const [markerId, setMarkerId] = useState<string>("");
    const [markerType, setMarkerType] = useState<string>("");
    const [scoreTarget, setScoreTarget] = useState<number>(0.50);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        axios.all([
            axios.post("/markers/tocheck", {scoreTarget: scoreTarget}),
            axios.get("/groupment/all"),
            axios.get("/salesman/all"),
            axios.get("/user/all"),
            axios.get("/labelCategory/all"),
            axios.get("/labelStatus/all")
        ]).then(axios.spread((markersResponse, groupsResponse, salesmanResponse, usersResponse, labelsCategoryResponse, labelsStatusResponse) => {
            setData(markersResponse.data);
            setGroupments(groupsResponse.data);
            setSalesmen(salesmanResponse.data);
            setUsers(usersResponse.data);
            setLabelsCategory(labelsCategoryResponse.data);
            setLabelsStatus(labelsStatusResponse.data);
            setIsLoading(false);
        }))
    }, [scoreTarget])

    const getMarker = (markerId:string) => {
        return data.find((item:Marker) => item._id === markerId);
    }

    const handleModalShow = (marker:Marker | undefined) => {
        if (marker === undefined) return;
        setMarkerId(marker._id)
        setMarkerType(marker.type)
        setShowModal(true);
    };

    const checkValue = (value:any) => {
        if (value.trim() === "") return setError("Vous devez saisir un score maximum");
        if (!value.match(/^[0-9]+$/)) return setError("Seul les caractères numériques sont autorisés");
        if (value < 1 || value > 90) return setError("Le pourcentage doit être compris entre 1 et 90");
        setScoreTarget(value/100);
    }

    const getTableData = () => {
        let tableData: Array<any> = [];
        let sortedData = data.sort((a:Marker,b:Marker) => ArrayUtils.ascendingSort(a.result_score,b.result_score));
        sortedData.map((item:Marker) => {
            if (parseFloat(item.result_score) <= scoreTarget) {
                tableData.push({
                    id: {data: item._id}, 
                    prospect: {data: item.nom, type: "input"},
                    score_de_precision: {data: Math.round(parseFloat(item.result_score)*100) + " %", type: "input"},
                    priorite_cciale_departement: {data: String(item.departement_details.priorite_cciale), type: "input"},
                    numero_dept: {data: item.numero_dept, type: "input"}
                })
            }
            return null;
        })
        return tableData;
    }

    const getTableAction = (itemId:any) => {
        return <>
                <Button variant={"primary"} size={"sm"} className={"mx-2"} onClick={() => handleModalShow(getMarker(itemId))}><FontAwesomeIcon icon={faEye} /></Button> 
            </>
    }

    return (
        <>
        {isLoading && 
            <Container>
                <AnimatedPicture />
                <h1>Chargement en cours...</h1>
            </Container>
        }
        
        {!isLoading && !isMarkerAdd &&
            <Container className={"mt-5"}>
                <Row md="auto"><h5>Score maximum</h5></Row>
                <Row xs="auto" className={"mb-4"}>
                    <Col >
                        <InputGroup className="mb-3">
                            <FormControl defaultValue={scoreTarget*100} isInvalid={error !== ""} onBlur={(e) => checkValue(e.target.value)} placeholder={"Score max"}/>
                            <Button><FontAwesomeIcon icon={faSearch}/></Button>
                        </InputGroup>
                    </Col>
                </Row>
                {error !== "" && <Alert variant={"danger"} dismissible onClose={() => setError("")}>{error}</Alert>}
                <TableFiltered
                    data={getTableData()}
                    setData={null}
                    hasActions={true}
                    actionsComponent={((itemId:any) => getTableAction(itemId))}
                    hasFilters={true}
                    actionsLabel={"VOIR LA FICHE PROSPECT"}
                />

                {showModal && 
                <MarkerModal
                    data={data}
                    setData={setData}
                    markerId={markerId}
                    markerType={markerType}
                    setShowModal={setShowModal}
                    isMarkerAdd={false}
                    setIsMarkerAdd={setIsMarkerAdd}
                    userRole={userRole}
                    username={username}
                    groupments={groupments}
                    salesmen={salesmen}
                    users={users}
                    labelsCategory={labelsCategory}
                    labelsStatus={labelsStatus}
                />
                }
            </Container>
        }
        </>
    )
}