import { useEffect, useState } from "react";
import Marker from "../../../interfaces/Marker";
import ChartView from "../../../sub/ChartView";

interface StatsByRegionProps {
    data: Array<Marker>,
    getFilterSelection: (data:Array<object>, filter:any, setfilter:any) => JSX.Element,
    getDataInfo: (labels:Array<string>) => Array<{value:string, label:string}>
}

export default function StatsByRegion({data, getFilterSelection, getDataInfo}: StatsByRegionProps) {
    const [chartData, setChartData] = useState<any>([]);
    const [selectedFilter, setSelectedFilter] = useState<string>("Tous");
    const [selectedType, setSelectedType] = useState<string>("doughnut");

    const regionsLabel = [
        "AUVERGNE-RHÔNE-ALPES", "BOURGOGNE-FRANCHE-COMTÉ", "BRETAGNE", "CENTRE-VAL DE LOIRE", "CORSE", "GRAND EST", "HAUTS-DE-FRANCE",
        "ÎLE-DE-FRANCE", "NORMANDIE", "NOUVELLE-AQUITAINE", "OCCITANIE", "PAYS DE LA LOIRE", "PROVENCE-ALPES-CÔTE D'AZUR"
    ];

    const commercialRelationLabels = ["Client", "Hors cible", "Prospect"];
    
    const getCount = (regionName:string, filterField:string, filterValue:string) => {
        if (regionName === "Tous") {
            return data.filter((item:Marker) => item[filterField as keyof Marker] === filterValue).length;
        } else {
            return data.filter((item:Marker) => item.departement_details.region.toUpperCase() === regionName && item[filterField as keyof Marker] === filterValue).length;
        }
    }

    const getData = () => {
        let data = [];
        let count = 0;
        for (let i = 0; i < commercialRelationLabels.length; i++) {
            count = getCount(selectedFilter, "client", commercialRelationLabels[i].toUpperCase())
            data.push(count);
        }
        setChartData(data);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedFilter])

    return (
        <ChartView
            chartType={selectedType}
            setChartType={setSelectedType}
            data={chartData}
            dataLabels={commercialRelationLabels}
            chartTitle={"Établissements associés (précis)"}
            filterTitle={"Régions"}
            filtersSelection={getFilterSelection(getDataInfo(regionsLabel), selectedFilter, setSelectedFilter)}
        />
    )
}