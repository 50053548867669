import Crud from '../../sub/Crud';

export default function SettingsGroupments() {
    return (
        <Crud
            apiRouterPathRoot={"/groupment"}
            elementLabel={"groupement"}
            isGroupmentCrud={true}
        />
   )
}