import React, { useMemo, useState } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';

import MapFilters from "./MapFilters";
import MarkerModal from "./MarkerModal/MarkerModal";

import MarkerAdd from "./MarkerAdd";

import { Roles } from "../../enums/Roles";
import ExportButton from "../../sub/ExportButton";
import { MapContainer, TileLayer} from 'react-leaflet';
import LocationMarker from "../../sub/LocationMarker";
import MarkerCluster from "../../sub/Clusters";
import L from "leaflet";
import Util from "../../utils/Util";

import Marker from "../../interfaces/Marker";
import Groupment from "../../interfaces/Groupment";
import Salesman from "../../interfaces/Salesman";
import Label from "../../interfaces/Label";
import User from "../../interfaces/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import ViewSelection from "./ViewSelection";

interface MapViewProps {
    userRole: number,
    username: string,
    data: Array<Marker>,
    setData: React.Dispatch<React.SetStateAction<Array<Marker>>>,
    groupments: Array<Groupment>,
    salesmen: Array<Salesman>,
    labelsCategory: Array<Label>,
    labelsStatus: Array<Label>,
    users: Array<User>,
    userId: string,
    selectedView: string, 
    setSelectedView: React.Dispatch<React.SetStateAction<string>>,
}

export default function MapView({userRole, username, data, setData, groupments, salesmen, labelsCategory, labelsStatus, users, userId, selectedView, setSelectedView}: MapViewProps) {
    const [isMarkerAdd, setIsMarkerAdd] = useState<boolean>(false);

    // Data filters (categories, salesman and groups)
    const [category, setCategory] = useState<Array<string>>(["Tous"]);
    const [salesman, setSalesman] = useState<Array<string>>(["Tous"]);
    const [group, setGroup] = useState<string>("Tous");
    const [client, setClient] = useState<string>("Tous");
    const [labelCategory, setLabelCategory] = useState<string>("Tous");
    const [labelStatus, setLabelStatus] = useState<string>("Tous");
    const [internalRef, setInternalRef] = useState<string>("");
    const [numeroDept, setNumeroDept] = useState<string>("");
    const [cp, setCp] = useState<string>("");

    const [markerId, setMarkerId] = useState<string>("");

    const [showModal, setShowModal] = useState<boolean>(false);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);

    const handleModalShow = (markerId: string) => {
        setMarkerId(markerId);
        setShowModal(true);
    };

    const getMarker = (markerId: string) => {
        return data.find((item: Marker) => item._id === markerId);
    }

    const filterSalesman = (filter:string) => {
        Util.multiFilter(salesman, "Tous", filter, setSalesman);
    }

    const filterCategory = (filter:string) => {
        Util.multiFilter(category, "Tous", filter, setCategory);
    }

    let filteredData = useMemo(() => data.filter((marker:Marker) => (category.includes("Tous") || category.includes(marker.type)) &&
        (salesman.includes("Tous") || salesman.includes(marker.departement_details.salesman)) &&
        (group === "Tous" || group === marker.groupement) &&
        (labelCategory === "Tous" || labelCategory === marker.libelle_categorie) &&
        (labelStatus === "Tous" || labelStatus === marker.libelle_statut) &&
        (client === "Tous" || client === marker.client) &&
        (internalRef === "" || internalRef.includes(marker.num_interne)) &&
        (numeroDept === "" || numeroDept.includes(marker.numero_dept)) &&
        (cp === "" || cp.includes(marker.cp))
    ), [category, client, cp, data, group, internalRef, labelCategory, labelStatus, numeroDept, salesman]);

    let southWest = useMemo(() => L.latLng(-89.98155760646617, -180), []);
    let northEast = useMemo(() => L.latLng(89.99346179538875, 180), []);
    let bounds = useMemo(() => L.latLngBounds(southWest, northEast), [northEast, southWest]);

    const MapClusters = useMemo(() => <MarkerCluster markers={filteredData} onMarkerClick={(markerId:string) => handleModalShow(markerId)}salesmen={salesmen}/>, [filteredData, salesmen])
   
    return (
        <Container fluid>
            <MapContainer className="map" center={[46.34, 2.6025]} zoom={6} maxZoom={18} minZoom={3} maxBounds={bounds} doubleClickZoom={false}>
                <MapFilters 
                    data={data}
                    setCategory={(filter:string) => filterCategory(filter)} 
                    setSalesman={(filter:string) => filterSalesman(filter)}
                    setGroup={setGroup}
                    setLabelCategory={setLabelCategory}
                    setLabelStatus={setLabelStatus}
                    setClient={setClient}
                    setInternalRef={setInternalRef}
                    setNumeroDept={setNumeroDept}
                    setCp={setCp}
                    groupments={groupments}
                    salesmen={salesmen}
                    labelsCategory={labelsCategory}
                    labelsStatus={labelsStatus}
                    category={category}
                    visibility={isFilterMenuOpen ? "visible" : "invisible"}
                />
                <Button variant="primary" className="map-button-menu-filters mt-2 me-2" onClick={() => {setTimeout(() => {setIsFilterMenuOpen(!isFilterMenuOpen)}, 100)}}><FontAwesomeIcon icon={faFilter} /></Button>

                <Container fluid className="map-select-view">
                    <ViewSelection 
                        userId={userId} 
                        selectedView={selectedView} 
                        setSelectedView={setSelectedView} 
                    />
                </Container>

                <TileLayer 
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
                    noWrap={true}
                />
                {MapClusters}
                <LocationMarker />

                <Container fluid className="map-actions">
                    <h5><strong>Points affichés avec le filtrage actuel: {filteredData.length}</strong></h5>
                    {userRole !== Roles.SALESMAN && 
                    <Row xs="auto" className="justify-content-center mb-3">
                        <Col xs="6" md="auto"><MarkerAdd setIsMarkerAdd={setIsMarkerAdd} handleModalShow={(index:string) => handleModalShow(index)}/></Col>
                        {userRole === Roles.ADMIN && <Col xs="6" md="auto"><ExportButton APIUrl={"/markers/export"} fileName={"prospects"} fileExtension={"xlsx"} data={null} /></Col>}
                    </Row>}
                </Container>
            </MapContainer>

            {showModal && 
            <MarkerModal
                data={data}
                setData={setData}
                markerId={!isMarkerAdd ? getMarker(markerId)?._id : undefined}
                markerType={!isMarkerAdd ? getMarker(markerId)?.type : undefined}
                setShowModal={setShowModal}
                isMarkerAdd={isMarkerAdd}
                setIsMarkerAdd={setIsMarkerAdd}
                userRole={userRole}
                username={username}
                groupments={groupments}
                salesmen={salesmen}
                users={users}
                labelsCategory={labelsCategory}
                labelsStatus={labelsStatus}
            />
            }
        </Container>
    );
}